import React from "react";
import { defaultConferencing } from "src/data/defaultLayout";
import { Text } from "@100mslive/roomkit-react";

function PrebuiltEmojiReactionsConfig() {
  return <></>;
}

export default PrebuiltEmojiReactionsConfig;

const defaultData =
  defaultConferencing?.["default"]?.["elements"]?.["emoji_reactions"];

export const emojiReactionsConfig = {
  ui: {
    title: "Emoji Reactions",
    hasSwitch: true,
    tooltipText: (
      <Text variant="caption" css={{ color: "$on_surface_high" }}>
        The selected role can send emoji reactions.
        <Text variant="caption" css={{ fontWeight: "$semiBold" }}>
          Only available on web and mweb.
        </Text>
      </Text>
    ),
  },
  path: "screens.conferencing.default.elements.emoji_reactions",
  Component: PrebuiltEmojiReactionsConfig,
  defaultData: defaultData,
};
