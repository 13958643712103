import { useEffect, useRef } from "react";
import { MODAL_Z_INDEX } from "src/constants";
import { Button, Dialog, Flex, Text } from "@100mslive/roomkit-react";

function MainDialog(
  {
    open = false,
    onOpenChange,
    title = "",
    subtitle = <></>,
    disableButton = false,
    mainFunction = () => {},
    variant = "primary",
    content = <></>,
    loading = false,
    hideCloseIcon = false,
    Icon = <></>,
    enableEnterToSubmit = false,
    buttonText,
    fullWidth = false,
    width = "480px",
  },
  ...props
) {
  const primaryButtonRef = useRef();

  useEffect(() => {
    const runMainFunc = e => {
      if (e.code === "Enter") {
        primaryButtonRef.current?.click();
      }
    };

    if (enableEnterToSubmit && window && !disableButton) {
      window.addEventListener("keypress", runMainFunc);
    }
    return () => window.removeEventListener("keypress", runMainFunc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange} {...props}>
      <Dialog.Portal>
        <Dialog.Overlay
          css={{
            backgroundColor: "rgba(0, 0, 0, 0.8);",
            inset: 0,
            zIndex: MODAL_Z_INDEX - 20,
          }}
        />
        <Dialog.Content
          css={{
            width: `min(${width},100%)`,
            border: "1px solid $surface_bright",
            bg: "$surface_default",
            position: "fixed",
            zIndex: MODAL_Z_INDEX,
          }}
        >
          {/*Make it a wrapper style ui component*/}
          {!hideCloseIcon && (
            <Dialog.DefaultClose
              css={{ position: "absolute", top: "$8", right: "$8" }}
            />
          )}
          <Dialog.Title>
            <Flex direction="row" css={{ gap: "$4", mb: "$2" }} align="center">
              {Icon}
              <Text variant="h5">{title}</Text>
            </Flex>
          </Dialog.Title>
          <Flex direction="column">
            {subtitle}
            <form
              onSubmit={e => {
                e.preventDefault();
                if (!disableButton) {
                  mainFunction();
                }
              }}
              className="w-full"
            >
              {content}
            </form>

            {buttonText && (
              <Flex direction="row" css={{ mt: "$8", gap: "$8" }}>
                {fullWidth ? null : (
                  <Button
                    variant="standard"
                    outlined
                    css={{
                      height: "$16",
                      width: "100%",
                      fontSize: "$sm",
                    }}
                    onClick={onOpenChange}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  ref={primaryButtonRef}
                  variant={variant}
                  css={{
                    height: "$16",
                    width: "100%",
                    fontSize: "$sm",
                  }}
                  disabled={disableButton}
                  onClick={() => {
                    mainFunction();
                  }}
                  loading={loading}
                >
                  {buttonText}
                </Button>
              </Flex>
            )}
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default MainDialog;
