import React, { Dispatch, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHasUnsavedLiveStreamingChanges,
  toggleRtmp,
  updateRTMPDestinations,
  validateRTMPDestinations,
} from "src/actions/RolesActions";
import TemplateConfigCard from "src/components/Cards/TemplateConfigCard";
import ConfigMultiSelect from "src/components/Common/ConfigMultiSelect";
import ConfigSingleSelect from "src/components/Common/ConfigSingleSelect";
import Line from "src/components/Common/Line";
import StatusString from "src/components/Common/StatusString";
import ValuePill from "src/components/Common/ValuePill";
import HipaaComplianceNotice from "src/components/HipaaComplianceNotice/HipaaComplianceNotice";
import TimeInput from "src/components/TimeInput";
import { TEMPLATE_TABS, VideoQuality } from "src/constants";
import SwitchWithTitle from "src/pages/Developer/SwitchWithTitle";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { RootState } from "src/store/reducers";
import { rtmpDestinationsType } from "src/types/policyTypes";
import { currentWorkspaceHipaa, slugify } from "src/utils";
import { ArrowRightIcon } from "@100mslive/react-icons";
import { Dropdown, Flex, Text } from "@100mslive/roomkit-react";
import { ConfigInputCSS } from "./HLS";

interface RTMPProps {
  subscribeToClicked: (roleName: string) => void;
  enabledAutoStartRecording: boolean;
  internalRecorderSubscriptionList: string[];
  internalRecorderRolesForSubscription: string[];
  setSelected: Dispatch<React.SetStateAction<string>>;
  isValidInternalRecorderState: boolean;
}

const layersList = Object.values(VideoQuality)
  .map(r => r["16:9"])
  .filter(layer => layer.height >= 480 && layer.width >= 480);

// eslint-disable-next-line complexity
const RTMP: FC<RTMPProps> = ({
  subscribeToClicked,
  enabledAutoStartRecording,
  internalRecorderSubscriptionList,
  internalRecorderRolesForSubscription,
  setSelected,
  isValidInternalRecorderState,
}) => {
  const cardTitle = "External stream (RTMP out)";
  const dispatch = useDispatch();

  const rtmpDestinations =
    useSelector(
      (state: RootState) => state.roles.policyInfo.destinations.rtmpDestinations
    ) || {};

  const rtmpDestinationIds = Object.keys(rtmpDestinations || {});

  const isHipaa = currentWorkspaceHipaa();

  const { policyDerivedStates } = useSelector(
    (state: RootState) => state.roles
  );

  const invalidFields = useSelector(
    (state: RootState) => state.roles.invalidFields.rtmpDestinations
  );

  const rtmpObj = rtmpDestinations?.[rtmpDestinationIds?.[0]] || {};

  const updateRTMPStore = (key: keyof rtmpDestinationsType, value: any) => {
    if (rtmpObj) {
      //@ts-ignore
      rtmpObj[key] = value;
      dispatch(setHasUnsavedLiveStreamingChanges(true));
      dispatch(updateRTMPDestinations(rtmpObj, rtmpDestinationIds?.[0]));
    }
  };

  // eslint-disable-next-line
  return (
    <TemplateConfigCard
      text={cardTitle}
      subtitle="RTMP Streaming can be used to live stream your video conferencing apps to platforms like YouTube, Twitch, Facebook, etc."
      id={slugify(cardTitle)}
      classNameForText="config-subheading"
      rightComponent={
        <Flex direction="column" css={{ minWidth: "100%" }}>
          <SettingsTitle
            key="rtmp"
            hideTooltip
            title=""
            text="RTMP Streaming"
          />
          <SwitchWithTitle
            hideTitle
            disabled={isHipaa || enabledAutoStartRecording}
            tooltipMessage=""
            checkedValue={policyDerivedStates?.rtmpDestinationsEnabled}
            onCheckedChange={() => {
              dispatch(setHasUnsavedLiveStreamingChanges(true));
              dispatch(validateRTMPDestinations(rtmpDestinationIds?.[0]));
              dispatch(
                toggleRtmp(!policyDerivedStates?.rtmpDestinationsEnabled)
              );
            }}
          />

          {enabledAutoStartRecording ? (
            <>
              <Text
                variant="caption"
                css={{
                  mt: "$4",
                  color: "$on_surface_medium",
                  display: "inline",
                }}
              >
                RTMP streaming cannot be enabled since auto-start is enabled for
                recordings.
              </Text>
              <span
                onClick={() => setSelected(TEMPLATE_TABS.RECORDING)}
                className="cursor-pointer items-center gap-1 text-xs mt-1 flex text-primary-default hover:text-primary-dark whitespace-nowrap"
              >
                See config <ArrowRightIcon height={14} width={14} />
              </span>
            </>
          ) : null}

          <HipaaComplianceNotice />

          {policyDerivedStates?.rtmpDestinationsEnabled ? (
            <>
              <Line />
              <SettingsTitle
                key="recordingEnabled"
                title="If recording is required this can be set as true. This value has no effect on streaming."
                text="Recording"
              />
              <SwitchWithTitle
                hideTitle
                tooltipMessage=""
                checkedValue={rtmpObj?.recordingEnabled}
                onCheckedChange={() => {
                  updateRTMPStore(
                    "recordingEnabled",
                    !rtmpObj?.recordingEnabled
                  );
                }}
              />
              <Line />
              <SettingsTitle
                key="maxDuration"
                title="Sets the maximum duration of the recording"
                text="Maximum recording duration in minutes"
              />
              <TimeInput
                css={ConfigInputCSS}
                onChange={updateRTMPStore}
                timeFactor={60}
                _key="maxDuration"
                value={rtmpObj?.maxDuration}
              />
              <StatusString content={invalidFields?.maxDuration || ""} />
              <Line />

              <SettingsTitle
                key="broadcasterRoles"
                title="Choose roles to be broadcasted over the live stream"
                text="Broadcaster Roles"
              />
              <ConfigMultiSelect
                inputText="Select roles"
                optionsArray={internalRecorderRolesForSubscription}
                onCheckedChange={subscribeToClicked}
                isCheckedCondition={role =>
                  (
                    (internalRecorderSubscriptionList as string[]) || []
                  )?.includes(role)
                }
              />

              <Flex css={{ w: "100%", flexWrap: "wrap" }}>
                {internalRecorderSubscriptionList.map((roleName: string) => (
                  <ValuePill
                    key={roleName}
                    content={roleName}
                    onClick={() => {
                      subscribeToClicked(roleName);
                    }}
                  />
                ))}
              </Flex>
              <StatusString
                content={
                  isValidInternalRecorderState
                    ? ""
                    : "At least one role must be selected"
                }
              />
              <Line />
              <SettingsTitle
                key="maxResolution"
                title="Indicates the resolution of the screen to be recorded in pixels"
                text="Maximum Resolution"
              />
              <ConfigSingleSelect
                triggerCSS={{ marginTop: "$4" }}
                inputText={
                  rtmpObj?.width && rtmpObj?.height
                    ? `${rtmpObj.width}x${rtmpObj.height}`
                    : "Select a resolution"
                }
                content={
                  <>
                    {layersList.map(layer => (
                      <Dropdown.Item
                        key={`${layer?.width}x${layer?.height}`}
                        css={{ p: "$4 $8" }}
                        onClick={() => {
                          updateRTMPStore("width", layer?.width);
                          updateRTMPStore("height", layer?.height);
                        }}
                      >
                        <Text variant="caption">
                          {layer?.width}x{layer?.height}
                        </Text>
                      </Dropdown.Item>
                    ))}
                  </>
                }
              />
            </>
          ) : null}
        </Flex>
      }
    />
  );
};

export default RTMP;
