import React, { ComponentProps, FC } from "react";
import { CSS, Flex, Text } from "@100mslive/roomkit-react";

interface TagProps {
  label: string;
  type?: string;
  css?: CSS;
}

export const HighlightBannerCSS = {
  normal: {
    background: "linear-gradient(210deg, #6626ED 0%, #2672ED 100%)",
    border: "unset",
    boxShadow: "0px 4px 16px 0px rgba(39, 115, 237, 0.30)",
  },
  disabled: {
    "&[disabled]": {
      border: "unset",
      background: "linear-gradient(210deg, #773eef 0%, #3e81ef 100%)",
      boxShadow: "none",
      opacity: 0.7,
    },
  },
  hover: {
    "&:not([disabled]):hover": {
      border: "unset",
      background: "linear-gradient(210deg, #2672ED 0%, #6626ED 100%)",
    },
  },
  active: {
    "&:not([disabled]):active": {
      border: "unset",
      background: "linear-gradient(210deg, #004299 0%, #5713e6 100%)",
    },
  },
};

const Tag: FC<TagProps & ComponentProps<typeof Text>> = ({
  variant = "overline",
  type = "highlight",
  label,
  css,
}) => {
  const defaultCss: CSS = {
    ...HighlightBannerCSS.normal,
    r: "$0",
    py: "$2",
    px: "$4",
  };
  if (type !== "highlight") {
    defaultCss.background = "$primary_dim";
  }
  const updatedCss = {
    ...defaultCss,
    ...css,
  };

  return (
    <Flex css={{ flex: "0 1 auto" }}>
      <Text variant={variant} css={updatedCss}>
        {label}
      </Text>
    </Flex>
  );
};

export default Tag;
