import React from "react";
import ConfigMultiSelect from "src/components/Common/ConfigMultiSelect";
import ValuePill from "src/components/Common/ValuePill";
import { Box, Flex } from "@100mslive/roomkit-react";

export const PrebuiltSidebarMultiSelect = ({
  inputText,
  optionsArray,
  selectedOptions,
  onCheckedChange,
  isCheckedCondition,
}: {
  inputText: string;
  optionsArray: string[];
  selectedOptions: string[];
  onCheckedChange: (value: any) => void;
  isCheckedCondition: (value: any) => boolean;
}) => (
  <Box css={{ mt: "$4" }}>
    <ConfigMultiSelect
      inputText={inputText}
      optionsArray={optionsArray}
      onCheckedChange={onCheckedChange}
      isCheckedCondition={isCheckedCondition}
    />
    <Flex css={{ w: "100%", flexWrap: "wrap" }}>
      {(selectedOptions || []).map((option: string) => (
        <ValuePill
          key={option}
          content={option}
          onClick={() => onCheckedChange(option)}
        />
      ))}
    </Flex>
  </Box>
);
