import React, { useRef } from "react";
import StatusString from "src/components/Common/StatusString";
import PrebuiltSidebarItemWrapper from "src/pages/Prebuilt/PrebuiltSidebarItemWrapper";
import { CheckIcon } from "@100mslive/react-icons";
import { Flex, Input } from "@100mslive/roomkit-react";

export const PrebuiltSidebarInput = ({
  title,
  value,
  onChange,
  tooltipText = "",
  errorMessage = "",
  maxLength = undefined,
}: {
  title: string;
  value: string;
  onChange: (value: string) => void;
  tooltipText?: string;
  errorMessage?: string;
  maxLength?: number;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <PrebuiltSidebarItemWrapper
      title={title}
      hideTooltip={!tooltipText?.length}
      tooltipText={tooltipText}
    >
      <Flex
        justify="center"
        align="center"
        css={{
          gap: "$6",
        }}
      >
        <Input
          css={{ r: "$0", ml: "$space$px", w: "99%", bg: "$surface_bright" }}
          maxLength={maxLength}
          ref={inputRef}
          defaultValue={value}
        />
        <CheckIcon
          style={{ width: "$12", cursor: "pointer" }}
          onClick={() =>
            onChange(inputRef.current?.value ? inputRef.current.value : value)
          }
        />
      </Flex>
      <StatusString content={errorMessage} />
    </PrebuiltSidebarItemWrapper>
  );
};
