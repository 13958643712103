import React from "react";
import {
  JoinForm,
  JoinForm_JoinBtnType,
} from "@100mslive/types-prebuilt/elements/join_form";
import ConfigSingleSelect from "src/components/Common/ConfigSingleSelect";
import StatusString from "src/components/Common/StatusString";
import { defaultLayout } from "src/data/defaultLayout";
import PrebuiltSidebarItemWrapper from "src/pages/Prebuilt/PrebuiltSidebarItemWrapper";
import { Dropdown, Text } from "@100mslive/roomkit-react";
import { validateJoinForm } from "../validator/previewScreenValidations";

type Props = {
  data: JoinForm;
  setData: (data: JoinForm) => void;
  invalidFields: { [key in keyof JoinForm]?: string } | undefined;
};
const joinButtonTypes = {
  JOIN_BTN_TYPE_JOIN_AND_GO_LIVE: "Join and Start Streaming",
  JOIN_BTN_TYPE_JOIN_ONLY: "Join Only",
};

const defaultData =
  defaultLayout?.["screens"]?.["preview"]?.["default"]?.["elements"]?.[
    "join_form"
  ];
export const joinFormConfig = {
  ui: {
    title: "Join Button",
    hasSwitch: false,
    tooltipText: "",
  },
  path: "screens.preview.default.elements.join_form",
  Component: PrebuiltJoinFormConfig,
  defaultData: defaultData,
  validator: validateJoinForm,
};

function PrebuiltJoinFormConfig({ data, setData, invalidFields }: Props) {
  const isGoLive =
    data?.join_btn_type === JoinForm_JoinBtnType.JOIN_BTN_TYPE_JOIN_AND_GO_LIVE;

  return (
    <>
      <PrebuiltSidebarItemWrapper
        hideTooltip
        title="Button Type"
        css={{ mt: "$8" }}
      >
        <ConfigSingleSelect
          inputText={
            joinButtonTypes[data?.join_btn_type as keyof typeof joinButtonTypes]
          }
          triggerCSS={{ w: "98%", ml: "$1" }}
          contentCSS={{ zIndex: 1100 }}
          content={
            <>
              {Object.keys(joinButtonTypes).map(type => (
                <Dropdown.Item
                  key={type}
                  css={{ p: "$4 $8" }}
                  onClick={() => {
                    const tempData = {
                      ...data,
                      join_btn_type: type as JoinForm_JoinBtnType,
                    };
                    setData({
                      ...tempData,
                    });
                  }}
                >
                  <Text variant="caption">
                    {joinButtonTypes[type as keyof typeof joinButtonTypes]}
                  </Text>
                </Dropdown.Item>
              ))}
            </>
          }
        />
        <StatusString content={invalidFields?.join_btn_type as string} />
        {isGoLive ? (
          <Text
            variant="caption"
            css={{ c: "$on_surface_low", fontWeight: "$regular", mt: "$4" }}
          >
            Stream will auto-start on join
          </Text>
        ) : null}
      </PrebuiltSidebarItemWrapper>
    </>
  );
}

export default PrebuiltJoinFormConfig;
