import React, { useRef } from "react";
import { baseTheme } from "src/themeConfig";
import { Box, Flex, Text } from "@100mslive/roomkit-react";

function PrebuiltMenuItem({
  icon,
  title,
  description,
  onClick,
}: {
  icon: JSX.Element;
  title: string;
  description: string;
  onClick: () => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <Flex
      css={{
        cursor: "pointer",
        py: "$4",
        r: "$0",
        my: "$md",
        px: "$4",
        "&:hover": {
          bg: "$surface_brighter",
        },
      }}
      onClick={onClick}
      onMouseEnter={() => {
        ref.current?.style.setProperty(
          "color",
          baseTheme.colors.on_surface_medium
        );
      }}
      onMouseLeave={() => {
        ref.current?.style.setProperty(
          "color",
          baseTheme.colors.on_surface_low
        );
      }}
      justify="start"
      align="center"
    >
      <Box css={{ c: "$on_surface_medium", w: "$10", h: "$10" }}>{icon}</Box>
      <Flex direction="column" css={{ ml: "$md" }}>
        <Text
          variant="body2"
          css={{ fontWeight: "$semiBold", c: "$on_surface_high" }}
        >
          {title}
        </Text>
        <Text
          variant="caption"
          ref={ref}
          css={{ fontWeight: "$regular", c: "$on_surface_low", mt: "$2" }}
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  );
}

export default PrebuiltMenuItem;
