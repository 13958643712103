import React, { FC } from "react";
import { Flex, Text } from "@100mslive/roomkit-react";
import question from "../../assets/images/question.png";

interface NoResultsProps {
  title: string;
  subtitle: string;
}

const NoResults: FC<NoResultsProps> = ({ title, subtitle }) => (
  <Flex direction="column" css={{ my: "$20", justifyContent: "center" }}>
    <img
      style={{ display: "inline-block", margin: "0 auto" }}
      src={question}
      alt="No results"
      width="40px"
      height="40px"
    />
    <Text
      css={{
        color: "$on_surface_high",
        fontWeight: "$semiBold",
        textAlign: "center",
        mt: "$md",
      }}
    >
      {title}
    </Text>
    {subtitle ? (
      <Text
        variant="sm"
        css={{
          color: "$on_surface_medium",
          mt: "$4",
          textAlign: "center",
        }}
      >
        {subtitle}
      </Text>
    ) : null}
  </Flex>
);

export default NoResults;
