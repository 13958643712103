import React from "react";
import { Link } from "react-router-dom";
import useDynamicTruncate from "src/hooks/useDynamicTruncate";
import { middleTruncateString } from "src/utils";
import { Text } from "@100mslive/roomkit-react";

const TruncatedColumn = ({ linkExist, link, onClick, id, width = "12vw" }) => {
  const { prefixLength, maxWidth } = useDynamicTruncate(id);

  const textContent = middleTruncateString(id, prefixLength);
  const handleClick = onClick ? () => onClick() : undefined;
  if (linkExist) {
    return (
      <Link
        style={{
          width: width,
          minWidth: "100px",
          maxWidth: `${textContent.includes("...") ? 2000 : maxWidth}px`,
        }}
        to={link}
        onClick={handleClick}
      >
        <Text
          variant="body2"
          css={{
            "&:hover": { textDecoration: "underline" },
            color: "$primary_bright",
            fontWeight: 700,
            fontFamily: "var(--user-font-roboto)",
            width: "100%",
          }}
          id={id}
        >
          {textContent}
        </Text>
      </Link>
    );
  } else {
    return (
      <div
        style={{
          width: width,
          minWidth: "100px",
          maxWidth: `${textContent.includes("...") ? 2000 : maxWidth}px`,
        }}
      >
        <Text
          variant="body2"
          css={{
            color: "$on_surface_high",
            fontWeight: 700,
            fontFamily: "var(--user-font-roboto)",
            width: "100%",
          }}
          id={id}
        >
          {textContent}
        </Text>
      </div>
    );
  }
};

export default TruncatedColumn;
