import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import toastr from "src/components/Common/toastr";
import { AppAnalytics } from "src/helpers";
import { PadLockOnIcon, PersonIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, Loading, Text } from "@100mslive/roomkit-react";
import StripeWrapper from "./StripeWrapper";
import { poweredByStripe } from "../../assets/images";
import { MainDialog } from "../../components";
import { API_CALL_STATE } from "../../constants";
import { fetchBillingInfo } from "../../store/actions";

function Component({ open, setOpen }) {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }
      setLoading(true);
      const { error } = await stripe.confirmSetup({
        elements,

        confirmParams: {
          return_url: `${process.env.REACT_APP_HOST_URL}settings/organization?tab=billing`,
        },
      });
      setLoading(false);
      if (error) {
        setErrorMessage(error.message);
        toastr.error(error.message);
        try {
          AppAnalytics.track("card.add.failed", {
            componentId: "add.card.pop-up",
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        toastr.success("Primary card change successful");
        try {
          AppAnalytics.track("card.add.success", {
            componentId: "add.card.pop-up",
          });
        } catch (e) {
          console.error(e);
        }
      }
    },
    [elements, stripe]
  );
  return (
    <Dialog
      open={open}
      setOpen={setOpen}
      subtitle={
        <>
          <form onSubmit={handleSubmit}>
            <PaymentElement />
            <Button
              variant="primary"
              loading={loading}
              disabled={!stripe || !elements || loading}
              type="submit"
              css={{
                mt: "$8",
                w: "100%",
                "&:not(:hover)": { bg: "$primary_default !important" },
              }}
            >
              Add Card
            </Button>
            {errorMessage && <p>{errorMessage}</p>}
          </form>
          <Box css={{ textAlign: "center", mb: "$4" }}>
            <hr
              style={{
                border: "1px solid",
              }}
              className="text-border-light mt-6"
            />
            <Text variant="body2" css={{ mt: "$8" }}>
              <PadLockOnIcon style={{ marginRight: "2px" }} />
              Your credit card data is secure
            </Text>
            <Text
              variant="caption"
              css={{
                c: "$on_surface_medium",
                mb: "$6",
                mt: "$7",
              }}
            >
              We never store any card information on our servers.
              <br /> All payments are encrypted and securely processed by
              stripe.
            </Text>
            <img src={poweredByStripe} alt="poweredByStripe" />
          </Box>
        </>
      }
    />
  );
}

function AddCardModal({ open, setOpen }) {
  const dispatch = useDispatch();
  const { billing, status } = useSelector(state => state.organisation);
  useEffect(() => {
    if (open && status.fetchBillingInfo !== API_CALL_STATE.DONE) {
      dispatch(fetchBillingInfo());
    }
  }, [dispatch, open, status.fetchBillingInfo]);
  if (billing.clientSecret) {
    return (
      <StripeWrapper>
        <Component open={open} setOpen={setOpen} />
      </StripeWrapper>
    );
  }
  return (
    <Dialog
      open={open}
      setOpen={setOpen}
      content={
        <Flex justify="center" align="center">
          <Loading />
        </Flex>
      }
    />
  );
}

function Dialog({ subtitle = <></>, content = <></>, open, setOpen }) {
  return (
    <MainDialog
      title="Add Card"
      open={open}
      onOpenChange={() => setOpen(!open)}
      variant="primary"
      Icon={<PersonIcon />}
      disableButton={false}
      content={content}
      subtitle={subtitle}
      fullWidth={true}
      width="540px"
    />
  );
}

export default AddCardModal;
