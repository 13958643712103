import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { get, isEmpty } from "lodash";
import { patchRoleInStore, setHasUnsavedRoles } from "src/actions/RolesActions";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import {
  getSimulcastConfigForVideoQuality,
  getSimulcastLayersForConfig,
} from "src/store/roleTemplate";
import { policyType, roleType } from "src/types/policyTypes";
import {
  calculateAspectRatio,
  isCustomAspectRatio,
  isCustomVideoQuality,
} from "src/utils";
import { CheckIcon, ChevronDownIcon, CrossIcon } from "@100mslive/react-icons";
import {
  Checkbox,
  Dropdown,
  Flex,
  Switch,
  Text,
} from "@100mslive/roomkit-react";

// eslint-disable-next-line complexity
function SimulcastConfig({
  policy,
  roleName,
}: {
  policy: policyType;
  roleName: string;
}) {
  const cardRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const videoSettings = (policy?.roles?.[roleName] as roleType)?.publishParams
    ?.video;
  const aspectRatio =
    calculateAspectRatio({
      width: videoSettings?.width,
      height: videoSettings?.height,
    }) || "4:3";
  const isCustomVideoQualityRole =
    isCustomAspectRatio(aspectRatio) || isCustomVideoQuality(videoSettings);
  const simulcastEnabled = !(
    isEmpty(
      (policy?.roles?.[roleName] as roleType)?.publishParams?.simulcast?.video
    ) &&
    isEmpty(
      (policy?.roles?.[roleName] as roleType)?.publishParams?.simulcast?.screen
    )
  );
  const minDim = Math.min(videoSettings.width, videoSettings.height);
  const defaultSimulcastConfig = !isCustomVideoQualityRole
    ? getSimulcastConfigForVideoQuality({
        videoQuality: `_${minDim}p`,
        aspectRatio: aspectRatio,
      })
    : {};
  const hasTwoOrMorePossibleSimulcastLayers = !isCustomVideoQualityRole
    ? defaultSimulcastConfig.video.layers.length > 1
    : true;

  const onSimulcastToggleChange = () => {
    const role = policy?.roles?.[roleName] as roleType;
    if (simulcastEnabled) {
      dispatch(
        patchRoleInStore({
          ...role,
          publishParams: {
            ...role.publishParams,
            simulcast: {
              video: {},
              screen: {},
            },
          },
        })
      );

      dispatch(setHasUnsavedRoles(true));
    } else {
      if (!isCustomVideoQualityRole) {
        const minDim = Math.min(
          role?.publishParams.video.width,
          role?.publishParams.video.height
        );
        const aspectRatio = calculateAspectRatio({
          width: role?.publishParams.video.width,
          height: role?.publishParams.video.height,
        });
        const simulcastConfig = getSimulcastConfigForVideoQuality({
          videoQuality: `_${minDim}p`,
          aspectRatio: aspectRatio,
        });
        const layers = getSimulcastLayersForConfig({
          layers: simulcastConfig?.video?.layers,
          videoQuality: `_${minDim}p`,
          aspectRatio: aspectRatio,
        });

        const currentLayers = get(
          policy,
          `roles.${roleName}.publishParams.simulcast.video.layers`,
          []
        ) as [];
        let simulcastLayers: Record<string, unknown>[] = Object.values(
          layers.qualityLayerMap
        );
        // check if some layers are already selected. If yes then use the similar layers
        if (currentLayers.length) {
          simulcastLayers = simulcastLayers.filter(({ rid }) => {
            const l = currentLayers.filter(
              (layer: { rid: string }) => layer.rid === rid
            );
            return !!l.length;
          });
        }
        dispatch(
          patchRoleInStore({
            ...role,
            publishParams: {
              ...role.publishParams,
              simulcast: {
                video: { layers: simulcastLayers },
                screen: {},
              },
            },
          })
        );

        dispatch(setHasUnsavedRoles(true));
      }
    }
  };
  const res = {
    width: (policy?.roles?.[roleName] as roleType)?.publishParams.video.width,
    height: (policy?.roles?.[roleName] as roleType)?.publishParams.video.height,
  };
  const simulcastVideoQuality = !isCustomVideoQuality(res)
    ? `_${Math.min(res.width, res.height)}p`
    : "custom";
  const simulcastConfig = getSimulcastConfigForVideoQuality({
    videoQuality: simulcastVideoQuality,
    aspectRatio: aspectRatio,
  });

  const simulcastVideoQualities = getSimulcastLayersForConfig({
    layers: simulcastConfig?.video?.layers,
    videoQuality: simulcastVideoQuality,
    aspectRatio: aspectRatio,
  });
  const customerSimulcastConfig = (policy?.roles?.[roleName] as roleType)
    ?.publishParams?.simulcast;
  const selectedSimulcastVideoQualities = getSimulcastLayersForConfig({
    layers: customerSimulcastConfig?.video?.layers,
    videoQuality: simulcastVideoQuality,
    aspectRatio: aspectRatio,
  });

  const changeSimulcastLayer = (checked: boolean, resolution: string) => {
    const layer = simulcastVideoQualities.qualityLayerMap[resolution];
    const selectedQualityCount =
      selectedSimulcastVideoQualities.simulcastQualities.length;
    let layers = get(
      policy,
      `roles.${roleName}.publishParams.simulcast.video.layers`,
      []
    ) as unknown[];
    if (checked) {
      if (
        selectedQualityCount > 2 &&
        selectedSimulcastVideoQualities.qualityLayerMap[resolution]
          .scaleResolutionDownBy !== 1
      ) {
        delete selectedSimulcastVideoQualities.qualityLayerMap[resolution];
        layers =
          Object.values(selectedSimulcastVideoQualities.qualityLayerMap).filter(
            layer => !!layer
          ) || ([] as unknown[]);
      }
    } else {
      layers.push(layer);
    }
    layers = (layers as { scaleResolutionDownBy: number }[]).sort(
      (
        a: { scaleResolutionDownBy: number },
        b: { scaleResolutionDownBy: number }
      ) => a.scaleResolutionDownBy - b.scaleResolutionDownBy
    );
    const role = policy.roles[roleName] as roleType;
    role.publishParams.simulcast.video.layers = layers;
    dispatch(patchRoleInStore(role));
    dispatch(setHasUnsavedRoles(true));
  };

  return (
    <>
      <Flex
        direction="column"
        css={{
          mt: "$10",
          pb: "$10",
          minWidth: "100%",
          borderBottom: "$space$px solid $border_default",
        }}
      >
        <SettingsTitle
          key="canSimulcast"
          title="When simulcast is enabled, peers of this role will publish multiple video layers with selected video quality as the maximum. Simulcast can only be enabled when Video Quality is 360p or above."
          text="Can Publish Simulcast"
        />
        <Flex css={{ mt: "$6" }} align="center">
          <Switch
            onCheckedChange={onSimulcastToggleChange}
            checked={simulcastEnabled}
            disabled={
              isCustomVideoQualityRole || !hasTwoOrMorePossibleSimulcastLayers
            }
          />
          <Text variant="caption" css={{ c: "$on_surface_low", ml: "$8" }}>
            {simulcastEnabled ? "Enabled" : "Disabled"}
          </Text>
        </Flex>
      </Flex>
      {simulcastEnabled && !isCustomVideoQualityRole ? (
        <Flex
          direction="column"
          ref={cardRef}
          css={{
            mt: "$10",
            pb: "$10",
            minWidth: "100%",
            borderBottom: "$space$px solid $border_default",
          }}
        >
          <SettingsTitle
            key="canSimulcast"
            title="When simulcast is enabled, peers of this role will publish multiple video layers with selected video quality as the maximum. A minimum of 2 layers must be always selected."
            text="Video Simulcast Layers"
            css={{ mb: "$4" }}
          />

          <Dropdown.Root open={open} onOpenChange={setOpen}>
            <Dropdown.Trigger
              onClick={() => {
                return;
              }}
              asChild
              css={{ r: "$1", w: "100%", bg: "$surface_brighter" }}
            >
              <Flex
                justify="between"
                align="center"
                css={{ bg: "$surface_brighter !important", p: "$6" }}
              >
                <Text variant="body2" css={{ c: "$on_surface_low" }}>
                  Select Simulcast Layers
                </Text>
                <Flex align="center">
                  {/* animate cheveron down icon to rotate 180deg when open is toggled*/}
                  <ChevronDownIcon
                    width={20}
                    height={20}
                    style={{
                      transition: "transform 0.2s ease-in-out",
                      transform: open ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                </Flex>
              </Flex>
            </Dropdown.Trigger>

            <Dropdown.Content
              align="end"
              css={{
                w: `${
                  cardRef?.current
                    ? Math.floor(cardRef?.current?.offsetWidth)
                    : 0
                }px`,
              }}
              className="bg-surface-light shadow-2xl px-0"
              sideOffset={10}
              onInteractOutside={() => setOpen(false)}
            >
              {simulcastVideoQualities?.simulcastQualities?.map(
                (resKey: string) => {
                  const layer = simulcastVideoQualities.qualityLayerMap[resKey];
                  const checked =
                    selectedSimulcastVideoQualities.simulcastQualities.includes(
                      resKey
                    );

                  return (
                    <Dropdown.CheckboxItem
                      key={resKey}
                      onSelect={e => e.preventDefault()}
                      checked={checked}
                      onCheckedChange={() =>
                        changeSimulcastLayer(checked, resKey)
                      }
                      css={{ p: "$6" }}
                    >
                      <Flex align="center">
                        <Checkbox.Root
                          id={layer.rid}
                          checked={checked}
                          css={{
                            bg: "$on_surface_medium",
                            borderColor: "$on_surface_medium",
                            '&[data-state="checked"]': {
                              bg: "$on_surface_medium",
                            },
                          }}
                        >
                          <Checkbox.Indicator css={{ c: "$secondary_dim" }}>
                            <CheckIcon width={16} height={16} />
                          </Checkbox.Indicator>
                        </Checkbox.Root>
                        <Text
                          variant="caption"
                          css={{ ml: "$6", c: "$on_surface_medium" }}
                        >
                          {resKey}
                        </Text>
                      </Flex>
                    </Dropdown.CheckboxItem>
                  );
                }
              )}
            </Dropdown.Content>
          </Dropdown.Root>
          <Flex css={{ flexWrap: "wrap" }}>
            {selectedSimulcastVideoQualities?.simulcastQualities?.map(
              (resKey: string) => (
                <Flex
                  key={resKey}
                  css={{
                    c: "$on_surface_medium",
                    bg: "$secondary_dim",
                    r: "$0",
                    p: "$2 $4",
                    mr: "$2",
                    minWidth: "max-content",
                    mt: "$4",
                  }}
                >
                  <Text
                    variant="xs"
                    css={{ mr: "$2", color: "$on_surface_medium" }}
                  >
                    {resKey}
                  </Text>

                  <CrossIcon
                    width={12}
                    height={16}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      changeSimulcastLayer(true, resKey);
                    }}
                  />
                </Flex>
              )
            )}
          </Flex>
        </Flex>
      ) : null}
    </>
  );
}

export default SimulcastConfig;
