import { Button, Flex, Text } from "@100mslive/roomkit-react";
import phone from "../assets/images/phone.svg";
export default function MobileDeployment({ link, className }) {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      css={{
        gap: "$10",
        textAlign: "center",
        borderRadius: "$2",
        maxWidth: "$64",
        padding: "1rem",
      }}
      className={`bg-surface-light ${className}`}
    >
      <img width={48} height={66} src={phone} alt="phone" />
      <Text variant="h6">Mobile support coming soon!</Text>
      <Text
        variant="body2"
        css={{ fontWeight: "$regular", color: "$textMedWhite" }}
      >
        For now, please switch to a desktop or laptop to view your dashboard
      </Text>
      <a href={link}>
        <Button className="bg-secondary-default p-3">Go back to Website</Button>
      </a>
    </Flex>
  );
}
