import React, { useEffect, useState } from "react";
import {
  VirtualBackground,
  VirtualBackgroundMedia,
  VirtualBackgroundMedia_MediaType,
} from "@100mslive/types-prebuilt/elements/virtual_background";
import api from "src/api";
import StatusString from "src/components/Common/StatusString";
import toastr from "src/components/Common/toastr";
import { defaultLayout } from "src/data/defaultLayout";
import PrebuiltSidebarItemWrapper from "src/pages/Prebuilt/PrebuiltSidebarItemWrapper";
import { CheckIcon, ExitIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, Loading, Text } from "@100mslive/roomkit-react";
import PrebuiltBackgroundMediaPreview from "./PrebuiltVirtualBackgroundMedia";
import {
  defaultLayouts,
  MAX_FILE_SIZE_IN_KB,
  PREBUILT_COMPONENT_TITLES,
} from "../../helpers/constants";
import { validateVirtualBackground } from "../validator/previewScreenValidations";

type Props = {
  data: VirtualBackground;
  setData: (data: VirtualBackground) => void;
  invalidFields: { [key in keyof VirtualBackground]?: string } | undefined;
};

const defaultData =
  defaultLayout?.["screens"]?.["preview"]?.["default"]?.["elements"]?.[
    "virtual_background"
  ];
export const virtualBackground = {
  ui: {
    title: PREBUILT_COMPONENT_TITLES.VIRTUAL_BACKGROUND,
    hasSwitch: true,
    tooltipText:
      "Add your own backgrounds by adding URL of your image or video. Recommended image or video size is 1920x1080px. Turn on the camera to use this feature if enabled",
  },
  path: "screens.preview.default.elements.virtual_background",
  Component: PrebuiltVirtualBackground,
  defaultData: defaultData,
  validator: validateVirtualBackground,
};

function PrebuiltVirtualBackground({ data, setData, invalidFields }: Props) {
  const [backgroundMedia, setBackgroundMedia] = useState<
    VirtualBackgroundMedia[]
  >([]);
  const [invalidErrorMessage, setinvalidErrorMessage] = useState("");

  const addVirtualBackgroundBackgroundData = (url = "") => {
    backgroundMedia.push({
      url: url,
      default: backgroundMedia.length ? false : true,
      media_type: VirtualBackgroundMedia_MediaType.IMAGE,
    });
    setBackgroundMedia(backgroundMedia);
    data.background_media = backgroundMedia;
    setData(data);
  };

  const backgroundMediaSet = new Set(backgroundMedia.map(data => data.url));

  const setMedia = (index: number, url: string, isDefault: boolean) => {
    backgroundMedia[index].url = url;
    backgroundMedia[index].default = isDefault;
    if (isDefault) {
      for (let i = 0; i < backgroundMedia.length; i++) {
        if (i !== index) {
          backgroundMedia[i].default = false;
        }
      }
    }
    setBackgroundMedia(backgroundMedia);
    data.background_media = backgroundMedia;
    setData(data);
  };

  const handleDefaultImageSelect = (image: string) => {
    if (backgroundMediaSet.has(image.toString())) {
      for (let i = 0; i < backgroundMedia.length; i++) {
        if (backgroundMedia[i].url === image) {
          removeMedia(i);
          break;
        }
      }
    } else {
      backgroundMedia.push({
        url: image,
        default: backgroundMedia.length ? false : true,
        media_type: VirtualBackgroundMedia_MediaType.IMAGE,
      });
      setBackgroundMedia(backgroundMedia);
      data.background_media = backgroundMedia;
      setData(data);
    }
  };

  const removeMedia = (index: number) => {
    const tempBackgroundMedia = backgroundMedia[index];
    backgroundMedia.splice(index, 1);
    if (backgroundMedia.length > 0 && tempBackgroundMedia?.default === true) {
      backgroundMedia[0].default = true;
    }
    setBackgroundMedia(backgroundMedia);
    data.background_media = backgroundMedia;
    setData(data);
  };

  useEffect(() => {
    setBackgroundMedia(data?.background_media || []);
    //eslint-disable-next-line
  }, [data?.background_media]);

  const [loading, setLoading] = useState(false);
  const handleImageChange = async () => {
    const input = document.createElement("input") as HTMLInputElement;
    input.type = "file";
    input.accept = "image/png, image/jpeg";
    async function inputChange(this: GlobalEventHandlers, ev: Event) {
      const file = (ev.target as HTMLInputElement)?.files?.[0];
      if (file) {
        try {
          const fileSizeKiloBytes = file.size / 1024;
          if (fileSizeKiloBytes > MAX_FILE_SIZE_IN_KB) {
            setinvalidErrorMessage(
              `Backgrounds should not be more than ${
                MAX_FILE_SIZE_IN_KB / 1024
              }MB in size. Reduce the size and try again.`
            );
            return;
          }

          const formData = new FormData();

          formData.append("image", file);
          setLoading(true);
          const res = await api
            .service("dashboard", {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .post("layouts/upload-image", formData);
          if (res.data.success) {
            toastr.success(res?.data?.msg);
            addVirtualBackgroundBackgroundData(res.data?.url || "");
            setinvalidErrorMessage("");
          } else {
            throw Error(res?.data?.msg);
          }
        } catch (e: any) {
          const message = e.response?.data?.msg || e.message;
          console.error(message);
          toastr.error(message);
        } finally {
          setLoading(false);
        }
      }
    }
    input.onchange = inputChange;
    input.click();
  };

  return (
    <PrebuiltSidebarItemWrapper
      title="Custom Backgrounds"
      tooltipText="Recommended image ratio is 16:9. Maximum 2MB file size."
    >
      <Text variant="xs" css={{ c: "$on_surface_low", my: "$4" }}>
        Select the thumbnail to add it to Prebuilt UI.
      </Text>
      <Flex
        css={{
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {defaultLayouts.map(image => {
          return (
            <Flex
              key={image}
              css={{
                cursor: "pointer",
                display: "grid",
                gridTemplateColumns: "1fr",
                mb: "$4",
                position: "relative",
              }}
              onClick={() => handleDefaultImageSelect(image)}
            >
              <img
                alt=""
                src={image}
                style={{
                  height: "62px",
                  width: "62px",
                  borderRadius: "5px",
                  objectFit: "cover",
                  gridRowStart: 1,
                  gridColumnStart: 1,
                  zIndex: "-1",
                  opacity: backgroundMediaSet.has(image) ? "0.5" : 1,
                }}
              />
              {backgroundMediaSet.has(image.toString()) ? (
                <Box
                  css={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    w: "100%",
                    h: "100%",
                    gridRowStart: 1,
                    gridColumnStart: 1,
                    zIndex: "-1",
                  }}
                >
                  <CheckIcon height={32} width={32} />
                </Box>
              ) : (
                <></>
              )}
            </Flex>
          );
        })}
      </Flex>
      <Flex
        css={{ justifyContent: "space-between", maxHeight: "40px", mb: "$1" }}
      >
        <Button
          disabled={false}
          variant="standard"
          onClick={handleImageChange}
          css={{
            width: "stretch",
            borderRadius: "$0",
            fontSize: "$sm",
            right: "0",
            p: "$3 $4",
            // mr: "$3",
            cursor: "pointer",
          }}
        >
          {loading ? (
            <Loading width={20} height={20} />
          ) : (
            <ExitIcon
              style={{ transform: "rotate(270deg)" }}
              width={20}
              height={20}
            />
          )}
          <Text variant="sub2" css={{ ml: "$4" }}>
            Upload Image
          </Text>
        </Button>
      </Flex>
      <Text variant="xs" css={{ c: "$on_surface_low", mt: "$1" }}>
        Select a default virtual background by clicking the image thumbnail.
      </Text>
      {backgroundMedia?.map((media: VirtualBackgroundMedia, index: number) => {
        return (
          <PrebuiltBackgroundMediaPreview
            media={media}
            index={index}
            key={media.url}
            setMedia={setMedia}
            removeMedia={removeMedia}
            setinvalidErrorMessage={setinvalidErrorMessage}
          />
        );
      })}
      <StatusString
        content={
          invalidErrorMessage
            ? invalidErrorMessage
            : (invalidFields?.background_media as string)
        }
      />
    </PrebuiltSidebarItemWrapper>
  );
}

export default PrebuiltVirtualBackground;
