import React, { useEffect, useState } from "react";
import { Input } from "@100mslive/roomkit-react";

function TimeInput({ onChange, timeFactor, _key, value, ...props }) {
  const [inputValue, setInputValue] = useState(0);

  useEffect(() => {
    if (value) {
      setInputValue(value / timeFactor);
    } else {
      setInputValue("");
    }
  }, [timeFactor, value]);

  return (
    <Input
      type="number"
      value={inputValue}
      onChange={event => {
        const timeValue = parseInt(event.target.value);
        setInputValue(timeValue);
        onChange(_key, timeValue * timeFactor);
      }}
      {...props}
    />
  );
}

export default TimeInput;
