import React, { FC } from "react";
import { Box, Button, Flex, Text } from "@100mslive/roomkit-react";

interface SectionHeaderProps {
  title: string;
  body?: string;
  buttonText: string;
  link?: string;
  tag?: JSX.Element | null;
}

const SectionHeader: FC<SectionHeaderProps> = ({
  title,
  body,
  buttonText,
  link,
  tag,
}) => (
  <Flex justify="between" css={{ w: "100%" }}>
    <Box css={{ w: "100%" }}>
      <Flex justify="start" gap="2" align="center">
        <Text
          variant="h5"
          css={{ color: "$on_surface_high", fontWeight: "$semiBold" }}
        >
          {title}
        </Text>
        {tag}
      </Flex>
      {body ? (
        <Text
          variant="sm"
          css={{ mt: "$4", color: "$on_surface_medium", maxWidth: "90%" }}
        >
          {body}
        </Text>
      ) : null}
    </Box>
    {link ? (
      <a href={link} target="_blank" rel="noreferrer">
        <Button
          outlined
          variant="standard"
          css={{
            borderRadius: "$0",
            fontSize: "$sm",
            fontWeight: "$semiBold",
            color: "$on_surface_high",
          }}
        >
          {buttonText}
        </Button>
      </a>
    ) : null}
  </Flex>
);

export default SectionHeader;
