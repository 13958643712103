import React from "react";
import { Flex } from "@100mslive/roomkit-react";

function ResponsiveLayout({
  width,
  height,
  children,
}: {
  width: number | string;
  height: number | string;
  children: React.ReactNode;
}) {
  return (
    <Flex
      css={{
        w: width,
        h: height,
        border: "$space$px solid $border_default",
        r: "$0",
      }}
    >
      {children}
    </Flex>
  );
}

export default ResponsiveLayout;
