import React, { FC, useState } from "react";
import { AppAnalytics } from "src/helpers";
import { CheckIcon, CopyIcon } from "@100mslive/react-icons";
import { Button, Text } from "@100mslive/roomkit-react";
import { copyToClipboard } from "../../helpers/utils";
interface CopyButtonProps {
  value: string;
  buttonCSS?: Record<string, string>;
  disabled?: boolean;
  btnId?: string;
  componentId?: string;
}

// Add props for click events

const CopyButton: FC<CopyButtonProps> = ({
  value,
  buttonCSS = {},
  disabled = false,
  btnId,
  componentId,
}) => {
  const [copied, setCopied] = useState(false);
  if (value === "") {
    return <></>;
  }
  return (
    <Button
      outlined
      variant="standard"
      disabled={disabled}
      css={{
        position: "relative",
        display: "inline-block",
        p: "$2",
        borderRadius: "$0",
        color: "$on_surface_medium",
        ...buttonCSS,
      }}
      onClick={e => {
        e.preventDefault();
        if (!copied) {
          copyToClipboard(value);
          setCopied(true);

          AppAnalytics.track("btn.clicked", {
            btnId: `${btnId}.copied`,
            componentId: componentId,
            value: value,
          });

          setTimeout(() => setCopied(false), 1500);
        }
      }}
    >
      {copied ? (
        <>
          <Text
            variant="xs"
            css={{
              p: "$2 $4",
              w: "max-content",
              position: "absolute",
              top: "-$12",
              bg: "$surface_brighter",
              c: "$on_surface_medium",
              zIndex: "20",
              borderRadius: "$0",
            }}
          >
            Copied
          </Text>
          <CheckIcon height={16} width={16} />
        </>
      ) : (
        <CopyIcon height={16} width={16} />
      )}
    </Button>
  );
};

export default CopyButton;
