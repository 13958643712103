import React, { ComponentProps, HTMLAttributes } from "react";
import { styled } from "@100mslive/roomkit-react";

// @ts-ignore
const StyledTextArea = styled("textarea", {
  padding: "8px 12px",
  backgroundColor: "$surface_bright",
  marginLeft: "1px",
  border: "solid $space$px $border_bright",
  resize: "none",
  outline: "none",
  width: "99%",
  fontSize: "16px",
  cursor: "pointer",
  fontFamily: "$sans",
  lineHeight: "inherit",
  borderRadius: "$0",
  "&:focus": {
    boxShadow: "0 0 0 1px $colors$primary_default",
    border: "1px solid transparent",
  },
});

const TextArea = (
  props: HTMLAttributes<HTMLTextAreaElement> &
    ComponentProps<typeof StyledTextArea>
) => {
  return <StyledTextArea {...props} />;
};

export default TextArea;
