import React, { ComponentProps, PropsWithChildren } from "react";
import { Flex } from "@100mslive/roomkit-react";

const TwoColumnFlex: React.FC<
  PropsWithChildren<
    ComponentProps<typeof Flex> & {
      w?: number;
      gap?: string;
      columnGap?: string;
      children: React.ReactNode[];
      column1Direction?: "row" | "column" | "rowReverse" | "columnReverse";
      column2Direction?: "row" | "column" | "rowReverse" | "columnReverse";
    }
  >
> = ({
  children,
  w = 50,
  column1Direction = "row",
  column2Direction = "row",
  columnGap = "$16",
  ...props
}) => (
  <Flex css={{ minWidth: "100%", ...props.css }} align="center" {...props}>
    <Flex
      css={{ mr: columnGap, width: `${w}%`, h: "100%" }}
      direction={column1Direction}
    >
      {children[0]}
    </Flex>
    <Flex
      css={{ width: `${100 - w}%`, h: "100%" }}
      direction={column2Direction}
    >
      {children[1]}
    </Flex>
  </Flex>
);

export default TwoColumnFlex;
