import React, {
  ComponentProps,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { Layout } from "@100mslive/types-prebuilt";
import { isEmpty, set } from "lodash";
import {
  fetchPolicyInfo,
  setHasUnsavedTranscriptionChanges,
  toggleTranscription,
  updatePlugins,
  updatePolicyAndAppLayout,
  updatePolicyInfo,
  updateTranscription,
} from "src/actions/RolesActions";
import SectionHeader from "src/components/Common/SectionHeader";
import { SideList, SideListItem } from "src/components/Common/SideList";
import toastr from "src/components/Common/toastr";
import TemplateLayout from "src/components/layout/templateLayout";
import Tag from "src/components/Tag/Tag";
import { API_CALL_STATE, transcriptionModes } from "src/constants";
import { AppAnalytics } from "src/helpers/analytics_helper";
import {
  getPriorityRolesArray,
  INTERNAL_RECORDER_ROLE,
  isHLSViewer,
  isVisibleRole,
} from "src/helpers/roles_helper";
import useActiveLinks from "src/hooks/useActiveLink";
import { TRANSCRIPTION_OUTPUT_MODES } from "src/services/policy/constants";
import { getRolesWithoutHLSViewerSettings } from "src/services/policy/roles";
import { RootState } from "src/store/reducers";
import { roleTemplatePolicy, TranscriptionType } from "src/types/policyTypes";
import { slugify } from "src/utils";
import { validateTranscriptionFields } from "src/validations/destinations";
import { Flex } from "@100mslive/roomkit-react";
import AdvancedTranscriptionSettings from "./AdvancedTranscriptionSettings";
import ClosedCaptions from "./ClosedCaptions";
import LiveTranscriptionHLS from "./LiveTranscriptionHLS";
import SpeakerLabelledTranscriptionSettings from "./SpeakerLabelledTranscriptionSettings";
interface Sections {
  [key: string]: {
    title: string;
    children: string[];
  };
}

// eslint-disable-next-line complexity
const Transcription: FC<ComponentProps<typeof Flex> & { policyId: string }> = ({
  policyId,
}) => {
  const dispatch = useDispatch();
  const hasUnsavedTranscriptionChanges = useSelector(
    (state: RootState) => state.roles.hasUnsavedTranscriptionChanges
  );
  const invalidFields = useSelector(
    (state: RootState) => state.roles.invalidFields
  );
  const transcriptions = useSelector(
    (state: RootState) => state.roles.policyInfo.destinations.transcriptions
  );

  const policyInfo = useSelector((state: RootState) => state.roles.policyInfo);

  const roleLayouts = useSelector(
    (state: RootState) => state.appLayouts.roleLayouts
  );
  const transcriptionId: string =
    Object.keys(policyInfo?.destinations?.transcriptions || {})?.[0] || "";
  const updatePolicyStatus = useSelector(
    (state: RootState) => state.roles.updatePolicyStatus
  );
  const policyDerivedStates = useSelector(
    (state: RootState) => state.roles.policyDerivedStates
  );
  const transcriptionsPlugins = useSelector(
    (state: RootState) => state.roles.policyInfo.plugins?.transcriptions
  );

  const roles = useSelector((state: RootState) => state.roles.policyInfo.roles);
  const roleNames = Object.keys(roles) || [];

  const visibleRoleObject = useMemo(() => {
    const tempRoleLayouts = Object.keys(roleLayouts).map(role => {
      if (!roleLayouts[role].role) {
        roleLayouts[role].role = role;
      }
      return { ...roleLayouts[role] } as Layout;
    });
    return getRolesWithoutHLSViewerSettings({
      roles: Object.values(policyInfo?.roles) || ([] as roleTemplatePolicy[]),
      roleLayouts: tempRoleLayouts || ([] as Layout[]),
    });
  }, [policyInfo, roleLayouts]);
  const visibleRoles = (visibleRoleObject?.roles || []).map(role => role?.name);

  const internalRecorderSubscriptionList = Object.values(
    roles?.[INTERNAL_RECORDER_ROLE]?.subscribeParams?.subscribeToRoles || {}
  ) as string[];
  const internalRecorderRolesForSubscription = getPriorityRolesArray(
    roleNames.filter(isVisibleRole).filter(role => !isHLSViewer(role))
  );

  const transcription = policyInfo?.destinations?.transcriptions?.[
    transcriptionId as string
  ] as TranscriptionType;

  const transcriptionModeList: string[] = transcription?.outputModes || [];

  const sections: Sections = {
    "Closed Captions": {
      title: "Closed Captions",
      children: [],
    },
    "Live Transcription (HLS)": {
      title: "Live Transcription (HLS)",
      children: [],
    },
    "Post Call Transcription": {
      title: "Post Call Transcription",
      children: ["AI-Generated Summary"],
    },
    "Advanced Transcription Configuration": {
      title: "Advanced Transcription Configuration",
      children: [],
    },
  };
  const isLiveTranscriptionEnabled = !!(
    (transcriptions?.[transcriptionId]?.modes || [])?.length > 0 &&
    transcriptions?.[transcriptionId]?.modes?.includes("live")
  );
  const isClosedCaptionEnabled = !!(
    (transcriptions?.[transcriptionId]?.modes || [])?.length > 0 &&
    transcriptions?.[transcriptionId]?.modes?.includes(
      transcriptionModes.CAPTION
    )
  );
  const isPostCallTranscriptionEnabled = !!(
    (transcriptions?.[transcriptionId]?.modes || [])?.length > 0 &&
    transcriptions?.[transcriptionId]?.modes?.includes("recorded")
  );
  useEffect(() => {
    if (!policyId) {
      return;
    }
    dispatch(fetchPolicyInfo(policyId));
    dispatch(setHasUnsavedTranscriptionChanges(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyId]);

  useEffect(() => {
    if (updatePolicyStatus === API_CALL_STATE.DONE) {
      dispatch(fetchPolicyInfo(policyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePolicyStatus]);

  useEffect(() => {
    try {
      AppAnalytics.track("tab.clicked", {
        platform: "hms-dashboard",
        componentId: "template.config.transcription",
        btnId: "tab.template.transcription",
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const toggleTranscriptionHandler = (enabled: boolean, mode: string) => {
    dispatch(setHasUnsavedTranscriptionChanges(true));
    dispatch(toggleTranscription(enabled as boolean, mode));

    try {
      AppAnalytics.track("toggle.clicked", {
        platform: "hms-dashboard",
        componentId: "template.config.transcription",
        btnId:
          mode === "live"
            ? "toggle.liveTranscription"
            : "toggle.speakerLabelledTranscription",
        value: enabled,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const toggleClosedCaptionsRoles = (roles: string[]) => {
    const closedCaptionPlugin = transcriptionsPlugins?.find(
      plugin => plugin.mode === transcriptionModes.CAPTION
    );

    const closedCaptionRoles = new Set(
      closedCaptionPlugin?.permissions?.admin ?? []
    );

    roles.forEach((role: string) => {
      if (closedCaptionRoles.has(role)) {
        closedCaptionRoles.delete(role);
      } else {
        closedCaptionRoles.add(role);
      }
    });

    const newRolesArray = Array.from(closedCaptionRoles);
    const tempPolicy = { ...policyInfo };
    tempPolicy.plugins = {
      ...policyInfo.plugins,
      transcriptions: [
        {
          permissions: {
            admin: newRolesArray,
          },
          mode: transcriptionModes.CAPTION,
        },
      ],
    };

    dispatch(updatePlugins(tempPolicy.plugins));
  };

  const onSaveHandler = (): void => {
    let transcriptionsValidationResult = { isValid: true };

    if (Object.keys(invalidFields?.["plugins"] || {}).length) {
      toastr.error("Please enter valid values to save changes");
    } else {
      if (
        !isEmpty(policyInfo?.destinations?.transcriptions?.[transcriptionId])
      ) {
        const transcription = policyInfo?.destinations?.transcriptions?.[
          transcriptionId
        ] as TranscriptionType;
        transcriptionsValidationResult = validateTranscriptionFields({
          transcription: transcription,
        });
        dispatch(
          updateTranscription({
            transcription: transcription,
            name: transcription?.name || "",
          })
        );
      }

      if (transcriptionsValidationResult.isValid) {
        dispatch(setHasUnsavedTranscriptionChanges(false));
        if (!isEmpty(roleLayouts)) {
          dispatch(
            updatePolicyAndAppLayout({
              policy: policyInfo,
              roleLayouts: roleLayouts,
            })
          );
        } else {
          dispatch(updatePolicyInfo(policyId, policyInfo));
        }
      }
    }
  };

  const setTranscription = useCallback(
    (key: string, value: ReactNode) => {
      const transcription = policyInfo?.destinations?.transcriptions?.[
        transcriptionId
      ] as TranscriptionType;
      set(transcription, key, value);
      dispatch(
        updateTranscription({
          transcription: transcription,
          name: (transcription?.name || transcriptionId) as string,
        })
      );
    },
    [dispatch, policyInfo?.destinations?.transcriptions, transcriptionId]
  );

  const outputModeClicked = useCallback(
    (mode: string) => {
      const valArr = Object.values(TRANSCRIPTION_OUTPUT_MODES);
      const keyArr = Object.keys(TRANSCRIPTION_OUTPUT_MODES);
      const idx = valArr.findIndex(val => val === mode);

      if (transcriptionModeList.includes(keyArr[idx])) {
        const i = transcriptionModeList.indexOf(keyArr[idx]);
        transcriptionModeList.splice(i, 1);
      } else {
        transcriptionModeList.push(keyArr[idx]);
      }
      transcription.outputModes = transcriptionModeList;
      dispatch(
        updateTranscription({
          transcription: transcription,
          name: transcription?.name as string,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, transcription?.outputModes]
  );

  const activeSection = useActiveLinks("config-subheading");

  const sideList = (
    <Flex
      direction="column"
      css={{
        minWidth: "210px",
        position: "sticky",
        overflow: "clip auto",
        top: "$20",
        h: "100%",
      }}
    >
      {Object.keys(sections).map((section: string) => {
        const url = slugify(sections[section].title);
        return (
          <HashLink
            to={`?tab=transcription#${url}`}
            style={{ width: "100%" }}
            key={`#${url}`}
            onClick={e => e.stopPropagation()}
          >
            <SideList
              showChevron={sections[section].children.length > 0}
              title={sections[section].title}
              subtitle=""
              active={
                activeSection === url ||
                sections[section]?.children.some(
                  child => slugify(child) === activeSection
                )
              }
            >
              {sections[section]?.children.map(child => {
                const childUrl = slugify(sections[section].title);
                return (
                  <HashLink
                    to={`?tab=transcription#${childUrl}`}
                    style={{ width: "100%" }}
                    key={`#${childUrl}`}
                  >
                    <SideListItem
                      title={child}
                      active={activeSection === childUrl}
                      onClick={() => null}
                    />
                  </HashLink>
                );
              })}
            </SideList>
          </HashLink>
        );
      })}
    </Flex>
  );

  return (
    <TemplateLayout
      unsaved={hasUnsavedTranscriptionChanges}
      onSaveClick={onSaveHandler}
    >
      {sideList}
      <Flex
        direction="column"
        css={{
          rowGap: "$12",
          w: "100%",
          left: "240px",
          flexGrow: 1,
          mb: "$10",
        }}
      >
        <SectionHeader
          title="Transcription"
          buttonText="Read Guide"
          body="Enable and configure transcription and AI-generated summary settings to get the most out of your meetings."
          link={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/how-to-guides/enable-transcription-and-summary`}
          tag={<Tag label="BETA" />}
        />
        <ClosedCaptions
          transcription={transcriptions?.[transcriptionId] as TranscriptionType}
          setTranscription={setTranscription}
          isTranscriptionEnabled={isClosedCaptionEnabled}
          roles={visibleRoles || []}
          toggleClosedCaptionsRoles={toggleClosedCaptionsRoles}
          selectedRolesForCaption={
            transcriptionsPlugins?.find(
              plugin => plugin.mode === transcriptionModes.CAPTION
            )?.permissions?.admin
          }
        />
        <LiveTranscriptionHLS
          transcription={transcriptions?.[transcriptionId] as TranscriptionType}
          setTranscription={setTranscription}
          toggleTranscription={toggleTranscriptionHandler}
          isTranscriptionEnabled={
            policyDerivedStates.transcriptionEnabled &&
            isLiveTranscriptionEnabled
          }
        />
        <SpeakerLabelledTranscriptionSettings
          outputModeClicked={outputModeClicked}
          policyInfo={policyInfo}
          transcription={transcriptions?.[transcriptionId] as TranscriptionType}
          toggleTranscription={toggleTranscriptionHandler}
          setTranscription={setTranscription}
          invalidFields={invalidFields}
          isTranscriptionEnabled={
            policyDerivedStates.transcriptionEnabled &&
            isPostCallTranscriptionEnabled
          }
          transcriptionModeList={transcriptionModeList}
        />
        {policyDerivedStates.transcriptionEnabled ? (
          <AdvancedTranscriptionSettings
            transcription={
              transcriptions?.[transcriptionId] as TranscriptionType
            }
            setTranscription={setTranscription}
            internalRecorderSubscriptionList={internalRecorderSubscriptionList}
            internalRecorderRolesForSubscription={
              internalRecorderRolesForSubscription
            }
          />
        ) : null}
      </Flex>
    </TemplateLayout>
  );
};

export default Transcription;
