import React from "react";
import { Link } from "react-router-dom";
import { AlertTriangleIcon, CodeIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, Text } from "@100mslive/roomkit-react";

const RoomCodesDisabledState = () => (
  <Box css={{ p: "$10", w: "100%" }}>
    <Box
      css={{
        backgroundColor: "$surface_bright",
        p: "$12",
        borderRadius: "$1",
      }}
    >
      <Flex
        css={{
          mb: "$14",
          backgroundColor: "$surface_brighter",
          p: "$md",
          borderRadius: "$1",
          border: "1px solid $alert_warning",
          color: "$alert_warning",
          gap: "$4",
        }}
      >
        <AlertTriangleIcon
          style={{ minHeight: "20px", minWidth: "20px", color: "inherit" }}
        />
        <Box>
          <Text
            variant="sm"
            css={{
              fontWeight: "$semiBold",
              color: "$on_surface_high",
              mb: "$2",
            }}
          >
            Room Codes are disabled
          </Text>
          <Text
            variant="sm"
            css={{
              color: "$on_surface_medium",
            }}
          >
            100ms Prebuilt Room Link and Room Code can’t be generated for this
            room as Room Codes are disabled globally for your account. You can
            enable Room Codes by going to&nbsp;
            <Link to="/developer">
              <span className="text-primary-light hover:text-primary-default">
                Developer Section
              </span>
            </Link>
            &nbsp;and choose to enable “Room Codes”.
          </Text>
        </Box>
      </Flex>
      <Flex
        align="center"
        gap="4"
        css={{ color: "$primary_bright", mb: "$10" }}
      >
        <CodeIcon height={40} width={40} style={{ color: "inherit" }} />
        <Box>
          <Text
            variant="lg"
            css={{ color: "$on_surface_high", fontWeight: "$semiBold" }}
          >
            Join Room with Auth Token
          </Text>
          <Text variant="sm" css={{ color: "$on_surface_medium", mt: "$2" }}>
            With Room Codes being disabled, you can still join a room using auth
            token.
          </Text>
        </Box>
      </Flex>
      <a
        style={{ display: "block", width: "100%" }}
        target="_blank"
        rel="noreferrer"
        href={`${process.env.REACT_APP_WEBSITE_URL}docs/concepts/v2/concepts/security-and-tokens#how-to-get-auth-tokens`}
      >
        <Button css={{ w: "100%", fontWeight: "$semiBold", fontSize: "$sm" }}>
          Learn How to Generate Auth Token
        </Button>
      </a>
    </Box>
  </Box>
);

export default RoomCodesDisabledState;
