import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChevronLeft } from "@material-ui/icons";
import { isFunction } from "lodash";
import { UNSAVED_CHANGES_TEXT } from "src/constants";
import { setHasUnsavedPrebuiltChanges } from "src/store/appLayout/actions";
import { RootState } from "src/store/reducers";
import { Box, Flex, Text } from "@100mslive/roomkit-react";
import Tag from "../Tag/Tag";

function PrebuiltCustomisationMenu({
  onBack,
  children,
  title,
  subtitle,
  description,
  isNewText = "",
}: {
  onBack?: () => void;
  isNewText?: string;
  children: React.ReactNode | React.ReactNode[];
  title: string;
  subtitle?: string;
  description?: string;
}) {
  const dispatch = useDispatch();
  const hasUnsavedPrebuiltChanges = useSelector(
    (state: RootState) => state.appLayouts.hasUnsavedPrebuiltChanges
  );
  return (
    <>
      <Flex
        css={{
          py: "$4",
          pb: "$md",
          w: "100%",
          borderBottom: "1px solid $border_default",
        }}
        direction="column"
        align="center"
      >
        <Flex
          justify="between"
          align="center"
          css={{
            w: "100%",
          }}
        >
          <Flex justify="center" align="start">
            {isFunction(onBack) ? (
              <Box
                onClick={() => {
                  if (hasUnsavedPrebuiltChanges) {
                    if (!window.confirm(UNSAVED_CHANGES_TEXT)) {
                      return;
                    } else {
                      dispatch(setHasUnsavedPrebuiltChanges(false));
                      onBack();
                    }
                  } else {
                    onBack();
                  }
                }}
                css={{
                  cursor: "pointer",
                  c: "on_surface_medium",
                  mr: "$4",
                  width: "$9",
                }}
              >
                <ChevronLeft />
              </Box>
            ) : null}
            <Flex direction="column" align="start" justify="center">
              <Flex align="center" css={{ mb: subtitle ? "$2" : "0" }}>
                <Text
                  variant="sub1"
                  css={{ c: "$on_surface_high", lineHeight: "$md" }}
                >
                  {title}
                </Text>
                {isNewText ? (
                  <Tag
                    variant="caption"
                    css={{ ml: "$4", p: "$2 $4", r: "$0" }}
                    label={isNewText}
                  />
                ) : null}
              </Flex>
              {subtitle ? (
                <Text
                  variant="caption"
                  css={{ c: "$on_surface_low", fontWeight: "$regular" }}
                >
                  {subtitle}
                </Text>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
        {description ? (
          <Text
            variant="caption"
            css={{ c: "$on_surface_low", py: "$md", fontWeight: "$regular" }}
          >
            {description}
          </Text>
        ) : null}
      </Flex>
      {children}
    </>
  );
}

export default PrebuiltCustomisationMenu;
