import React, { ComponentProps, PropsWithChildren } from "react";
import { Button } from "@100mslive/roomkit-react";
import { HighlightBannerCSS } from "../Tag/Tag";
function DashboardButton({
  children,
  variant,
  ...props
}: PropsWithChildren<
  {
    variant?: "standard" | "danger" | "primary" | "highlight" | undefined;
  } & ComponentProps<typeof Button>
>) {
  const isHighlight = variant === "highlight";
  const highLightCSS = isHighlight
    ? {
        ...HighlightBannerCSS.normal,
        ...HighlightBannerCSS.disabled,
        ...HighlightBannerCSS.hover,
        ...HighlightBannerCSS.active,
      }
    : {};

  const buttonVariant = isHighlight ? undefined : variant;
  return (
    <Button
      variant={buttonVariant}
      {...props}
      css={{
        ...props.css,
        ...highLightCSS,
      }}
    >
      {children}
    </Button>
  );
}

export default DashboardButton;
