import { FileIcon, HomeIcon, Svg100MsLogoIcon } from "@100mslive/react-icons";
import { Button, Flex, Text } from "@100mslive/roomkit-react";
import Maintenance from "../assets/images/Downtime.svg";

const websiteURL = "https://100ms.live";

const Downtime = () => (
  <Flex
    align="center"
    justify="center"
    css={{
      position: "relative",
      height: "100vh",
    }}
  >
    <Svg100MsLogoIcon
      style={{
        position: "absolute",
        top: "64px",
        height: "40px",
        width: "155px",
      }}
    />
    <Flex
      align="center"
      justify="center"
      direction="column"
      css={{
        h: "80%",
        w: "70%",

        position: "relative",
      }}
    >
      <Flex
        css={{
          position: "absolute",
          top: "0",
          height: "100%",
          width: "100%",
          zIndex: "-1",
          background:
            "radial-gradient(circle, rgba(0 0 0 / 0%) 0%, rgba(11 13 15 / 100%) 70%)",
        }}
      />
      <Flex
        css={{
          position: "absolute",
          top: "0",
          height: "100%",
          width: "100%",
          backgroundImage:
            "linear-gradient(rgb(45 52 64 / 50%) 1px, transparent 1px), linear-gradient(to right, rgb(45 52 64 / 50%) 1px, #0B0D0F 1px)",
          backgroundSize: "84px 84px",
          zIndex: "-10",
        }}
      />
      <img
        alt="downtime"
        src={Maintenance}
        className="h-48 w-60 mx-auto mb-10 z-10"
      />
      <Text variant="h4" css={{ fontWeight: "$semiBold", color: "$white" }}>
        Under maintenance
      </Text>
      <Text
        css={{
          color: "$on_surface_medium",
          mt: "$4",
          textAlign: "center",
        }}
      >
        The 100ms dashboard is currently under <br />
        scheduled maintenance and will be back soon.
      </Text>

      <Flex
        css={{
          gap: "$10",
          mt: "$14",
        }}
      >
        <a href={websiteURL}>
          <Button icon>
            <HomeIcon />
            Go to website
          </Button>
        </a>
        <a href={`${websiteURL}/docs`}>
          <Button icon variant="standard" outlined>
            <FileIcon />
            Visit docs
          </Button>
        </a>
      </Flex>
    </Flex>
  </Flex>
);

export default Downtime;
