import React, { FC } from "react";
import { statusTypeColors } from "src/constants";
import { textVariants } from "src/types/common";
import { Box, Text } from "@100mslive/roomkit-react";

interface StatusStringProps {
  content: string;
  type?: string;
  color?: string;
  textVariant?: textVariants;
}

const StatusString: FC<StatusStringProps> = ({
  content,
  textVariant = "caption",
  type = "error",
  color = "",
}) => {
  const getTypeColor = (type: string) => {
    return statusTypeColors[type] || "$alert_error_default";
  };
  if (content) {
    return (
      <Box css={{ mt: "$4" }}>
        <Text variant={textVariant} css={{ c: color || getTypeColor(type) }}>
          {content}
        </Text>
      </Box>
    );
  }
  return null;
};

export default StatusString;
