import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { AppAnalytics, openDevrevWidget } from "src/helpers";
import {
  BookIcon,
  BuildingIcon,
  ChatIcon,
  ExitIcon,
  LayersIcon,
  PersonContactIcon,
} from "@100mslive/react-icons";
import { Box, Dropdown, Flex, Text } from "@100mslive/roomkit-react";
import { toggleRightSidebar } from "../../store/actions";
import { currentUser, getCurrentWorkspaceID } from "../../utils";
import NameTile from "../Common/NameTile";
import DropdownItemContent from "../Nav/DropdownItemContent";
import HeaderItem from "../Nav/HeaderItem";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      isOrgAdmin: false,
      isDevrevPlug: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  componentDidUpdate() {
    if (
      this.props.workspace &&
      this.props.workspace.is_admin !== this.state.isOrgAdmin
    ) {
      this.setState({ isOrgAdmin: this.props.workspace.is_admin });
    }
  }

  iconStyle = {
    height: "20px",
    width: "20px",
    color: "inherit",
    marginRight: "8px",
  };

  headerItemList = [
    {
      text: "Docs",
      link: `${process.env.REACT_APP_WEBSITE_URL}docs`,
      icon: <BookIcon style={this.iconStyle} />,
      btnId: "docs.header",
    },
    {
      text: "Talk to Sales",
      link: `${process.env.REACT_APP_WEBSITE_URL}contact`,
      icon: <PersonContactIcon style={this.iconStyle} />,
      btnId: "talk.to.sales",
    },
    {
      text: "Support",
      link: "",
      onClick: openDevrevWidget,
      icon: <ChatIcon style={this.iconStyle} />,
      btnId: "support.header",
    },
  ];

  dropdownItemCSS = {
    p: "0",
    bg: "$surface_default",
    "&:hover": { backgroundColor: "$surface_brighter" },
    w: "100%",
  };

  render() {
    const { first_name } = currentUser();
    const { email = "", last_name = "" } = currentUser();
    return (
      <header id="page-topbar">
        <Flex
          justify="between"
          css={{
            alignItems: "center",
            borderBottom: "1px solid $border_default",
            w: "100%",
            backgroundColor: "$background_default",
            p: "$sm $14",
            gap: "$md",
          }}
        >
          <Flex>{this.props.leftSideButtons}</Flex>
          {this.props.titleComponent}
          <Flex justify="center" align="center">
            {this.headerItemList.map(headerItem => (
              <HeaderItem key={headerItem.text} {...headerItem} />
            ))}
            <Dropdown.Root>
              <Dropdown.Trigger
                onClick={() =>
                  AppAnalytics.track("btn.clicked", {
                    componentId: "header",
                    btnId: "profile.header",
                  })
                }
                css={{ borderRadius: "$0" }}
              >
                <NameTile
                  first_name={first_name ? first_name : "User"}
                  last_name={last_name}
                />
              </Dropdown.Trigger>
              <Dropdown.Content
                align="end"
                side="bottom"
                sideOffset={8}
                css={{
                  border: "1px solid $border_default",
                  borderRadius: "$0",
                  background: "$surface_default",
                  p: "$xs",
                  overflowY: "visible",
                  width: "max-content",
                }}
              >
                <Flex align="center" css={{ gap: "$6", marginBottom: "$4" }}>
                  <NameTile
                    first_name={first_name ? first_name : "User"}
                    last_name={last_name}
                    unClickable
                  />
                  <Box>
                    <Text
                      variant="sm"
                      css={{
                        fontWeight: "$semiBold",
                        color: "$on_surface_high",
                        maxWidth: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {`${first_name ? first_name : "User"} ${last_name}`}
                    </Text>
                    <Text variant="xs" css={{ color: "$on_surface_low" }}>
                      {email}
                    </Text>
                  </Box>
                </Flex>
                {this.state.isOrgAdmin && (
                  <>
                    <Dropdown.ItemSeparator
                      css={{ mx: "0", backgroundColor: "$border_default" }}
                    />
                    <Dropdown.Item
                      onClick={() =>
                        AppAnalytics.track("btn.clicked", {
                          btnId: "workspace.settings",
                          componentId: "header",
                        })
                      }
                      css={this.dropdownItemCSS}
                    >
                      <DropdownItemContent
                        link="/settings/workspace"
                        text="Workspace Settings"
                        icon={<LayersIcon />}
                      />
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() =>
                        AppAnalytics.track("btn.clicked", {
                          btnId: "organization.settings",
                          componentId: "header",
                        })
                      }
                      css={this.dropdownItemCSS}
                    >
                      <DropdownItemContent
                        link="/settings/organization"
                        text="Organization Settings"
                        icon={<BuildingIcon />}
                      />
                    </Dropdown.Item>
                  </>
                )}
                <Dropdown.ItemSeparator
                  css={{ mx: "0", backgroundColor: "$border_default" }}
                />
                <Dropdown.Item
                  onClick={() =>
                    AppAnalytics.track("btn.clicked", {
                      btnId: "log.out",
                      componentId: "header",
                    })
                  }
                  css={this.dropdownItemCSS}
                >
                  <DropdownItemContent
                    link="/logout"
                    text="Log out"
                    icon={<ExitIcon />}
                  />
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown.Root>
          </Flex>
        </Flex>
      </header>
    );
  }
}

const mapStatetoProps = state => {
  const workspaceID = getCurrentWorkspaceID();
  const { layoutType } = state.Layout;
  const { workspaces, status } = state.workspace;

  return {
    layoutType,
    workspace: workspaces[workspaceID],
    fetchAllWorkspacesStatus: status.fetchAllWorkspaces,
  };
};

export default connect(mapStatetoProps, {
  toggleRightSidebar,
})(withNamespaces()(Header));
