import React, {
  ComponentPropsWithoutRef,
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { regionMap } from "src/constants";
import { AppAnalytics } from "src/helpers";
import useAppRegionList from "src/hooks/useAppRegionList";
import { RootState } from "src/store/reducers";
import { setRegionInStore } from "src/store/userInfo/actions";
import { Flex, Select } from "@100mslive/roomkit-react";
import TextAndSubtext from "./TextAndSubtext";
import TwoColumnFlex from "./TwoColumnFlex";

interface ClusterRegionSelectComponentProps {
  direction: "row" | "column";
  children: ReactNode[] | undefined;
}

export const ClusterRegionSelect: FC<
  PropsWithChildren<ClusterRegionSelectComponentProps>
> = ({ children, direction = "row" }) => {
  if (direction === "row" && (children as ReactNode[]).length > 1) {
    return (
      <TwoColumnFlex css={{ mb: "$16" }}>
        {children as ReactNode[]}
      </TwoColumnFlex>
    );
  }
  return <Flex css={{ pt: "$8" }}>{children}</Flex>;
};

interface ClusterRegionSelectLabelComponentProps {
  text: string;
  subtitle: string;
  children: (props: { text: string; subtitle: string }) => JSX.Element;
}

export const ClusterRegionSelectLabel = ({
  text,
  subtitle,
  children,
}: ClusterRegionSelectLabelComponentProps) => {
  return children({ text, subtitle });
};

export const DefaultClusterRegionSelectLabel = ({
  text,
  subtitle,
}: {
  text: string;
  subtitle: string;
}) => <TextAndSubtext text={text} subText={subtitle} />;

export const ClusterRegionSelectBox = ({
  componentId,
  ...props
}: ComponentPropsWithoutRef<typeof Select.Select> & {
  componentId: string;
}) => {
  const dispatch = useDispatch();
  const [regionList] = useAppRegionList();

  const region = useSelector((state: RootState) => state.userInfo.region);

  const setRegionOnChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedIndex = e.target?.selectedIndex;
      const selectedRegionKey = regionList[selectedIndex]?.key;
      dispatch(setRegionInStore(selectedRegionKey));

      AppAnalytics.track("btn.clicked", {
        btnId: "server.region.changed",
        componentId: componentId,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regionList]
  );

  return (
    <Select.Root
      className="mt-2 flex"
      css={{
        bg: "$surface_bright",
      }}
    >
      <Select.DefaultDownIcon />
      <Select.Select
        className="h-10 w-full"
        css={{
          bg: "$surface_bright",
          border: "solid $space$px $border_bright",
          cursor: "pointer",
          "&:focus-visible": {
            border: "none",
          },
          ...props.css,
        }}
        onChange={setRegionOnChange}
        defaultValue={regionMap[region]}
        value={regionMap[region]}
        {...props}
      >
        {regionList
          .filter(regionItem => {
            return regionItem.key !== "eu";
          })
          .map(regionItem => {
            return (
              <option
                value={regionItem.name}
                key={regionItem.key}
                selected={region === regionItem.key}
              >
                {regionItem.name}
              </option>
            );
          })}
      </Select.Select>
    </Select.Root>
  );
};
