import React, { useState } from "react";
import { LinkIcon } from "@100mslive/react-icons";
import {
  Box,
  Button,
  Dialog,
  Flex,
  Label,
  QRCode,
  Select,
  Text,
} from "@100mslive/roomkit-react";

function MobileEmbedQRDialog({
  openDialog,
  openDialogFunction,
  activeRole,
  roles,
  roomShortCodeForRoles,
  subdomain,
  roleDescription,
}: {
  openDialog: boolean;
  openDialogFunction: (bool: boolean) => void;
  activeRole: string;
  roles: string[];
  roleDescription?: string;
  subdomain: string | undefined;
  roomShortCodeForRoles: { [key: string]: { value: string } };
}) {
  const deviceArray = [{ platform: "Flutter" }, { platform: "Web" }];
  const [device, setDevice] = useState(deviceArray[0].platform);
  const [role, setRole] = useState(activeRole);

  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);

  return (
    <Dialog.Root onOpenChange={openDialogFunction} open={openDialog}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content
          css={{
            width: "min(600px,80%)",
            p: "$12",
            bg: "$surface_default",
            zIndex: "999",
            position: "fixed",
          }}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Dialog.DefaultClose
            css={{ position: "absolute", top: "$8", right: "$8" }}
          />
          <Dialog.Title>
            <Text variant="h5">Join using another device</Text>
          </Dialog.Title>

          <Flex css={{ pt: "$10", gap: "$10" }}>
            <Flex
              css={{ w: "50%" }}
              justify="center"
              align="start"
              direction="column"
            >
              <Label css={{ mb: "$4" }}>
                <Text variant="body2">Select Role</Text>
              </Label>
              <Select.Root
                css={{
                  width: "100%",
                  bg: "$surface_bright",
                  r: "$0",
                }}
              >
                <Select.DefaultDownIcon />
                <Select.Select
                  //@ts-ignore
                  onChange={setRole}
                  value={role}
                  css={{
                    r: "$0",
                    width: "100%",
                    bg: "$surface_bright",
                    border: "solid $space$px $border_bright",
                    "&:focus-visible": {
                      border: "none",
                    },
                  }}
                >
                  {device &&
                    roles.map(role => {
                      return (
                        <option value={role} key={role}>
                          {role}
                        </option>
                      );
                    })}
                </Select.Select>
              </Select.Root>
              {roleDescription ? (
                <Text
                  variant="caption"
                  css={{
                    c: "$on_surface_low",
                    fontWeight: "$regular",
                    mt: "$4",
                  }}
                >
                  {roleDescription}
                </Text>
              ) : null}

              <Label css={{ mb: "$4", mt: "$10" }}>
                <Text variant="body2">Select Type</Text>
              </Label>

              <Select.Root
                css={{
                  width: "100%",
                  bg: "$surface_bright",
                  r: "$0",
                }}
              >
                <Select.DefaultDownIcon />
                <Select.Select
                  //@ts-ignore
                  onChange={setDevice}
                  value={device}
                  css={{
                    r: "$0",
                    width: "100%",
                    bg: "$surface_bright",
                    border: "solid $space$px $border_bright",
                    "&:focus-visible": {
                      border: "none",
                    },
                  }}
                >
                  {deviceArray.map(option => {
                    const id = option.platform;
                    return (
                      <option value={id} key={id}>
                        {id}
                      </option>
                    );
                  })}
                </Select.Select>
              </Select.Root>
              <Button
                variant="standard"
                loading={loading}
                css={{ r: "$0" }}
                disabled={
                  !device &&
                  !role &&
                  !roomShortCodeForRoles?.[activeRole]?.value
                }
                onClick={() => {
                  navigator.clipboard?.writeText(
                    `https://${subdomain}/meeting/${roomShortCodeForRoles?.[activeRole]?.value}`
                  );
                }}
                icon
              >
                <LinkIcon />
                Copy Invite Link
              </Button>
            </Flex>
            <Flex
              direction="column"
              justify="between"
              css={{
                w: "50%",
                border: "solid $space$px $border_bright",
                p: "$8",
                textAlign: "center",
                borderRadius: "$1",
                bg: "$surface_bright",
              }}
            >
              <Box css={{ w: "100%", cursor: "none", r: "$0" }}>
                <Box css={{ bg: "$white", p: "$4", w: "100%" }}>
                  {roomShortCodeForRoles?.[activeRole]?.value ? (
                    <QRCode
                      value={`https://${subdomain}/meeting/${roomShortCodeForRoles?.[activeRole]?.value}`}
                    />
                  ) : null}
                </Box>
              </Box>
              <Text
                variant="body2"
                css={{
                  fontWeight: "$regular",
                  c: "$on_surface_high",
                  mt: "$4",
                }}
              >
                Scan this QR code on your device to join as {role}
              </Text>
            </Flex>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
export default MobileEmbedQRDialog;
