import React from "react";
import { upperFirst } from "lodash";
import { StatusTagValue } from "src/types/recordingTag";
import {
  AlertTriangleIcon,
  CheckCircleIcon,
  InfoIcon,
  RecordIcon,
} from "@100mslive/react-icons";
import { Flex, Text } from "@100mslive/roomkit-react";

const iconStyle = { height: "16px", width: "16px", color: "inherit" };

const statusData = {
  starting: {
    color: "$alert_error_default",
    icon: <RecordIcon style={iconStyle} />,
  },
  running: {
    color: "$alert_error_default",
    icon: <RecordIcon style={iconStyle} />,
  },
  stopping: {
    color: "$alert_error_default",
    icon: <RecordIcon style={iconStyle} />,
  },
  "post-processing": {
    color: "$alert_error_default",
    icon: <RecordIcon style={iconStyle} />,
  },
  post_processing: {
    color: "$alert_error_default",
    icon: <RecordIcon style={iconStyle} />,
  },
  uploading: {
    color: "$alert_error_default",
    icon: <RecordIcon style={iconStyle} />,
  },
  completed: {
    color: "$alert_success",
    icon: <CheckCircleIcon style={iconStyle} />,
  },
  failed: {
    color: "$alert_warning",
    icon: <AlertTriangleIcon style={iconStyle} />,
  },
};

const StatusTag = ({ status }: { status: StatusTagValue }) => {
  return (
    <Flex
      align="center"
      justify="center"
      css={{
        borderRadius: "$0",
        position: "relative",
        w: "max-content",
      }}
    >
      <Flex
        css={{
          opacity: "0.1",
          position: "absolute",
          backgroundColor: statusData[status].color || "$surface_brighter",
          h: "100%",
          w: "100%",
        }}
      />
      <Flex
        gap="1"
        align="center"
        css={{
          p: "$xs",

          color: statusData[status].color || "$surface_brighter",
        }}
      >
        {statusData[status].icon || <InfoIcon style={iconStyle} />}
        <Text variant="xs" css={{ color: "inherit", fontWeight: "$semiBold" }}>
          {upperFirst(status).replaceAll("_", "-")}
        </Text>
      </Flex>
    </Flex>
  );
};

export default StatusTag;
