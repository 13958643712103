import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isFunction } from "lodash";
import { useCheckTemplateName } from "src/hooks/useCheckTemplateName";
import { PencilDrawIcon } from "@100mslive/react-icons";
import { Button, Text } from "@100mslive/roomkit-react";
import MainDialog from "./MainDialog";
import {
  fetchPolicyInfo,
  UPDATE_TEMPLATE_NAME_RESET,
  updateTemplateName,
} from "../../actions/RolesActions";
import { API_CALL_STATE } from "../../constants";
import Credential from "../Common/Credential";

const EditTemplateNameModal = ({
  policyId,
  buttonText = <></>,
  shouldFetchPolicy = false,
  hideButton = false,
  trigger = false,
  setTrigger,
  css = {},
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(trigger);
  const { templates, updateTemplateNameStatus } = useSelector(
    state => state.roles
  );
  const policyInfo = templates[policyId] || {};
  const [input, setInput] = useState(policyInfo?.name);

  useEffect(() => {
    setInput(policyInfo.name);
  }, [open, policyInfo.name]);

  const {
    error: templateNameError,
    status: checkTemplateNameStatus,
    changeHandler: handleTemplateNameChange,
  } = useCheckTemplateName({
    name: input,
    setTemplateName: name => {
      setInput(name);
    },
  });
  useEffect(() => {
    if (updateTemplateNameStatus === API_CALL_STATE.DONE) {
      setOpen(false);
      if (isFunction(setTrigger)) {
        setTrigger(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTemplateNameStatus]);

  useEffect(() => {
    return () => {
      if (updateTemplateNameStatus === API_CALL_STATE.DONE) {
        dispatch({ type: UPDATE_TEMPLATE_NAME_RESET });
      }
    };
  });

  return (
    <>
      {!hideButton ? (
        !isEmpty(css) ? (
          <Button
            variant="standard"
            css={css}
            onClick={() => {
              setOpen(true);
              if (shouldFetchPolicy && policyId) {
                dispatch(fetchPolicyInfo(policyId));
              }
            }}
          >
            <PencilDrawIcon style={{ height: "20px" }} />
            <> {buttonText}</>
          </Button>
        ) : (
          <button
            onClick={() => {
              setOpen(true);
              if (shouldFetchPolicy && policyId) {
                dispatch(fetchPolicyInfo(policyId));
              }
            }}
            style={{ display: "flex", flex: "row" }}
          >
            <PencilDrawIcon
              className="text-textIcon-disable"
              style={{ height: "20px" }}
            />
            <>{buttonText}</>
          </button>
        )
      ) : null}
      <MainDialog
        open={open || trigger}
        disableButton={templateNameError || input?.trim() === policyInfo?.name}
        loading={updateTemplateNameStatus === "processing"}
        onOpenChange={() => {
          setOpen(false);
          if (isFunction(setTrigger)) {
            setTrigger(false);
          }
        }}
        title="Edit Template Name"
        Icon={<PencilDrawIcon className="text-textIcon-medEmp" />}
        subtitle={
          <Text css={{ color: "$on_surface_medium" }}>
            Change the name of this template.
          </Text>
        }
        content={
          <div className="my-8">
            <Credential
              placeholder="Upto 80 characters"
              defaultValue={input}
              passEvent={true}
              error={templateNameError}
              css={{ minWidth: "100%", h: "40px" }}
              inputCss={{ r: "$0", bg: "$surface_default" }}
              maxLength={80}
              onChangeHandler={e => {
                const inputText = e.target.value;
                handleTemplateNameChange(
                  inputText,
                  "template.rename.success",
                  "edit.template.name.dialog"
                );
              }}
              hideLabel={true}
              showClipboard={false}
              success={
                !(
                  checkTemplateNameStatus === API_CALL_STATE.IN_PROGRESS ||
                  templateNameError
                )
              }
              label=""
              value={input}
            />

            <Text
              variant="caption"
              css={{
                fontWeight: "$regular",
                marginTop: "$4",
                c: "$alert_error_default",
              }}
            >
              {templateNameError}
            </Text>
          </div>
        }
        buttonText="Save"
        mainFunction={() => {
          dispatch(
            updateTemplateName(
              {
                policyId: policyId,
                templateName: input?.trim(),
              },
              [
                "template.rename",
                {
                  templateId: policyId,
                  initialTemplateName: policyInfo.name,
                  changedTemplateName: input?.trim(),
                },
                {
                  templateId: policyId,
                  initialTemplateName: policyInfo.name,
                  attemptedTemplateName: input?.trim(),
                },
              ]
            )
          );
        }}
      />
    </>
  );
};

export default EditTemplateNameModal;
