import React, { ComponentProps, PropsWithChildren } from "react";
import classNames from "classnames";
import { ArrowTopDownIcon } from "@100mslive/react-icons";
import { Flex, Text } from "@100mslive/roomkit-react";
import ascending from "../../assets/icons/ascending.svg";
import descending from "../../assets/icons/descending.svg";
import "./tableNew.scss";

const Table: React.FC<
  PropsWithChildren<
    ComponentProps<typeof Flex> & {
      tableHeaders: Array<string>;
      hideBottomBorders: boolean;
      centerFinalColumn: boolean;
      compact: boolean;
      highlightAll: boolean;
      className: string;
      noHoverEffect: boolean;
      sortingFunctions?: { [key: string]: () => void };
      sortingHeader?: { [key: string]: string };
    }
  >
> = ({
  children,
  tableHeaders,
  hideBottomBorders = false,
  centerFinalColumn = false,
  compact = false,
  highlightAll = false,
  noHoverEffect = false,
  className,
  sortingFunctions,
  sortingHeader,
}) => {
  return (
    <table className={classNames(`w-full table `, className)}>
      <thead className="sticky z-10 top-0 bg-background-default">
        <tr className="w-full border-0">
          {tableHeaders.map(tableHeader => {
            const isSort = sortingFunctions && sortingFunctions[tableHeader];
            return (
              <th
                key={tableHeader}
                className={`table-new-header ${isSort ? "cursor-pointer" : ""}`}
              >
                {tableHeader ? (
                  <Flex
                    align="center"
                    onClick={isSort && sortingFunctions[tableHeader]}
                  >
                    <Text
                      variant="overline"
                      css={{
                        color: "$on_surface_medium",
                        fontWeight: "$semiBold",
                      }}
                    >
                      {tableHeader.toUpperCase()}
                    </Text>
                    {isSort && sortingFunctions[tableHeader] ? (
                      <Text
                        variant="xs"
                        css={{
                          color: "$on_surface_medium",
                          fontWeight: "$semiBold",
                          marginLeft: "$2",
                        }}
                      >
                        <Text style={{ width: "24px" }}>
                          {sortingHeader && sortingHeader[tableHeader] ? (
                            <>
                              {sortingHeader[tableHeader] === "asc" ? (
                                <img
                                  style={{ width: "100%" }}
                                  alt="asc"
                                  src={ascending}
                                />
                              ) : (
                                <img
                                  style={{ width: "100%" }}
                                  alt="desc"
                                  src={descending}
                                />
                              )}
                            </>
                          ) : (
                            <ArrowTopDownIcon />
                          )}
                        </Text>
                      </Text>
                    ) : null}
                  </Flex>
                ) : null}
              </th>
            );
          })}
        </tr>
        {/* Making a sticky row's border sticky requires a hack*/}
        <tr className="bg-border-default h-px min-w-full">
          {tableHeaders.map(tableHeader => (
            <th key={`${tableHeader}-dummy`} className="max-h-px p-0" />
          ))}
        </tr>
      </thead>
      <tbody
        className={`border-border-default tbody-container ${
          highlightAll ? "highlight-all" : ""
        } ${noHoverEffect ? "no-hover-effect" : ""} ${
          compact ? "compact" : ""
        } ${hideBottomBorders ? "hide-bottom-borders" : ""} ${
          centerFinalColumn ? "center-final-col" : ""
        }`}
      >
        {children}
      </tbody>
    </table>
  );
};

export default Table;
