import React from "react";
import { Spinner } from "reactstrap";
import classNames from "classnames";
import { Box } from "@100mslive/roomkit-react";

export default function CenteredSpinner({ className, color, css }) {
  return (
    <Box
      className={classNames(
        "flex items-center justify-center z-[100]",
        className
      )}
      css={{ ...css }}
    >
      <Spinner color={color} />
    </Box>
  );
}
