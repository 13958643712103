import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetchPolicyInfo } from "src/actions/RolesActions";
import { AppAnalytics } from "src/helpers";
import { Flex, Text } from "@100mslive/roomkit-react";

const TemplateList = ({
  templates,
  all_templates,
  viewAllTemplates,
  limitTemplates = false,
}) => {
  const dispatch = useDispatch();

  if (limitTemplates && templates.length) {
    return (
      <Text
        onClick={() => {
          viewAllTemplates();
          AppAnalytics.track("btn.clicked", {
            componentId: "sidebar",
            btnId: "view.all.templates",
          });
        }}
        variant="overline"
        css={{
          color: "$on_surface_high",
          cursor: "pointer",
          paddingLeft: "$18",
          mt: "$md",
          mb: "$4",
          textTransform: "uppercase",
          fontWeight: "$semiBold",
          "&:hover": { color: "$primary_bright" },
        }}
      >
        View all
      </Text>
    );
  }
  return templates.map(key => {
    const template = all_templates[key];
    const activeTemplate =
      window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 1
      ] === template.id;
    const subdomain = template?.subdomain || template?.subdomain_name || "";
    return (
      <li
        className="hover:opacity-80"
        key={template.id}
        style={{ marginTop: "20px" }}
        onClick={() =>
          AppAnalytics.track("btn.clicked", {
            btnId: "template.selection",
            componentId: "sidebar",
            value: template.name,
          })
        }
      >
        <NavLink to={`/templates/${template.id}`}>
          <Flex
            direction="column"
            css={{
              paddingLeft: "$18",
              w: "100%",
            }}
            onClick={() => {
              dispatch(fetchPolicyInfo(template.id));
            }}
          >
            <Text
              variant="sm"
              css={{
                color: activeTemplate ? "$on_surface_high" : "$on_surface_low",
                maxWidth: "150px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {template.name}
            </Text>

            <Flex
              align="center"
              gap="1"
              css={{
                display: subdomain ? "flex" : "none",
                w: "100%",
              }}
            >
              <Text
                variant="xs"
                css={{
                  color: activeTemplate
                    ? "$on_surface_medium"
                    : "$on_surface_low",
                  maxWidth: "150px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  mt: "$1",
                }}
              >
                {subdomain}
              </Text>
            </Flex>
          </Flex>
        </NavLink>
      </li>
    );
  });
};

export default TemplateList;
