import React, { ReactNode, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ConfigMultiSelect from "src/components/Common/ConfigMultiSelect";
import StatusString from "src/components/Common/StatusString";
import TabGroup from "src/components/Common/TabGroup";
import ValuePill from "src/components/Common/ValuePill";
import { VIDEO_QUALITY_FOR_POLICY, VideoQuality } from "src/constants";
import {
  getPriorityRolesArray,
  INTERNAL_RECORDER_ROLE,
  isHLSViewer,
  isVisibleRole,
} from "src/helpers";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { RootState } from "src/store/reducers";
import { policyTemplateType, RecordingConfigType } from "src/types/policyTypes";
import { CheckCircleIcon, ChevronDownIcon } from "@100mslive/react-icons";
import { Box, Dropdown, Flex, Switch, Text } from "@100mslive/roomkit-react";

const recordingTypeTabs = [
  {
    name: "Custom",
    id: 0,
    icon: <></>,
    title: "Custom",
    value: true,
  },
  {
    name: "Browser",
    id: 1,
    icon: <></>,
    title: "Browser",
    value: false,
  },
];
export const CompositeRecordingSubtitle = () => (
  <Text
    variant="body2"
    css={{ c: "$on_surface_low", fontWeight: "$regular", mt: "$4" }}
  >
    Composes a single mp4 file with all tracks of all peers in the room&nbsp;
    <a
      href={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/features/recordings/recording-modes/composite-recordings`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Text
        variant="body2"
        css={{ c: "$primary_bright", fontWeight: "$regular" }}
        as="span"
      >
        Learn more
      </Text>
    </a>
  </Text>
);

// eslint-disable-next-line complexity
export const CompositeRecording = ({
  policyInfo,
  recordingsConfig,
  setRecordingInfo,
  setRecordingsConfig,
  toggleBrowserComposite,
  isHLSorRTMPEnabled,
  subscribeToClicked,
  isValidInternalRecorderState,
}: {
  policyInfo: policyTemplateType;
  recordingsConfig: RecordingConfigType;
  setRecordingInfo: (key: string, value: ReactNode) => void;
  setRecordingsConfig: (key: string, value: ReactNode) => void;
  toggleBrowserComposite: (bool?: boolean) => void;
  isHLSorRTMPEnabled: boolean;
  subscribeToClicked: (str: string) => void;
  isValidInternalRecorderState: boolean;
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [recordingTabChanged, setRecordingTabChanged] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const browserComposite =
    recordingsConfig?.compositeRecording?.browserComposite || {};
  const enableAutoStartRecording = browserComposite?.autoStart || false;
  const isBrowserCompositeEnabled = browserComposite?.enabled || false;
  const customComposite =
    recordingsConfig?.compositeRecording?.customComposite || {};
  const isCustomCompositeEnabled = customComposite?.enabled || false;
  const showRecordingTypeTabs =
    (isCustomCompositeEnabled && !isBrowserCompositeEnabled) ||
    recordingTabChanged;
  const showRecordingsAutoJoinText =
    isCustomCompositeEnabled && showRecordingTypeTabs;
  const showBrowserCompositeDisclaimer =
    isBrowserCompositeEnabled && recordingTabChanged;

  const internalRecorderSubscriptionList = Object.values(
    policyInfo?.roles?.[INTERNAL_RECORDER_ROLE]?.subscribeParams
      ?.subscribeToRoles || {}
  ) as string[];

  const hasSubdomain =
    // @ts-ignore
    policyInfo?.subdomain || policyInfo?.subdomain_name;
  const roles = useSelector((state: RootState) => state.roles.policyInfo.roles);

  const roleNames = Object.keys(roles) || [];
  const internalRecorderRolesForSubscription = getPriorityRolesArray(
    roleNames.filter(isVisibleRole).filter(role => !isHLSViewer(role))
  );

  const toggleDropDown = () => setDropdown(!dropdown);

  const layers = Object.keys(VIDEO_QUALITY_FOR_POLICY)
    .filter(r => parseInt(r) >= 480)
    .map(t => VideoQuality[`_${t}p`]["16:9"]);

  const activeRecordingType = useMemo(() => {
    return recordingTypeTabs.find(
      tab => tab.value === isCustomCompositeEnabled
    );
  }, [isCustomCompositeEnabled]);
  return (
    <Flex direction="column" css={{ minWidth: "100%", paddingBottom: "$10" }}>
      <Flex
        direction="column"
        css={{
          pb: "$10",
          minWidth: "100%",
          borderBottom:
            isBrowserCompositeEnabled || showRecordingTypeTabs
              ? "$space$px solid $border_default"
              : "none",
        }}
      >
        <SettingsTitle
          key="Enable Composite Recording"
          title="Enable browser recordings for this template and rooms created after enabling the same."
          text="Enable Composite Recording"
        />
        <Flex css={{ mt: "$4", position: "relative" }}>
          <Switch
            checked={isBrowserCompositeEnabled || isCustomCompositeEnabled}
            onCheckedChange={(checked: boolean) => {
              if (checked) {
                toggleBrowserComposite(checked);
              } else {
                setRecordingTabChanged(false);
                if (isBrowserCompositeEnabled) {
                  toggleBrowserComposite(checked);
                }
                if (isCustomCompositeEnabled) {
                  setRecordingsConfig(
                    "compositeRecording.customComposite.enabled",
                    false
                  );
                }
                setRecordingInfo("upload", null);
              }
            }}
          />
        </Flex>
      </Flex>
      {showRecordingTypeTabs ? (
        <Flex
          direction="column"
          css={{
            py: "$10",
            minWidth: "100%",
            borderBottom: "$space$px solid $border_default",
          }}
        >
          <SettingsTitle
            key="typeOfCompositeRecording"
            title="Only available for those who are currently using Custom Composite (previously called SFU) Recording. Once you select Browser Composite and save, you can go back to Custom Composite only through the templates API."
            text="Type of Composite Recording"
          />
          <TabGroup
            activeTab={activeRecordingType?.name}
            setActiveTab={(tabName: string) => {
              const selectedTab = recordingTypeTabs.find(
                tab => tab.name === tabName
              );
              if (selectedTab?.name === "Browser") {
                setRecordingsConfig(
                  "compositeRecording.customComposite.enabled",
                  false
                );
                toggleBrowserComposite(true);
                setRecordingTabChanged(true);
              } else {
                setRecordingsConfig(
                  "compositeRecording.customComposite.enabled",
                  true
                );
                toggleBrowserComposite(false);
                setRecordingTabChanged(true);
              }
            }}
            useParams={false}
            btnId={`type.composite.recording.${activeRecordingType?.name.toLowerCase()}`}
            componentId={`templates.config.recording.composite.recording.type.${activeRecordingType?.name.toLowerCase()}`}
            tabs={recordingTypeTabs}
            css={{ mt: "$4", w: "100%" }}
          />
        </Flex>
      ) : null}
      {showRecordingsAutoJoinText ? (
        <Box css={{ paddingTop: "$10" }}>
          <Text
            variant="body2"
            css={{ c: "$on_surface_medium", fontWeight: "$regular" }}
          >
            <CheckCircleIcon /> Recordings will auto-start on join
          </Text>
        </Box>
      ) : null}
      {showBrowserCompositeDisclaimer ? (
        <Box
          css={{
            border: "$space$px solid $border_bright",
            bg: "$surface_bright",
            p: "$8",
            r: "$1",
            w: "100%",
          }}
        >
          <Flex css={{ color: "$on_surface_medium" }} align="center">
            <Text
              variant="body2"
              css={{ display: "inline", fontWeight: "$semiBold" }}
            >
              Disclaimer
            </Text>
          </Flex>
          <Text
            variant="sm"
            css={{
              color: "$on_surface_low",
              mt: "$4",
            }}
          >
            Creates a unified recording of all peers in the room, presenting it
            as a browser view, see&nbsp;
            <a
              href={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/features/recordings/recording-modes/comparing-recording-modes`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Text
                variant="body2"
                css={{ c: "$primary_bright", fontWeight: "$regular" }}
                as="span"
              >
                Browser vs Custom Composite Recording.
              </Text>
            </a>
          </Text>
        </Box>
      ) : null}
      {isBrowserCompositeEnabled ? (
        <>
          {hasSubdomain ? (
            <Flex
              direction="column"
              css={{
                py: "$10",
                minWidth: "100%",
                borderBottom: isBrowserCompositeEnabled
                  ? "$space$px solid $border_default"
                  : "none",
              }}
            >
              <SettingsTitle
                key="autoStart"
                title="Automatically start recording a room when the first peer joins"
                text="Enable Auto-start on Room Join"
              />
              <Box css={{ mt: "$4" }}>
                <Switch
                  checked={browserComposite?.autoStart || false}
                  onCheckedChange={checked =>
                    setRecordingsConfig(
                      "compositeRecording.browserComposite.autoStart",
                      checked
                    )
                  }
                />
              </Box>

              {isHLSorRTMPEnabled && enableAutoStartRecording ? (
                <StatusString
                  type="warning"
                  content="Warning: Enabling this will disable HLS and RTMP streaming"
                />
              ) : null}
            </Flex>
          ) : null}
          {browserComposite?.autoStart ? (
            <Flex
              direction="column"
              css={{
                py: "$10",
                minWidth: "100%",
                borderBottom: "$space$px solid $border_default",
              }}
            >
              {" "}
              <SettingsTitle
                key="audioOnly"
                title="Only audio will be recorded during the session"
                text="Enable Audio-only Recording"
              />
              <Box css={{ mt: "$4" }}>
                <Switch
                  checked={browserComposite?.audioOnly || false}
                  onCheckedChange={checked =>
                    setRecordingsConfig(
                      "compositeRecording.browserComposite.audioOnly",
                      checked
                    )
                  }
                />
              </Box>{" "}
            </Flex>
          ) : null}
          <Flex
            direction="column"
            css={{
              py: "$10",
              minWidth: "100%",
              borderBottom: "$space$px solid $border_default",
            }}
          >
            <SettingsTitle
              key="broadcasterRoles"
              title="Choose roles to be broadcasted over the live stream"
              text="Broadcaster Roles"
            />
            <ConfigMultiSelect
              inputText="Select roles"
              optionsArray={internalRecorderRolesForSubscription}
              onCheckedChange={subscribeToClicked}
              isCheckedCondition={role =>
                (
                  (internalRecorderSubscriptionList as string[]) || []
                )?.includes(role)
              }
            />
            <Flex css={{ w: "100%", flexWrap: "wrap" }}>
              {internalRecorderSubscriptionList.map((roleName: string) => (
                <ValuePill
                  key={roleName}
                  content={roleName}
                  onClick={() => subscribeToClicked(roleName)}
                />
              ))}
            </Flex>
            <StatusString
              content={
                isValidInternalRecorderState
                  ? ""
                  : "At least one role must be selected"
              }
            />
          </Flex>
          <Flex
            direction="column"
            css={{
              pt: "$10",
              minWidth: "100%",
            }}
          >
            <SettingsTitle
              key="videoResolution"
              title="Select recording resolution to select width and height for your recordings."
              text="Video Resolution"
            />
            <Flex css={{ mt: "$4", position: "relative" }}>
              <Dropdown.Root
                open={dropdown}
                onOpenChange={toggleDropDown}
                css={{ w: "50%" }}
                modal={false}
              >
                <Dropdown.Trigger css={{ w: "min(100%,232px)" }}>
                  <Flex
                    css={{
                      p: "$5 $6",
                      bg: "$surface_bright",
                      r: "$0",
                      border: "solid $space$px $border_bright",
                    }}
                    ref={cardRef}
                    justify="between"
                  >
                    <Text variant="body2">
                      {`${browserComposite?.width}x${browserComposite?.height}`}
                    </Text>

                    <ChevronDownIcon
                      width={20}
                      height={20}
                      style={{
                        transition: "transform 0.3s ease",
                        transform: dropdown ? "rotate(180deg)" : "rotate(0deg)",
                      }}
                    />
                  </Flex>
                </Dropdown.Trigger>
                <Dropdown.Portal>
                  <Dropdown.Content
                    sideOffset={10}
                    css={{
                      w: `${
                        cardRef?.current
                          ? Math.floor(cardRef?.current?.offsetWidth)
                          : 0
                      }px`,
                      height: "auto",
                      maxHeight: "$96",
                      overflowY: "hidden",
                      textAlign: "center",
                    }}
                  >
                    {layers.map((type, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          css={{ p: "$4 $8" }}
                          onClick={() => {
                            setRecordingsConfig(
                              "compositeRecording.browserComposite.width",
                              type.width
                            );
                            setRecordingsConfig(
                              "compositeRecording.browserComposite.height",
                              type.height
                            );
                          }}
                        >
                          <Text variant="caption">
                            {`${type?.width}x${type?.height}`}
                          </Text>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Content>
                </Dropdown.Portal>
              </Dropdown.Root>
            </Flex>
          </Flex>{" "}
        </>
      ) : null}
    </Flex>
  );
};
