import { liveStreamRoleNames } from "src/services/cmsModels/Policy/constants";
import { Text } from "@100mslive/roomkit-react";

export const INTERNAL_RECORDER_ROLE = "__internal_recorder";
export const HLS_VIEWER_ROLE = "hls-viewer";
export const NEW_HLS_VIEWER_ROLE = liveStreamRoleNames["viewer-near-realtime"];
export const HLS_DESTINATIONS = "hlsDestinations";
export const isInternalRecorderRole = role => role === INTERNAL_RECORDER_ROLE;
export const isHLSViewer = role => role === HLS_VIEWER_ROLE;
export const isNewHLSViewer = role => role === NEW_HLS_VIEWER_ROLE;
export const isVisibleRole = (name = "") => name !== INTERNAL_RECORDER_ROLE;
export const getVisibleRoles = array => array?.filter(isVisibleRole);
export const isPriorityRole = (name = "") =>
  ["broadcaster", "host", "speaker"].includes(name);

const rolePriorities = [
  [liveStreamRoleNames.broadcaster, "speaker", "host"],
  ["moderator", liveStreamRoleNames["co-broadcaster"], "guest"],
  ["viewer-realtime", "listener", "viewer"],
  ["viewer-near-realtime"],
  ["viewer-on-stage"],
];

export const getPriorityRoles = (a, b) => {
  const priorityA = rolePriorities.findIndex(p => p.includes(a));
  const priorityB = rolePriorities.findIndex(p => p.includes(b));
  if (priorityA >= 0 && priorityB >= 0) {
    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    } else {
      return a.localeCompare(b);
    }
  } else {
    if (priorityA >= 0 && priorityB < 0) {
      return -1;
    } else if (priorityA < 0 && priorityB >= 0) {
      return 1;
    } else {
      return a.localeCompare(b);
    }
  }
};

export const getPriorityRolesArray = arr => arr.sort(getPriorityRoles);

export const getJoinRoomToolTipTextConfigPage = ({
  room_enabled,
  room_id,
  rolesLength,
  subdomain,
  roomCodeDisabled,
  layoutsLength,
}) => {
  let message;

  if (roomCodeDisabled) {
    message = (
      <Text variant="sm">
        Room code is disabled for this Workspace. Please enable it from
        developer section to join
      </Text>
    );
  }
  if (room_enabled === false && room_id) {
    message = (
      <Text variant="sm">
        The{" "}
        <a className="text-primary-light" href={`/room-details/${room_id}`}>
          room
        </a>{" "}
        has been disabled. Please enable to join
      </Text>
    );
  }
  if (rolesLength === 0) {
    message = <Text variant="sm">There are no roles present</Text>;
  }
  if (!subdomain) {
    message = (
      <Text variant="sm">
        Cannot join room since there’s no prebuilt attached to this template
      </Text>
    );
  }

  if (layoutsLength === 0) {
    message = <Text variant="sm">No Prebuilt attached to this template</Text>;
  }
  return message;
};
