import { useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Box, Input, Text } from "@100mslive/roomkit-react";
import { checkPasswordPolicy } from "../../helpers/utils";
import "./passwordInput.scss";

export default function PasswordInput({
  placeholder,
  firstName,
  setValidPassword,
  mainFunction = () => {},
}) {
  const [policy, setPolicy] = useState({
    uppercaseCheck: false,
    lowercaseCheck: false,
    numberOrSpecialCheck: false,
    minimumCheck: false,
    nameCheck: false,
    isPasswordValid: false,
  });
  const [blur, setBlur] = useState(null);
  const [focus, setFocus] = useState(null);
  let textInput = useRef(null);
  const checkPasswordPolicyHandle = e => {
    const password = e.target.value;
    const {
      uppercaseCheck,
      lowercaseCheck,
      numberOrSpecialCheck,
      minimumCheck,
      nameCheck,
      isPasswordValid,
    } = checkPasswordPolicy(password, firstName);
    isPasswordValid
      ? ReactTooltip.hide(textInput)
      : ReactTooltip.show(textInput);
    if (setValidPassword) {
      setValidPassword(isPasswordValid);
    }
    setPolicy({
      ...policy,
      uppercaseCheck,
      lowercaseCheck,
      numberOrSpecialCheck,
      minimumCheck,
      nameCheck,
      isPasswordValid,
    });
  };

  return (
    <Box>
      <Box
        data-tip
        data-for="PasswordPolicyTooltip"
        ref={ref => (textInput = ref)}
      >
        <Input
          placeholder={placeholder}
          name="password"
          type="password"
          className="form-control"
          css={{
            backgroundColor: "$secondary_dim !important",
            border: "none !important",
            "&:focus": { outline: "2px solid $primary_bright !important" },
          }}
          onChange={e => {
            checkPasswordPolicyHandle(e);
            mainFunction(e);
          }}
          onBlur={() => {
            ReactTooltip.hide(textInput);
            setBlur(true);
            setFocus(false);
          }}
          onFocus={() => {
            setFocus(true);
            setBlur(false);
          }}
        />
        {!focus && blur && !policy.isPasswordValid ? (
          <Text
            variant="caption"
            css={{
              color: "$alert_error_default",
              marginTop: "$3",
            }}
          >
            This password is invalid, please try again
          </Text>
        ) : null}
      </Box>
      <ReactTooltip
        id="PasswordPolicyTooltip"
        type=""
        effect="solid"
        place="bottom"
        backgroundColor="#303740"
        overridePosition={({ left, top }) => ({ left: left - 21, top })}
        className="password-tooltip"
      >
        {firstName && !policy.nameCheck ? (
          <>
            <span className="text-red-500 px-1">&#9888; </span>
            Password cannot contain name
            <Box
              css={{ margin: "$2 0" }}
              className="separator-password-policy"
            />
          </>
        ) : null}
        The password should contain at least
        <ul>
          <li className={policy.uppercaseCheck ? "success" : "fail"}>
            1 uppercase letter (A-Z)
          </li>
          <li className={policy.lowercaseCheck ? "success" : "fail"}>
            1 lowercase letter (a-z)
          </li>
          <li className={policy.numberOrSpecialCheck ? "success" : "fail"}>
            1 number or special character (!#/&%)
          </li>
          <li className={policy.minimumCheck ? "success" : "fail"}>
            10 characters
          </li>
        </ul>
      </ReactTooltip>
    </Box>
  );
}
