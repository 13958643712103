import { CrossIcon } from "@100mslive/react-icons";
import { Box, Text } from "@100mslive/roomkit-react";

const Chip = ({ text, className, onClick, css }) => {
  return (
    <Box css={{ ...css }} className={className}>
      <Text
        className="truncate"
        variant="body1"
        css={{ fontWeight: "$regular", pr: "$4" }}
      >
        {text}
      </Text>
      <button onClick={onClick}>
        <CrossIcon className="h-4" />
      </button>
    </Box>
  );
};

export default Chip;
