import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce, isEmpty } from "lodash";
import { fetchTemplatesData } from "src/actions/RolesActions";
import TextAndSubtext from "src/components/Common/TextAndSubtext";
import TwoColumnFlex from "src/components/Common/TwoColumnFlex";
import { validationMessagesKeys } from "src/constants";
import { AppAnalytics } from "src/helpers";
import {
  checkRoomName,
  mountCreateRoomConfigForStore,
  setLargeRoomInStore,
  setRoomNameInStore,
  setTemplateIDinStore,
} from "src/store/createRoom/actions";
import { RootState } from "src/store/reducers";
import { fetchGeoInfo } from "src/store/userInfo/actions";
import { currentWorkspaceHipaa } from "src/utils";
import { Messages } from "src/validations";
import { Flex, Select, Switch, Text } from "@100mslive/roomkit-react";
import {
  DefaultRoomNameLabel,
  RoomName,
  RoomNameInput,
  RoomNameInputBox,
  RoomNameLabel,
} from "../../components/Common/RoomName";

const CreateYourRoom = () => {
  const dispatch = useDispatch();
  const roomName = useSelector((state: RootState) => state.createRoom.roomName);

  const templateId = useSelector(
    (state: RootState) => state.createRoom.templateId
  );
  const largeRoom = useSelector(
    (state: RootState) => state.createRoom.largeRoom
  );

  const templates = useSelector((state: RootState) => state.roles.templates);
  const all_templates = useSelector(
    (state: RootState) => state.roles.all_templates
  );

  const [roomNameError, setRoomNameError] = useState("");
  const checkRoomNameError = useSelector(
    (state: RootState) => state.createRoom.roomNameError
  );

  const isHipaa = currentWorkspaceHipaa();

  useEffect(() => {
    if (isEmpty(all_templates)) {
      dispatch(fetchTemplatesData());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(all_templates)) {
      dispatch(mountCreateRoomConfigForStore(all_templates[0].id));
    }

    return () => {
      dispatch(fetchGeoInfo());
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const updateRoomName = useCallback(
    (input: string) => {
      dispatch(setRoomNameInStore(input));
    },
    [dispatch]
  );

  const debounceCheckRoomName = useCallback(
    debounce((name: string) => {
      dispatch(checkRoomName(name, false));
    }, 600),
    []
  );
  const handleRoomNameChange = (name: string) => {
    const error = Messages(
      validationMessagesKeys.validRoomName,
      name,
      true,
      []
    );
    if (!error) {
      debounceCheckRoomName(name);
      AppAnalytics.track("btn.clicked", {
        btnId: "room.rename.success",
        componentId: "create.your.room.step",
      });
    }
    setRoomNameError(error);
  };

  return (
    <Flex
      css={{ minWidth: "100%", p: "$10", display: "flex" }}
      direction="column"
    >
      {/* Room name */}
      <RoomName direction="row">
        <RoomNameLabel
          text="Room Name"
          subtitle="Configure the name of your room."
        >
          {({ text, subtitle }) => {
            return <DefaultRoomNameLabel text={text} subtitle={subtitle} />;
          }}
        </RoomNameLabel>
        <RoomNameInput
          placeholder="Upto 80 characters"
          defaultValue={roomName}
          css={{ minWidth: "100%", h: "40px" }}
          maxLength={80}
          inputCss={{ borderRadius: "$0" }}
          onChangeHandler={e => {
            const input = e.target.value;
            updateRoomName(input);
            handleRoomNameChange(input);
          }}
          error={roomNameError || checkRoomNameError}
          hideLabel={true}
          showClipboard={false}
          label=""
          value={roomName}
          passEvent={true}
        >
          <RoomNameInputBox value={roomName} label="" />
        </RoomNameInput>
      </RoomName>
      {/* Template */}
      <TwoColumnFlex css={{ mb: "$16" }}>
        <TextAndSubtext
          text="Select a template"
          subText="Select the template you want to create your room on."
        />
        <Select.Root
          className="mt-2 flex"
          css={{
            bg: "$surface_bright",
          }}
        >
          <Select.DefaultDownIcon />

          <Select.Select
            className="h-12 w-full"
            css={{
              bg: "$surface_bright",
              border: "solid $space$px $border_bright",
              cursor: "pointer",
              "&:focus-visible": {
                border: "none",
              },
            }}
            onChange={e => {
              const selectedIndex = e.target?.selectedIndex;
              dispatch(setTemplateIDinStore(all_templates[selectedIndex].id));
            }}
            defaultValue={all_templates?.[0]?.name}
            value={templates?.[templateId]?.name}
          >
            {all_templates.map((template: Record<string, unknown>) => {
              return (
                <option
                  value={template?.["name"] as string}
                  key={template?.["id"] as string}
                  selected={templateId === (template["id"] as string)}
                >
                  {template.name as string}
                </option>
              );
            })}
          </Select.Select>
        </Select.Root>
      </TwoColumnFlex>
      {!isHipaa && (
        <TwoColumnFlex css={{ mb: "$16" }}>
          <TextAndSubtext
            text="Support for Large Rooms"
            subText={
              <Text
                css={{ c: "$on_surface_low", mt: "$4", fontWeight: "$regular" }}
                variant="sm"
              >
                When enabled, this room can support upto 20,000 viewers.{" "}
                <Text as="span" variant="sm" css={{ color: "$primary_bright" }}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      cursor: "pointer",
                      fontSize: "inherit",
                    }}
                    href={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/how-to-guides/create-large-rooms`}
                  >
                    Learn more.
                  </a>
                </Text>
              </Text>
            }
          />
          <Flex css={{ gap: "$4" }} align="center">
            <Switch
              checked={largeRoom}
              onCheckedChange={() => {
                dispatch(setLargeRoomInStore(!largeRoom));
              }}
            />
            <Text variant="caption">{largeRoom ? "Enabled" : "Disabled"}</Text>
          </Flex>
        </TwoColumnFlex>
      )}
    </Flex>
  );
};
export default CreateYourRoom;
