import React, { useEffect } from "react";
import { AppAnalytics } from "src/helpers";
import { Flex, Text } from "@100mslive/roomkit-react";

const Tab = ({ value, isActiveTab, icon, onClick, disabled = false }) => (
  <Flex
    align="center"
    justify="center"
    onClick={onClick}
    css={{
      padding: "$5 $md",
      cursor: !disabled ? "pointer" : "not-allowed",
      minWidth: "max-content",
      w: "100%",
      backgroundColor: !disabled
        ? isActiveTab
          ? "$secondary_dim"
          : "$surface_dim"
        : isActiveTab
        ? "$secondary_disabled"
        : "$surface_dim",
    }}
  >
    <Text
      variant="sm"
      css={{
        fontWeight: "$semiBold",
        color: !disabled
          ? isActiveTab
            ? "$on_surface_high"
            : "$on_surface_medium"
          : "$on_surface_medium",
        display: "flex",
        alignItems: "center",
        gap: "4px",
      }}
    >
      {icon}
      {value}
    </Text>
  </Flex>
);

const TabGroup = ({
  useParams = true,
  tabs,
  setActiveTab,
  activeTab,
  btnId = "",
  componentId = "",
  css = {},
  disabled = false,
}) => {
  useEffect(() => {
    if (typeof window !== "undefined" && useParams) {
      const params = new URLSearchParams(window.location.search);
      const queryTab = params.get("tab");
      if (tabs.some(tab => tab.title === queryTab)) {
        setActiveTab(queryTab);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Flex
      css={{
        backgroundColor: "$surface_dim",
        border: "1px solid $border_default",
        borderRadius: "$0",
        w: "max-content",
        overflow: "hidden",
        h: "40px",
        ...css,
      }}
    >
      {tabs.map(tab => (
        <Tab
          key={tab.id}
          title={tab.title}
          icon={tab.icon}
          value={tab.name}
          setActiveTab={setActiveTab}
          isActiveTab={activeTab === tab.title}
          disabled={disabled}
          onClick={
            !disabled
              ? () => {
                  setActiveTab(tab.title);
                  if (useParams) {
                    const newUrl = new URL(window.location.href);
                    newUrl.searchParams.set("tab", tab.title);
                    window.history.replaceState(null, "", newUrl.toString());
                  }
                  AppAnalytics.track("btn.clicked", {
                    btnId: btnId,
                    componentId: componentId,
                    selected: tab.title,
                  });
                }
              : () => {}
          }
        />
      ))}
    </Flex>
  );
};

export default TabGroup;
