import React from "react";
import HipaaComplianceNotice from "src/components/HipaaComplianceNotice/HipaaComplianceNotice";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { currentWorkspaceHipaa } from "src/utils";
import { Flex, Switch, Text } from "@100mslive/roomkit-react";

export const TrackRecordingSubtitle = () => (
  <Text
    variant="body2"
    css={{ c: "$on_surface_low", fontWeight: "$regular", mt: "$4" }}
  >
    Captures individual audio or video tracks for each session peer, recording
    each track in its original resolution.&nbsp;
    <a
      href={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/features/recordings/recording-modes/track-recordings`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Text
        variant="body2"
        css={{ c: "$primary_bright", fontWeight: "$regular" }}
        as="span"
      >
        Learn more
      </Text>
    </a>
  </Text>
);

export const TrackRecording = ({
  isTrackRecordingEnabled,
  toggleTrackRecording,
}: {
  isTrackRecordingEnabled: boolean;
  toggleTrackRecording: (bool?: boolean) => void;
}) => {
  const isHipaa = currentWorkspaceHipaa();

  return (
    <Flex direction="column" css={{ minWidth: "100%" }}>
      <Flex
        direction="column"
        css={{
          minWidth: "100%",
        }}
      >
        <SettingsTitle
          key="Enable Track Recording"
          title="Enable track recordings for this template and rooms created after enabling the same."
          text="Enable Track Recording"
        />
        <Flex css={{ mt: "$4", position: "relative" }}>
          <Switch
            checked={isTrackRecordingEnabled}
            onCheckedChange={toggleTrackRecording}
            disabled={isHipaa}
          />
        </Flex>
        <HipaaComplianceNotice />
      </Flex>
    </Flex>
  );
};
