import React, { FC } from "react";
import { Button, Text } from "@100mslive/roomkit-react";

interface NameTileProps {
  first_name: string;
  last_name: string;
  unClickable?: boolean;
  onClick: () => void;
}

const NameTile: FC<NameTileProps> = ({
  first_name,
  last_name,
  onClick,
  unClickable = false,
}) => (
  <Button
    variant="standard"
    onClick={onClick}
    css={{
      border: "none !important",
      w: "$12",
      h: "$12",
      backgroundColor: "$surface_bright",
      borderRadius: "$0",

      pointerEvents: unClickable ? "none" : "all",
    }}
  >
    <Text
      variant="xs"
      css={{
        fontWeight: "$semiBold",
        color: "$on_surface_medium",
        display: "flex",
        textTransform: "uppercase",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {first_name[0]}
      {last_name ? last_name[0] : ""}
    </Text>
  </Button>
);

export default NameTile;
