import React, { FC, isValidElement, ReactElement } from "react";
import { Link } from "react-router-dom";
import { isFunction } from "lodash";
import { Flex, Text, Tooltip } from "@100mslive/roomkit-react";

interface OptionItemProps {
  link?: string;
  title?: string;
  icon?: ReactElement;
  comp?: ReactElement;
  className?: string;
  disabled?: boolean;
  tooltipText?: string;
  onClick?: () => void;
}

interface OptionContentProps {
  title?: string;
  icon?: ReactElement;
  comp?: ReactElement;
  tooltipText?: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const OptionItem: FC<OptionItemProps> = ({
  link = "",
  icon,
  title,
  comp,
  disabled = false,
  className,
  tooltipText = "",
  onClick,
}) =>
  link ? (
    <Link to={link} onClick={onClick} className="w-full">
      <OptionContent
        title={title}
        icon={icon}
        comp={comp}
        tooltipText={tooltipText}
        className={className}
      />
    </Link>
  ) : (
    <OptionContent
      disabled={disabled}
      title={title}
      icon={icon}
      comp={comp}
      tooltipText={tooltipText}
      onClick={onClick}
      className={className}
    />
  );

export default OptionItem;

const OptionContent: FC<OptionContentProps> = ({
  icon,
  title,
  comp,
  className,
  disabled,
  tooltipText,
  onClick,
}) => (
  <Tooltip
    disabled={!disabled || tooltipText === ""}
    title={tooltipText}
    side="bottom"
    align="center"
  >
    <Flex
      align="center"
      css={{
        width: "100%",
        p: "$4 $md",
        cursor: disabled ? "not-allowed" : "pointer",
        color: disabled ? "$on_surface_low" : "on_surface_high",
        "&:hover": { color: disabled ? "$on_surface_low" : "$on_surface_high" },
      }}
      className={className}
      onClick={() => {
        if (isFunction(onClick)) {
          onClick();
        }
      }}
    >
      {isValidElement(icon) ? (
        <>
          {icon}
          <Text
            variant="xs"
            css={{
              ml: "$2",
              p: "$2",
              color: disabled ? "$on_surface_low" : "on_surface_high",
              fontWeight: "$semiBold",
            }}
          >
            {title}
          </Text>
        </>
      ) : (
        comp
      )}
    </Flex>
  </Tooltip>
);
