import { useEffect, useState } from "react";
import { Box, Flex, Text } from "@100mslive/roomkit-react";
import alertIcon from "../../assets/icons/alertIcon.svg";

const InvoiceBanner = ({ invoices = [] }) => {
  const [overdueCount, setOverdueCount] = useState(0);
  const [outstandingCount, setOutstandingCount] = useState(0);

  useEffect(() => {
    for (let i = 0; i < invoices.length; i++) {
      if (invoices[i].status === "approved") {
        if (Date.now() > new Date(invoices[i].due_date)) {
          setOverdueCount(prev => prev + 1);
        } else if (overdueCount === 0) {
          setOutstandingCount(prev => prev + 1);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return overdueCount || outstandingCount ? (
    <Box
      css={{
        position: "relative",
        mb: "$10",
        backgroundColor: "$surface_bright",
        border: "1px solid $border_bright",
        borderRadius: "$1",
      }}
    >
      <Flex
        css={{
          h: "72px",
          py: "$2",
          px: "$10",
        }}
        align="center"
      >
        <img
          src={alertIcon}
          alt="Alert"
          style={{ height: "40px", width: "40px", marginRight: "24px" }}
        />
        <Text
          variant="sm"
          css={{ color: "$on_surface_high", fontWeight: "$semiBold" }}
        >
          You have{" "}
          {overdueCount
            ? `${overdueCount} overdue`
            : `${outstandingCount} outstanding`}{" "}
          invoice
          {overdueCount
            ? overdueCount > 1
              ? "s"
              : ""
            : outstandingCount > 1
            ? "s"
            : ""}
          :&nbsp;
        </Text>
        <Text variant="sm" css={{ color: "$on_surface_medium" }}>
          Please complete the payment to avoid account deactivation
        </Text>
      </Flex>
    </Box>
  ) : null;
};

export default InvoiceBanner;
