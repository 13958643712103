import React, { useEffect } from "react";
import { useMeasure } from "react-use";
import { InfoIcon } from "@100mslive/react-icons";
import {
  Box,
  Flex,
  PasswordInput,
  Text,
  Tooltip,
} from "@100mslive/roomkit-react";
import { AppAnalytics } from "../../helpers/analytics_helper";
import "../../styles/credentials.scss";

// eslint-disable-next-line complexity
const Credential = ({
  label,
  value,
  showClipboard = true,
  readOnly = false,
  getIsSecret = label => label && false,
  disabled = false,
  hideLabel = false,
  title = "",
  defaultValue = "",
  // eslint-disable-next-line
  onChangeHandler = e => {
    return;
  },
  onEnterKeyPress = () => {
    return;
  },
  rightComp = <></>,
  css = {},
  clickEventName = "",
  clickEventProps = {},
  error = "",
  placeholder = "",
  outlined = false,
  inputCss = {},
  labelCss = {},
  passEvent = false,
  success = false,
  leftIcon = null,
  minLength = 0,
  autoFocus = false,
  maxLength = 524288,
  tooltipMessage = "",
  tooltipLink = "",
  // Default maxLength for input
}) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const [text, setText] = React.useState("");
  const isSecret = getIsSecret(label);
  const [showPassword, setShowPassword] = React.useState(false);
  useEffect(() => {
    if (!text || text !== value) {
      setText(value);
    }
  }, [text, value]);
  const copyModal = () => {
    if (!isCopied) {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  };
  const [ref, { width }] = useMeasure();
  const errorCSS = error
    ? {
        boxShadow: "none !important",
        border: "2px solid $alert_error_default !important",
      }
    : {};
  return (
    <Flex css={{ width: "100%", ...css }}>
      {!hideLabel ? (
        <Flex
          css={{
            width: "280px",
            alignItems: "center",
            gap: "$2",
            marginBottom: "$xs",
          }}
        >
          <Text
            variant="body1"
            css={{ color: "$on_surface_high", ...labelCss }}
          >
            {label}
          </Text>
          <Flex css={{ alignItems: "center" }}>
            {tooltipMessage.length > 0 ? (
              <Tooltip
                title={
                  <Text
                    variant="sm"
                    css={{ color: "$on_surface_low", m: "$2" }}
                  >
                    {tooltipMessage}
                    {tooltipLink.length > 0 ? (
                      <a
                        className="text-primary-light hover:text-primary-default"
                        rel="noreferrer"
                        target="_blank"
                        href={tooltipLink}
                      >
                        &nbsp;Learn more.
                      </a>
                    ) : (
                      false
                    )}
                  </Text>
                }
                side="top"
                align="center"
                boxCss={{
                  width: "280px",
                  zIndex: 10,
                  marginBottom: "$xs",
                  alignItems: "center",
                  r: "$1",
                }}
              >
                <Flex>
                  <InfoIcon height={14} width={14} />
                </Flex>
              </Tooltip>
            ) : (
              false
            )}
          </Flex>
        </Flex>
      ) : (
        ""
      )}
      <Box className="w-full relative">
        <PasswordInput.Root>
          {leftIcon ? (
            <Flex className="px-1 absolute left-3 h-full items-center">
              {leftIcon}
            </Flex>
          ) : (
            ""
          )}

          <PasswordInput.Input
            autoFocus={autoFocus}
            error={error}
            defaultValue={defaultValue}
            placeholder={placeholder}
            maxLength={maxLength}
            minLength={minLength}
            onKeyDown={e => {
              if (e.key === "Enter") {
                onEnterKeyPress();
              }
            }}
            css={{
              pl: leftIcon ? 48 : 12,
              pr: width + 8,
              textOverflow: "ellipsis",
              "&:focus": !error && {
                boxShadow: "none",
                outline: success
                  ? "1px solid $alert_success"
                  : "1px solid $primary_bright",
              },
              border: !error && "1px solid $border_bright",
              backgroundColor: hideLabel
                ? outlined
                  ? "$transparent"
                  : "$surface_brighter"
                : outlined
                ? "$transparent"
                : "",
              ...inputCss,
              ...errorCSS,
            }}
            disabled={disabled}
            title={title || ""}
            showPassword={isSecret ? showPassword : true}
            onChange={e => {
              if (!readOnly && !disabled && onChangeHandler) {
                if (passEvent) {
                  onChangeHandler(e);
                } else {
                  onChangeHandler(e.target.value);
                }
              }
              setText(e.target.value);
            }}
            value={value}
            readOnly={readOnly}
          />
          <PasswordInput.Icons
            ref={ref}
            css={{
              gap: "$1",
              top: "$5",
              height: "auto",
              backgroundColor: "$transparent",
            }}
          >
            {isSecret ? (
              <PasswordInput.ShowIcon
                showPassword={showPassword}
                css={{
                  pl: "$2",
                  cursor: "pointer",
                  "&:hover": { c: "$on_surface_medium" },
                  backgroundColor: "$transparent",
                }}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            ) : null}
            {rightComp}
            {showClipboard ? (
              <PasswordInput.CopyIcon
                css={{
                  pl: "$2",
                  cursor: "pointer",
                  backgroundColor: "$transparent",
                  "&:hover": { c: "$on_surface_medium" },
                }}
                onClick={() => {
                  navigator.clipboard.writeText(text);
                  copyModal();

                  if (clickEventName) {
                    AppAnalytics.track(clickEventName, { ...clickEventProps });
                  } else {
                    AppAnalytics.track("credential.copied", {
                      credential_copied: label,
                    });
                  }
                }}
              />
            ) : null}
          </PasswordInput.Icons>
        </PasswordInput.Root>
        <Box className="relative">
          {isCopied ? (
            <Text
              variant="xs"
              css={{
                p: "$2 $4",
                w: "max-content",
                position: "absolute",
                top: "-$20",
                right: "0",
                bg: "$surface_brighter",
                c: "$on_surface_medium",
                zIndex: "20",
                borderRadius: "$0",
              }}
            >
              Copied
            </Text>
          ) : null}
        </Box>
      </Box>
    </Flex>
  );
};

export default Credential;
