import { useState } from "react";
import api from "src/api";
import toastr from "src/components/Common/toastr";
import { getAPIURL } from "src/utils";
import { DownloadIcon, InfoIcon } from "@100mslive/react-icons";
import {
  Box,
  Button,
  Flex,
  Loading,
  Text,
  Tooltip,
} from "@100mslive/roomkit-react";
import Options from "../../components/Common/Options";
import { AppAnalytics } from "../../helpers/analytics_helper";
import { openDevrevWidget } from "../../helpers/utils";

const InvoiceRow = ({ invoiceObject }) => {
  const [loading, setLoading] = useState(false);

  const date = new Date(invoiceObject.created_at);

  const createdOn = `${date.toLocaleString("default", {
    day: "2-digit",
  })} ${date.toLocaleString("default", {
    month: "long",
  })}, ${date.toLocaleString("default", { year: "numeric" })}`;

  const dueDate = new Date(invoiceObject.due_date);

  const dueOn = `${dueDate.toLocaleString("default", {
    day: "2-digit",
  })} ${dueDate.toLocaleString("default", {
    month: "long",
  })}, ${dueDate.toLocaleString("default", { year: "numeric" })}`;

  const handleInvoiceDownload = async (url, createdOn) => {
    if (url.includes("pay.stripe.com")) {
      window.open(url);
    } else {
      const queryParams = {
        invoice_url: url,
      };
      setLoading(true);
      try {
        const res = await api
          .service("dashboard", { responseType: "blob" })
          .get(getAPIURL("billings/invoices/pdf"), queryParams, {
            responseType: "blob",
          });
        const fileName = `Invoice ${createdOn}.pdf`;

        const href = URL.createObjectURL(new Blob([res.data]));

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${fileName}.pdf`);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        toastr.success("Invoice downloaded successfully");
      } catch (err) {
        toastr.error(err.message);
      }
      setLoading(false);
    }
  };

  const getStatus = {
    approved:
      Date.now() > dueDate
        ? { text: "Overdue", color: "$alert_error_default" }
        : { text: "Outstanding", color: "$primary_bright" },
    paid: { text: "Paid", color: "$alert_success" },
    partially_paid: { text: "Partially Paid", color: "$primary_bright" },
    void: { text: "Void", color: "$on_surface_medium" },
  };

  const status = getStatus[invoiceObject.status] || {
    text: invoiceObject.status,
    color: "$on_surface_medium",
  };

  return (
    <tr className="border-border-default">
      <td>
        <Text variant="sm" css={{ color: "$on_surface_medium" }}>
          {createdOn}
        </Text>
      </td>
      <td>
        <Text
          variant="sm"
          css={{ color: "$on_surface_high", fontWeight: "$semiBold" }}
        >
          {`$${invoiceObject.amount_due}`}
        </Text>
      </td>
      <td>
        <span className="py-1 px-2 relative flex items-center justify-center w-max">
          <Box
            css={{
              backgroundColor: status.color,
              borderRadius: "$0",
              opacity: "10%",
              height: "100%",
              width: "100%",
              top: "0",
              position: "absolute",
            }}
          />
          <Text
            variant="sm"
            css={{
              color: status.color,
              display: "inline-block",
              zIndex: "1",
              position: "relative",
              opacity: "100%",
            }}
          >
            {status.text}
          </Text>
        </span>
      </td>
      <td>
        <Text variant="sm" css={{ color: "$on_surface_medium" }}>
          {dueOn}
        </Text>
      </td>
      <td>
        <Flex align="center" gap="3" css={{ color: "$on_surface_high" }}>
          <Tooltip
            title={
              invoiceObject.invoice_url
                ? "Download invoice"
                : "Invoice unavailable"
            }
            side="top"
            align="center"
          >
            <>
              {loading ? (
                <Loading />
              ) : (
                <span
                  onClick={() => {
                    if (invoiceObject.invoice_url) {
                      AppAnalytics.track("btn.clicked", {
                        platform: "hms-dashboard",
                        btnId: "download.invoice",
                        componentId: "invoicesPage",
                      });
                    }
                    handleInvoiceDownload(invoiceObject.invoice_url, createdOn);
                  }}
                  style={{
                    cursor: "not-allowed",
                  }}
                >
                  <Flex
                    css={
                      invoiceObject.invoice_url
                        ? {
                            color: "$on_surface_medium",
                            "&:hover": { color: "$on_surface_high" },
                          }
                        : {
                            color: "$on_surface_low",
                          }
                    }
                    className="cursor-pointer"
                  >
                    <DownloadIcon
                      style={{
                        color: "inherit",
                        cursor: invoiceObject.invoice_url
                          ? "pointer"
                          : "not-allowed",
                      }}
                    />
                  </Flex>
                </span>
              )}
            </>
          </Tooltip>
          <Button
            css={{ padding: "$2 $10" }}
            className="py-1 px-6"
            variant={
              status.text === "Paid" || status.text === "Void"
                ? "standard"
                : "primary"
            }
            disabled={status.text === "Paid" || status.text === "Void"}
            onClick={() => {
              if (invoiceObject.payment_url) {
                AppAnalytics.track("btn.clicked", {
                  platform: "hms-dashboard",
                  btnId: "pay.bill",
                  componentId: "invoicesPage",
                });
                window.open(invoiceObject.payment_url);
              }
            }}
          >
            Pay
          </Button>
          <Options
            css={{
              w: "100%",
              textAlign: "center",
              position: "relative",
              left: "-$7",
              top: "$3",
              p: 0,
              border: "1px solid $border_default",
              backgroundColor: "$surface_default",
            }}
            content={[
              {
                data: (
                  <Text
                    variant="caption"
                    css={{
                      fontWeight: "$regular",
                      c: "$on_surface_medium",
                      p: "$4 $md",
                    }}
                  >
                    <InfoIcon className="mr-2" />
                    Report Issue
                  </Text>
                ),
                func: openDevrevWidget,
              },
            ]}
          />
        </Flex>
      </td>
    </tr>
  );
};

export default InvoiceRow;
