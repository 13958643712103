import React, { FC, ReactElement } from "react";
import { Link } from "react-router-dom";
import { AppAnalytics } from "src/helpers";
import { Box, CSS, Flex, Text } from "@100mslive/roomkit-react";
import CopyButton from "./CopyButton";

interface TitleValueProps {
  title: string;
  value: string;
  copyButton?: boolean;
  link?: string;
  btnId: string;
  ellipsis?: boolean;
  maxWidth?: string;
  componentId: string;
  bottomComponent?: ReactElement;
  textCss?: CSS;
}

// Add props for events

const TitleValue: FC<TitleValueProps> = ({
  title,
  value,
  copyButton = false,
  ellipsis = false,
  link = "",
  btnId = "",
  componentId = "",
  maxWidth = "100%",
  bottomComponent = <></>,
  textCss,
}) => {
  const valueCSS = ellipsis
    ? {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth,
      }
    : {};
  return (
    <Box>
      <Text
        variant="tiny"
        css={{
          color: "$on_surface_medium",
          fontWeight: "$semiBold",
          textTransform: "uppercase",
          mb: "$4",
          letterSpacing: "1.5px",
        }}
      >
        {title}
      </Text>
      {bottomComponent ? (
        <Flex align="center" gap="2">
          {link ? (
            <Link
              to={link}
              onClick={() => {
                AppAnalytics.track("btn.clicked", {
                  btnId: btnId,
                  componentId: componentId,
                });
              }}
            >
              <Text
                variant="sm"
                css={{
                  fontWeight: "$semiBold",
                  "&:hover": { color: "$primary_bright" },
                  ...textCss,
                }}
              >
                {value}
              </Text>
            </Link>
          ) : (
            <Text
              variant="sm"
              css={{
                fontWeight: "700",
                color: "$on_surface_high",
                ...valueCSS,
                ...textCss,
              }}
            >
              {value}
            </Text>
          )}
          <Box>
            {copyButton ? (
              <CopyButton
                value={value}
                btnId={btnId}
                componentId={componentId}
              />
            ) : null}
          </Box>
        </Flex>
      ) : (
        bottomComponent
      )}
    </Box>
  );
};

export default TitleValue;
