import React from "react";
import { Flex, Text } from "@100mslive/roomkit-react";

const labelConstants = {
  enabled: "$alert_success",
  disabled: "$alert_error_default",
  "large room": "$primary_default",
};

const RoomStateLabel = ({ labelText, css = {} }) => (
  <Flex
    align="center"
    justify="center"
    css={{
      borderRadius: "$0",
      position: "relative",
      w: "max-content",
      ...css,
    }}
  >
    <Flex
      css={{
        opacity: "0.1",
        position: "absolute",
        backgroundColor: labelConstants[labelText.toLowerCase()],
        borderRadius: "$0",
        h: "100%",
        w: "100%",
      }}
    />
    <Flex
      gap="1"
      align="center"
      css={{ p: "$xs", color: labelConstants[labelText.toLowerCase()] }}
    >
      <Text variant="xs" css={{ color: "inherit", fontWeight: "$semiBold" }}>
        {labelText}
      </Text>
    </Flex>
  </Flex>
);

export default RoomStateLabel;
