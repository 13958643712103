import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AppAnalytics, openDevrevWidget } from "src/helpers";
import {
  BookIcon,
  BuildingIcon,
  ChatIcon,
  ChatUnreadIcon,
  ExitIcon,
  LayersIcon,
  PersonContactIcon,
  Svg100MsLogoIcon,
} from "@100mslive/react-icons";
import { Box, Dropdown, Flex, Text } from "@100mslive/roomkit-react";
import logo from "../../assets/images/logo-blue-dark.svg";
import { toggleRightSidebar } from "../../store/actions";
import { currentUser, getCurrentWorkspaceID } from "../../utils";
import NameTile from "../Common/NameTile";
import DropdownItemContent from "../Nav/DropdownItemContent";
import HeaderItem from "../Nav/HeaderItem";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      isAdmin: false,
      isOrgAdmin: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.workspace && props.workspace.is_admin !== state.isAdmin) {
      return { isAdmin: props.workspace.is_admin };
    }

    const user = currentUser();
    const member = props?.currentWorkspace?.members?.find(member => {
      return member.id === user.user_id;
    });
    if (member && member?.isOrgAdmin !== state?.isOrgAdmin) {
      return { isOrgAdmin: member.isOrgAdmin };
    }
    return null;
  }

  iconStyle = {
    height: "20px",
    width: "20px",
    color: "inherit",
    marginRight: "8px",
  };

  dropdownItemCSS = {
    p: "0",
    bg: "$surface_default",
    "&:hover": { backgroundColor: "$surface_brighter" },
  };

  // eslint-disable-next-line complexity
  render() {
    const { first_name } = currentUser();
    const { email = "", last_name = "" } = currentUser();
    const headerItemList = [
      {
        text: "Docs",
        link: `${process.env.REACT_APP_WEBSITE_URL}docs`,
        icon: <BookIcon style={this.iconStyle} />,
        btnId: "docs.header",
      },
      {
        text: "Talk to Sales",
        link: `${process.env.REACT_APP_WEBSITE_URL}contact`,
        icon: <PersonContactIcon style={this.iconStyle} />,
        btnId: "talk.to.sales",
      },
      {
        text: "Support",
        link: "",
        onClick: this.props.isLoading
          ? () => {}
          : () => {
              openDevrevWidget(this.props.toggleState);
            },
        icon: !this.props.hasMessage ? (
          <ChatIcon style={this.iconStyle} />
        ) : (
          <ChatUnreadIcon style={this.iconStyle} />
        ),
        btnId: "support.header",
        showLoader: this.props.isLoading,
      },
    ];
    return (
      <header id="page-topbar">
        <Flex
          justify={this.props.isFullScreen ? "between" : "end"}
          css={{
            alignItems: "center",
            justifyContent: "flex-end",
            w: "100%",
            backgroundColor: this.props.isFullScreen
              ? "$transparent"
              : "$background_default",
          }}
        >
          {this.props.isFullScreen ? (
            <Flex
              align="center"
              css={{
                borderBottom: "1px solid $border_default",
                w: "100%",
                padding: this.state.collapsed ? "16.85px" : "16.85px $md",
                overflow: "clip",
              }}
            >
              <Link to="/">
                {this.state.collapsed ? (
                  <img
                    alt="100ms Logo"
                    src={logo}
                    style={{ height: "21px", width: "32px" }}
                  />
                ) : (
                  <Svg100MsLogoIcon height={20} width={75} />
                )}
              </Link>
            </Flex>
          ) : null}
          <Flex
            css={{
              alignItems: "center",
              justifyContent: "flex-end",
              borderBottom: "1px solid $border_default",
              w: "100%",
              backgroundColor: "inherit",
              p: "$sm $14",
              gap: "$md",
            }}
          >
            {headerItemList.map(headerItem => {
              return <HeaderItem key={headerItem.text} {...headerItem} />;
            })}
            <Dropdown.Root>
              <Dropdown.Trigger
                onClick={() =>
                  AppAnalytics.track("btn.clicked", {
                    componentId: "header",
                    btnId: "profile.header",
                  })
                }
                css={{ borderRadius: "$0" }}
              >
                <NameTile
                  first_name={first_name ? first_name : "User"}
                  last_name={last_name}
                />
              </Dropdown.Trigger>
              <Dropdown.Content
                align="end"
                side="bottom"
                sideOffset={8}
                css={{
                  border: "1px solid $border_default",
                  borderRadius: "$0",
                  background: "$surface_default",
                  p: "$xs",
                  overflowY: "visible",
                  width: "max-content",
                }}
              >
                <Flex align="center" css={{ gap: "$6", marginBottom: "$4" }}>
                  <NameTile
                    first_name={first_name ? first_name : "User"}
                    last_name={last_name}
                    unClickable
                  />
                  <Box>
                    <Text
                      variant="sm"
                      css={{
                        fontWeight: "$semiBold",
                        color: "$on_surface_high",
                        maxWidth: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {`${first_name ? first_name : "User"} ${last_name}`}
                    </Text>
                    <Text variant="xs" css={{ color: "$on_surface_low" }}>
                      {email}
                    </Text>
                  </Box>
                </Flex>
                {this.state.isAdmin && (
                  <>
                    <Dropdown.ItemSeparator
                      css={{ mx: "0", backgroundColor: "$border_default" }}
                    />
                    <Dropdown.Item
                      onClick={() =>
                        AppAnalytics.track("btn.clicked", {
                          btnId: "workspace.settings",
                          componentId: "header",
                        })
                      }
                      css={this.dropdownItemCSS}
                    >
                      <DropdownItemContent
                        link="/settings/workspace"
                        text="Workspace Settings"
                        icon={<LayersIcon />}
                      />
                    </Dropdown.Item>

                    {this.state.isOrgAdmin ? (
                      <Dropdown.Item
                        onClick={() =>
                          AppAnalytics.track("btn.clicked", {
                            btnId: "organization.settings",
                            componentId: "header",
                          })
                        }
                        css={this.dropdownItemCSS}
                      >
                        <DropdownItemContent
                          link="/settings/organization"
                          text="Organization Settings"
                          icon={<BuildingIcon />}
                        />
                      </Dropdown.Item>
                    ) : null}
                  </>
                )}
                <Dropdown.ItemSeparator
                  css={{ mx: "0", backgroundColor: "$border_default" }}
                />
                <Dropdown.Item
                  onClick={() =>
                    AppAnalytics.track("btn.clicked", {
                      btnId: "log.out",
                      componentId: "header",
                    })
                  }
                  css={this.dropdownItemCSS}
                >
                  <DropdownItemContent
                    link="/logout"
                    text="Log out"
                    icon={<ExitIcon />}
                  />
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown.Root>
          </Flex>
        </Flex>
      </header>
    );
  }
}

const mapStatetoProps = state => {
  const workspaceID = getCurrentWorkspaceID();
  const { layoutType } = state.Layout;
  const { workspaces, status } = state.workspace;

  return {
    layoutType,
    workspace: workspaces[workspaceID],
    currentWorkspace: state.workspace.current,
    fetchAllWorkspacesStatus: status.fetchAllWorkspaces,
    toggleState: state.devRevSupportState.toggleState,
    isLoading: state.devRevSupportState.isLoading,
    hasMessage: state.devRevSupportState.hasMessage,
  };
};

export default connect(mapStatetoProps, {
  toggleRightSidebar,
})(withNamespaces()(Header));
