import React, {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { isEmpty } from "lodash";
import {
  fetchPolicyInfo,
  setHasUnsavedSettings,
  updatePolicyAndAppLayout,
  updatePolicyInfo,
} from "src/actions/RolesActions";
import SectionHeader from "src/components/Common/SectionHeader";
import { SideList, SideListItem } from "src/components/Common/SideList";
import TemplateLayout from "src/components/layout/templateLayout";
import { API_CALL_STATE } from "src/constants";
import useActiveLinks from "src/hooks/useActiveLink";
import { RootState } from "src/store/reducers";
import { RoomStateType } from "src/types/policyTypes";
import { slugify } from "src/utils";
import { validateRoomState } from "src/validations/advancedSettings";
import { Flex } from "@100mslive/roomkit-react";
import GeneralAdvancedSettings from "./GeneralAdvancedSettings";
import NoiseCancellation from "./NoiseCancellation";
import Whiteboard from "./Whiteboard";
import { roleType } from "../../types/policyTypes";

interface Sections {
  [key: string]: {
    title: string;
    children: string[];
  };
}

const sections: Sections = {
  Whiteboard: {
    title: "Whiteboard",
    children: [],
  },
  "Noise Cancellation": {
    title: "Noise Cancellation",
    children: [],
  },
  "Preview Room State": {
    title: "Preview Room State",
    children: [],
  },
};

const AdvancedSettingsConfig: FC<
  ComponentProps<typeof Flex> & { policyId: string }
> = ({ policyId }) => {
  const [invalidFields, setInvalidFields] = useState<Record<string, unknown>>(
    {}
  );
  const invalidFieldsTemplate = useSelector(
    (state: RootState) => state.roles.invalidFields
  );
  const activeSection = useActiveLinks("config-subheading");
  const dispatch = useDispatch();
  const roles = useSelector((state: RootState) => state.roles.roles);

  const updatePolicyStatus = useSelector(
    (state: RootState) => state.roles.updatePolicyStatus
  );
  const hasUnsavedAdvancedSettings = useSelector(
    (state: RootState) => state.roles.hasUnsavedAdvancedSettings
  );
  const policyInfo = useSelector((state: RootState) => state.roles.policyInfo);

  const roleLayouts = useSelector(
    (state: RootState) => state.appLayouts.roleLayouts
  );
  const roomState = useSelector(
    (state: RootState) => state.roles.policyInfo.settings.roomState
  );
  const validateRoomStateSettings = useCallback(
    (
      roomStateInfo: RoomStateType | undefined,
      roles: roleType[]
    ): { isValid: boolean; invalidFields: Record<string, unknown> } => {
      let isValid = true;
      const validation = validateRoomState(roomStateInfo, roles);
      setInvalidFields({ ...validation.invalidFields });
      isValid = Boolean(validation.isValid);
      return { isValid, invalidFields: validation.invalidFields };
    },
    []
  );

  useEffect(() => {
    if (!policyId) {
      return;
    }
    dispatch(fetchPolicyInfo(policyId));
    dispatch(setHasUnsavedSettings(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyId]);

  useEffect(() => {
    if (updatePolicyStatus === API_CALL_STATE.DONE) {
      dispatch(fetchPolicyInfo(policyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePolicyStatus]);

  useEffect(() => {
    return () => {
      dispatch(fetchPolicyInfo(policyId));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveHandler = useCallback(() => {
    const roomStateInfo = roomState;
    const validationResult = validateRoomState(roomStateInfo, roles);
    if (validationResult.isValid) {
      dispatch(setHasUnsavedSettings(false));
      if (!isEmpty(roleLayouts)) {
        dispatch(
          updatePolicyAndAppLayout({
            policy: {
              ...policyInfo,
              settings: {
                ...policyInfo.settings,
                roomState: roomStateInfo
                  ? {
                      ...roomStateInfo,
                    }
                  : undefined,
              },
            },
            roleLayouts: roleLayouts,
          })
        );
      } else {
        dispatch(
          updatePolicyInfo(policyId, {
            ...policyInfo,
            settings: {
              ...policyInfo.settings,
              roomState: roomStateInfo
                ? {
                    ...roomStateInfo,
                  }
                : undefined,
            },
          })
        );
      }
    }
    setInvalidFields(validationResult.invalidFields);
  }, [dispatch, policyId, policyInfo, roles, roomState, roleLayouts]);

  return (
    <TemplateLayout
      unsaved={hasUnsavedAdvancedSettings}
      onSaveClick={saveHandler}
    >
      <Flex
        direction="column"
        css={{
          minWidth: "210px",
          position: "sticky",
          overflow: "clip auto",
          top: "$20",
          h: "100%",
        }}
      >
        {Object.keys(sections).map((section: string) => {
          const url = slugify(sections[section].title);
          return (
            <HashLink
              to={`?tab=advanced#${url}`}
              style={{ width: "100%" }}
              key={sections[section].title}
              onClick={e => e.stopPropagation()}
            >
              <SideList
                showChevron={sections[section].children.length > 0}
                title={sections[section].title}
                subtitle=""
                active={
                  activeSection === url ||
                  sections[section]?.children.some(
                    child => slugify(child) === activeSection
                  )
                }
              >
                {sections[section]?.children.map(child => {
                  const childUrl = slugify(child);
                  return (
                    <HashLink
                      to={`?tab=advanced#${childUrl}`}
                      style={{ width: "100%" }}
                      key={child}
                      onClick={e => e.stopPropagation()}
                    >
                      <SideListItem
                        title={child}
                        active={activeSection === childUrl}
                        onClick={() => null}
                      />
                    </HashLink>
                  );
                })}
              </SideList>
            </HashLink>
          );
        })}
      </Flex>

      <Flex
        direction="column"
        css={{ rowGap: "$12", w: "100%", left: "240px", flexGrow: 1 }}
      >
        <SectionHeader title="Advanced Settings" buttonText="Read Guide" />
        <Whiteboard invalidFields={invalidFieldsTemplate.plugins} />
        <NoiseCancellation />
        <GeneralAdvancedSettings
          validateRoomStateSettings={validateRoomStateSettings}
          invalidFields={invalidFields}
        />
      </Flex>
    </TemplateLayout>
  );
};

export default AdvancedSettingsConfig;
