import React, { ComponentProps, FC, ReactNode } from "react";
import TemplateConfigCard from "src/components/Cards/TemplateConfigCard";
import HipaaComplianceNotice from "src/components/HipaaComplianceNotice/HipaaComplianceNotice";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { TranscriptionType } from "src/types/policyTypes";
import { currentWorkspaceHipaa, slugify } from "src/utils";
import { Flex, Switch, Text } from "@100mslive/roomkit-react";

interface LiveTranscriptionHLSProps {
  transcription: TranscriptionType;
  setTranscription: (key: string, value: ReactNode) => void;
  toggleTranscription: (bool: boolean, mode: string) => void;
  isTranscriptionEnabled: boolean;
}

const LiveTranscriptionHLS: FC<
  ComponentProps<typeof Flex> & LiveTranscriptionHLSProps
> = ({ toggleTranscription, isTranscriptionEnabled }) => {
  const cardTitle = "Live Transcription (HLS)";

  const isHipaa = currentWorkspaceHipaa();

  const LeftComponent = () => (
    <Flex
      css={{
        maxHeight: "5000px",
        transition: "max-height ease 0.3s",
        overflow: "hidden",
      }}
    >
      <Text
        variant="body2"
        css={{ c: "$on_surface_low", fontWeight: "$regular" }}
      >
        This enables live transcription only for your live streams (HLS).
        <a
          href={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/how-to-guides/enable-transcription-and-summary`}
          rel="noreferrer noopener"
          target="_blank"
        >
          <Text
            variant="body2"
            css={{ c: "$primary_bright", fontWeight: "$regular" }}
            as="span"
          >
            &nbsp;Learn more
          </Text>
        </a>
      </Text>
    </Flex>
  );

  const rightComponent = (
    <Flex direction="column" css={{ minWidth: "100%" }}>
      <Flex
        direction="column"
        css={{
          py: "$10",
          minWidth: "100%",
        }}
      >
        <SettingsTitle
          key="Transcribe Live Streams"
          title="Generate captions for your live streams through HLS. RTMP-Out streams are not supported."
          text="Transcribe Live Streams"
        />
        <Switch
          disabled={isHipaa}
          checked={isTranscriptionEnabled}
          onCheckedChange={e => {
            toggleTranscription(e, "live");
          }}
          css={{ mt: "$4" }}
        />

        <HipaaComplianceNotice />
      </Flex>
    </Flex>
  );

  return (
    <TemplateConfigCard
      text={cardTitle}
      id={slugify(cardTitle)}
      classNameForText="config-subheading"
      subtitle=""
      leftComponent={<LeftComponent />}
      rightComponent={rightComponent}
    />
  );
};

export default LiveTranscriptionHLS;
