import React, { ReactNode } from "react";
import TemplateConfigCard from "src/components/Cards/TemplateConfigCard";
import TemplateMultiConfigCard from "src/components/Cards/TemplateMultiConfigCard";
import Line from "src/components/Common/Line";
import { policyTemplateType, RecordingConfigType } from "src/types/policyTypes";
import { recordingsConfigInvalidFieldsType } from "src/types/validations/destinations";
import { currentWorkspaceHipaa, slugify } from "src/utils";
import { Box, Flex, Text } from "@100mslive/roomkit-react";
import CommonRecordingConfiguration from "./CommonRecordingConfiguration";
import {
  CompositeRecording,
  CompositeRecordingSubtitle,
} from "./CompositeRecording";
import { StreamRecording, StreamRecordingSubtitle } from "./StreamRecording";
import { TrackRecording, TrackRecordingSubtitle } from "./TrackRecording";

// eslint-disable-next-line complexity
function CompositeRecordingConfig({
  recordingsConfig,
  policyInfo,
  setRecordingInfo,
  setRecordingsConfig,
  toggleTrackRecording,
  toggleStreamRecording,
  toggleBrowserComposite,
  subscribeToClicked,
  invalidFields,
  isHLSorRTMPEnabled,
  isValidInternalRecorderState,
}: {
  policyInfo: policyTemplateType;
  setRecordingInfo: (key: string, value: ReactNode) => void;
  subscribeToClicked: (str: string) => void;
  toggleTrackRecording: (bool?: boolean) => void;
  toggleStreamRecording: (bool?: boolean) => void;
  toggleBrowserComposite: (bool?: boolean) => void;
  recordingsConfig: RecordingConfigType;
  setRecordingsConfig: (key: string, value: ReactNode) => void;
  invalidFields?: { recordings: recordingsConfigInvalidFieldsType };
  isHLSorRTMPEnabled: boolean;
  isValidInternalRecorderState: boolean;
}) {
  const isHipaa = currentWorkspaceHipaa();
  const trackRecording = recordingsConfig?.trackRecording || {};
  const isTrackRecordingEnabled = trackRecording?.enabled || false;
  const streamRecording = recordingsConfig?.streamRecording || {};
  const isStreamRecordingEnabled = streamRecording?.enabled || false;
  const browserComposite =
    recordingsConfig?.compositeRecording?.browserComposite || {};
  const customComposite =
    recordingsConfig?.compositeRecording?.customComposite || {};
  const isBrowserCompositeEnabled = browserComposite?.enabled || false;
  const isCustomCompositeEnabled = customComposite?.enabled || false;
  const isCompositeRecordingEnabled =
    isBrowserCompositeEnabled || isCustomCompositeEnabled;

  const Separator = () => (
    <Box css={{ py: "$4" }}>
      <Line />
    </Box>
  );

  const templateConfigs = [
    {
      id: slugify("Recording Modes"),
      text: "Track Recording",
      subtitle: <TrackRecordingSubtitle />,
      classNameForText: "config-subheading",
      rightComponent: (
        <TrackRecording
          isTrackRecordingEnabled={isTrackRecordingEnabled}
          toggleTrackRecording={toggleTrackRecording}
        />
      ),
      bottomButton: <Separator />,
    },
    {
      id: slugify("Stream Recording"),
      text: "Stream Recording",
      subtitle: <StreamRecordingSubtitle />,
      classNameForText: "config-subheading",
      rightComponent: (
        <StreamRecording
          streamRecording={streamRecording}
          setRecordingsConfig={setRecordingsConfig}
          toggleStreamRecording={toggleStreamRecording}
        />
      ),
      bottomButton: <Separator />,
    },
    {
      id: slugify("Composite Recording"),
      text: "Composite Recording",
      subtitle: <CompositeRecordingSubtitle />,
      classNameForText: "config-subheading",
      rightComponent: (
        <CompositeRecording
          setRecordingInfo={setRecordingInfo}
          policyInfo={policyInfo}
          recordingsConfig={recordingsConfig}
          setRecordingsConfig={setRecordingsConfig}
          toggleBrowserComposite={toggleBrowserComposite}
          isHLSorRTMPEnabled={isHLSorRTMPEnabled}
          subscribeToClicked={subscribeToClicked}
          isValidInternalRecorderState={isValidInternalRecorderState}
        />
      ),
    },
  ];

  return (
    <>
      <TemplateMultiConfigCard templateConfigs={templateConfigs} />
      {isTrackRecordingEnabled ||
      isStreamRecordingEnabled ||
      isBrowserCompositeEnabled ||
      isCustomCompositeEnabled ? (
        <TemplateConfigCard
          text="Recording Configuration"
          id={slugify("Recording Configuration")}
          classNameForText="config-subheading"
          leftComponent={
            isHipaa && isCompositeRecordingEnabled ? (
              <Flex
                direction="column"
                css={{
                  r: "$0",
                  p: "$8 $6",
                  bg: "$surface_bright",
                  border: "$space$px solid $border_bright",
                }}
              >
                <>
                  <Text
                    variant="body2"
                    css={{ c: "$on_surface_medium", mb: "$4" }}
                  >
                    Disclaimer
                  </Text>
                  <Text
                    variant="body2"
                    css={{ c: "$on_surface_low", fontWeight: "$regular" }}
                  >
                    Validity of Download URLs is not valid for HIPAA workspaces
                    since 100ms doesn't have read access to your storage.&nbsp;
                  </Text>
                </>
              </Flex>
            ) : null
          }
          rightComponent={
            <CommonRecordingConfiguration
              recordingsConfig={recordingsConfig}
              setRecordingsConfig={setRecordingsConfig}
              invalidFields={invalidFields}
            />
          }
        />
      ) : null}
    </>
  );
}

export default CompositeRecordingConfig;
