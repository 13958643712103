import React from "react";
import { CSS, Flex } from "@100mslive/roomkit-react";

interface Props {
  css?: CSS;
}

const Line = ({ css }: Props) => (
  <Flex
    direction="column"
    css={{
      my: "$10",
      w: "100%",
      borderBottom: "$space$px solid $border_default",
      ...css,
    }}
  />
);

export default Line;
