import React, { useState } from "react";
import { Theme_ThemeType } from "@100mslive/types-prebuilt";
import { get } from "lodash";
import api from "src/api";
import Credential from "src/components/Common/Credential";
import toastr from "src/components/Common/toastr";
import { defaultDarkColorPalette } from "src/data/colorPalette";
import PrebuiltSidebarItemWrapper from "src/pages/Prebuilt/PrebuiltSidebarItemWrapper";
import { policyType } from "src/types/policyTypes";
import { ColorPalette } from "src/types/prebuilt";
import { CrossIcon, ExitIcon } from "@100mslive/react-icons";
import { Button, Flex, Loading, Text } from "@100mslive/roomkit-react";
import { MAX_FILE_SIZE_IN_KB } from "./helpers/constants";
import { analyseColorPalette } from "./helpers/utils";
import PrebuiltConfigAccordion from "./PrebuiltConfigAccordion";
import PrebuiltCustomisationMenu from "./PrebuiltCustomisationMenu";
import ColorPickerWithPalette from "../colorpicker/colorPickerWithPalette";
import TabGroup from "../Common/TabGroup";

const tabs = [
  {
    name: "Dark Theme",
    title: Theme_ThemeType.THEME_TYPE_DARK,
    id: 1,
  },
  { title: Theme_ThemeType.THEME_TYPE_LIGHT, id: 0, name: "Light Theme" },
];

const colorPaletteTooltipText: Record<string, string> = {
  primary:
    "Colors for UI elements that reflect the brand for main elements like primary buttons",
  secondary:
    "Colors for UI elements that reflect the brand but with lower emphasis like secondary buttons, offering contrast to primary elements",
  surface:
    "Colors for UI elements positioned above the background, like modals",
  background:
    "Colors for the base layer situated behind all content and UI elements",
  on_primary: "Colors for text and icons appearing on top of primary colors",
  on_secondary:
    "Colors for text and icons appearing on top of secondary colors",
  on_surface: "Colors for text and icons appearing on top of surface colors",
  alert_error: "Colors used to signify danger, errors in UI elements",
  warning: "Colors used to signify warnings in UI elements",
  success: "Colors used to signify success in UI elements",
  border: "Colors specifically used for borders on UI elements",
};

function Appearance({
  setActiveTab,
  logo,
  setLogo,
  colorPalette,
  setColorPalette,
  title,
  themeType = Theme_ThemeType.THEME_TYPE_DARK,
  setThemeType,
  subtitle,
  invalidFields,
  ...props
}: {
  title: string;
  subtitle: string;
  font: string;
  invalidFields: {};
  logo: string;
  colorPalette: typeof defaultDarkColorPalette;
  policyInfo: policyType;
  fetchPolicyInfoStatus: string;
  all_templates: policyType[];
  setActiveTab: (key: string) => void;
  setFont: (font: string) => void;
  setColorPalette: (colorPalette: ColorPalette) => void;
  setLogo: (logoUrl: string) => void;
  themeType: Theme_ThemeType;
  setThemeType: (type: Theme_ThemeType) => void;
}) {
  const invalidLink = get(invalidFields, "logo.url");
  const credentialsCSS = {
    css: {
      flexDirection: "column",
      maxWidth: "400px",
      mr: "$px",
    },
    inputCss: {
      border: "1px solid $border_default",
      bg: "$surface_default",
      r: "$0",
      mr: "$px",
      maxHeight: "$14",
    },
  };
  const [loading, setLoading] = useState(false);

  const handleImageChange = async () => {
    const input = document.createElement("input") as HTMLInputElement;
    input.type = "file";
    input.accept = "image/png, image/jpeg";
    async function inputChange(this: GlobalEventHandlers, ev: Event) {
      const file = (ev.target as HTMLInputElement)?.files?.[0];
      if (file) {
        try {
          const fileSizeKiloBytes = file.size / 1024;
          if (fileSizeKiloBytes > MAX_FILE_SIZE_IN_KB) {
            toastr.error(
              `Backgrounds should not be more than ${
                MAX_FILE_SIZE_IN_KB / 1024
              }MB in size. Reduce the size and try again.`
            );
            return;
          }

          const formData = new FormData();

          formData.append("image", file);
          setLoading(true);
          const res = await api
            .service("dashboard", {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .post("layouts/upload-image", formData);
          if (res.data.success) {
            toastr.success(res?.data?.msg);
            setLogo(res.data?.url);
          } else {
            throw Error(res?.data?.msg);
          }
        } catch (e: any) {
          const message = e.response?.data?.msg || e.message;
          console.error(message);
          toastr.error(message);
        } finally {
          setLoading(false);
        }
      }
    }
    input.onchange = inputChange;
    input.click();
  };

  return (
    <PrebuiltCustomisationMenu
      title={title}
      subtitle={subtitle}
      {...props}
      onBack={() => setActiveTab("default")}
    >
      <PrebuiltConfigAccordion
        title="Logo"
        tooltipText="The supported format is png. For best results, upload an image with a transparent background"
        hasSwitch={false}
      >
        <Flex>
          {logo ? (
            <img
              alt=""
              src={logo}
              style={{
                maxHeight: "40px",
                maxWidth: "40px",
                minHeight: "40px",
                minWidth: "40px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                objectFit: "cover",
              }}
            />
          ) : (
            <></>
          )}
          {logo ? (
            <Credential
              // autoFocus={!media?.url?.length}
              label=""
              {...credentialsCSS}
              readOnly={true}
              showClipboard={false}
              placeholder=""
              // onChangeHandler={val => {
              //   setMedia(index, val, !!media?.default);
              //   setinvalidErrorMessage("");
              // }}
              hideLabel
              value={logo}
              error={invalidLink || ""}
              rightComp={
                <Flex
                  css={{ cursor: "pointer important!" }}
                  onClick={() => setLogo("")}
                >
                  {loading ? (
                    <Loading width={20} height={20} />
                  ) : (
                    <CrossIcon
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  )}
                </Flex>
              }
            />
          ) : (
            <Button
              onClick={() => handleImageChange()}
              disabled={false}
              variant="standard"
              css={{
                width: "stretch",
                borderRadius: "$0",
                fontSize: "$sm",
                right: "0",
                p: "$3 $4",
                cursor: "pointer",
              }}
            >
              {loading ? (
                <Loading width={20} height={20} />
              ) : (
                <ExitIcon
                  style={{ transform: "rotate(270deg)" }}
                  width={20}
                  height={20}
                />
              )}
              <Text variant="sub2" css={{ ml: "$4" }}>
                Upload logo
              </Text>
            </Button>
          )}
        </Flex>

        {/* <LogoLink
          logoLink={logo}
          setLogoLink={val => {
            setLogo(val);
          }}
          invalidFields={invalidFields}
        /> */}
      </PrebuiltConfigAccordion>
      <PrebuiltConfigAccordion title="Colours" hasSwitch={false}>
        <>
          <PrebuiltSidebarItemWrapper
            title="Theme Type"
            hideTooltip
            css={{
              pt: "$8",
            }}
          >
            <Flex direction="column">
              <TabGroup
                tabs={tabs}
                setActiveTab={setThemeType}
                activeTab={themeType}
                css={{ w: "100%" }}
                useParams={false}
              />
              <Text
                variant="caption"
                css={{ c: "$on_surface_low", fontWeight: "$regular", mt: "$4" }}
              >
                Note: If you change the theme type, your colors will be reset
              </Text>
            </Flex>
          </PrebuiltSidebarItemWrapper>
          {colorPalette?.length > 0 &&
            colorPalette.map(color => {
              return (
                <PrebuiltSidebarItemWrapper
                  title={color.name}
                  key={color.key}
                  css={{
                    pt: "$8",
                  }}
                  tooltipText={colorPaletteTooltipText[color.key]}
                >
                  <ColorPickerWithPalette
                    color={color.value}
                    setColor={(updatedColor: string) => {
                      const updatedColorPalette = [...colorPalette];
                      const index = updatedColorPalette.findIndex(
                        item => item.key === color.key
                      );
                      updatedColorPalette[index].value = updatedColor;
                      // @ts-ignore
                      setColorPalette(updatedColorPalette);
                    }}
                    paletteColors={analyseColorPalette(color).colorPalette}
                  />
                </PrebuiltSidebarItemWrapper>
              );
            })}
        </>
      </PrebuiltConfigAccordion>
    </PrebuiltCustomisationMenu>
  );
}

export default Appearance;
