import { AlertTriangleIcon } from "@100mslive/react-icons";
import { Flex, Text } from "@100mslive/roomkit-react";
import SmallIcon from "./Icons/SmallIcon";

const FilterError = ({ errorString }) => {
  if (errorString === "") {
    return "";
  }
  return (
    <Flex
      align="center"
      css={{ gap: "$2", color: "$on_surface_medium", mt: "$4" }}
    >
      <SmallIcon>
        <AlertTriangleIcon />
      </SmallIcon>
      <Text variant="xs" css={{ color: "$on_surface_medium" }}>
        {errorString}
      </Text>
    </Flex>
  );
};

export default FilterError;
