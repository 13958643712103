import React, { ChangeEvent } from "react";
import { ConferencingHeader } from "@100mslive/types-prebuilt/elements/conferencing_header";
import StatusString from "src/components/Common/StatusString";
import TextArea from "src/components/Common/TextArea";
import { defaultLayout } from "src/data/defaultLayout";
import PrebuiltSidebarItemWrapper from "src/pages/Prebuilt/PrebuiltSidebarItemWrapper";
import { Input } from "@100mslive/roomkit-react";

type Props = {
  data: ConferencingHeader;
  setData: (data: ConferencingHeader) => void;
  invalidFields: { [key in keyof ConferencingHeader]?: string } | undefined;
};

function PrebuiltConferencingHeaderConfig({
  data,
  setData,
  invalidFields,
}: Props) {
  return (
    <>
      <PrebuiltSidebarItemWrapper title="Title" hideTooltip>
        <Input
          css={{ r: "$0", ml: "$space$px", w: "99%", bg: "$surface_bright" }}
          value={data.title}
          onChange={e => {
            const tempData = { ...data, title: e.target.value };
            setData(tempData);
          }}
          placeholder="Add Title..."
        />
        <StatusString content={invalidFields?.title || ""} />
      </PrebuiltSidebarItemWrapper>
      <PrebuiltSidebarItemWrapper
        title="Description"
        css={{ mt: 0 }}
        tooltipText="Description character limit is 1200."
      >
        <TextArea
          rows={3}
          value={data?.description}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            const tempData = { ...data, description: e.target.value };
            setData(tempData);
          }}
          maxLength={1200}
          placeholder="Add Description..."
        />

        <StatusString content={invalidFields?.description || ""} />
      </PrebuiltSidebarItemWrapper>
    </>
  );
}

export default PrebuiltConferencingHeaderConfig;

const defaultData =
  defaultLayout?.["screens"]?.["conferencing"]?.["default"]?.["elements"]?.[
    "header"
  ];

export const conferencingHeaderConfig = {
  ui: {
    title: "Header",
    hasSwitch: false,
    tooltipText: "",
  },
  path: "screens.conferencing.default.elements.header",
  Component: PrebuiltConferencingHeaderConfig,
  defaultData: defaultData,
  validator: () => {},
};
