import React, { ComponentProps, FC, ReactNode, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHasUnsavedTranscriptionChanges,
  toggleTranscription,
  updatePlugins,
} from "src/actions/RolesActions";
import TemplateConfigCard from "src/components/Cards/TemplateConfigCard";
import ConfigMultiSelect from "src/components/Common/ConfigMultiSelect";
import Line from "src/components/Common/Line";
import StatusString from "src/components/Common/StatusString";
import ValuePill from "src/components/Common/ValuePill";
import { transcriptionModes } from "src/constants";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { RootState } from "src/store/reducers";
import { TranscriptionType } from "src/types/policyTypes";
import { slugify } from "src/utils";
import { Flex, Switch, Text } from "@100mslive/roomkit-react";
interface ClosedCaptionsProps {
  transcription: TranscriptionType;
  setTranscription: (key: string, value: ReactNode) => void;
  isTranscriptionEnabled: boolean;
  roles: string[];
  selectedRolesForCaption?: string[];
  toggleClosedCaptionsRoles: (roles: string[]) => void;
}

const ClosedCaptions: FC<ComponentProps<typeof Flex> & ClosedCaptionsProps> = ({
  isTranscriptionEnabled,
  roles,
  toggleClosedCaptionsRoles,
  selectedRolesForCaption,
}) => {
  const cardTitle = "Closed Captions";
  const dispatch = useDispatch();
  const policyInfo = useSelector((state: RootState) => state.roles.policyInfo);

  const toggleCaption = useCallback(
    (enabled: boolean, mode: string) => {
      const firstRole = roles?.[0] || "";
      if (firstRole) {
        dispatch(setHasUnsavedTranscriptionChanges(true));
        dispatch(toggleTranscription(enabled as boolean, mode));
      }
    },
    [dispatch, roles]
  );

  const handleRoleSelect = (roles: string[]) => {
    dispatch(setHasUnsavedTranscriptionChanges(true));
    toggleClosedCaptionsRoles(roles);
  };

  const LeftComponent = () => (
    <Flex
      css={{
        maxHeight: "5000px",
        transition: "max-height ease 0.3s",
        overflow: "hidden",
      }}
    >
      <Text
        variant="body2"
        css={{ c: "$on_surface_low", fontWeight: "$regular" }}
      >
        This enables the ability to turn on closed captions (speaker labelled)
        for all the peers in a conferencing room.
      </Text>
    </Flex>
  );

  const rightComponent = (
    <Flex direction="column" css={{ minWidth: "100%" }}>
      <Flex
        direction="column"
        css={{
          py: "$10",
          minWidth: "100%",
        }}
      >
        <SettingsTitle
          key="Closed Captioning for Conferencing"
          title={
            <Text variant="caption" css={{ fontWeight: "$regular" }}>
              Enable Closed Captioning with speaker labels on video conferencing
              rooms. This will need to be enabled in the room by participants
              with permissions to enable / disable.
            </Text>
          }
          text="Closed Captioning for Conferencing"
        />
        <Switch
          checked={isTranscriptionEnabled}
          onCheckedChange={e => {
            toggleCaption(e, transcriptionModes.CAPTION);
            if (e) {
              const tempPolicy = { ...policyInfo };
              tempPolicy.plugins = {
                ...policyInfo.plugins,
                transcriptions: [
                  {
                    permissions: {
                      admin: roles,
                    },
                    mode: transcriptionModes.CAPTION,
                  },
                ],
              };
              dispatch(updatePlugins(tempPolicy.plugins));
            }
          }}
          css={{ mt: "$4" }}
        />
        {isTranscriptionEnabled ? (
          <>
            <Line />
            <SettingsTitle
              key="broadcasterRoles"
              title="Choose roles which will have the permission to enable or disable Closed Captions for everyone in the room. Viewers of the live stream (HLS) cannot be selected."
              text="Enable/Disable Closed Captions"
            />
            <ConfigMultiSelect
              inputText="Select roles which can enable or disable"
              optionsArray={roles}
              onCheckedChange={e => handleRoleSelect([e])}
              isCheckedCondition={role =>
                ((selectedRolesForCaption as string[]) || [])?.includes(role)
              }
            />
            <Flex css={{ w: "100%", flexWrap: "wrap" }}>
              {selectedRolesForCaption?.map((roleName: string) => (
                <ValuePill
                  key={roleName}
                  content={roleName}
                  onClick={() => handleRoleSelect([roleName])}
                />
              ))}
              <StatusString
                content={
                  selectedRolesForCaption && selectedRolesForCaption?.length > 0
                    ? ""
                    : "At least one role must be selected"
                }
              />
            </Flex>
          </>
        ) : null}
      </Flex>
    </Flex>
  );

  return (
    <TemplateConfigCard
      text={cardTitle}
      id={slugify(cardTitle)}
      classNameForText="config-subheading"
      subtitle=""
      leftComponent={<LeftComponent />}
      rightComponent={rightComponent}
    />
  );
};

export default ClosedCaptions;
