import React, { PureComponent } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MetisMenu from "metismenujs";
import { AppAnalytics } from "src/helpers";
import { createAppLayout } from "src/store/appLayout/actions";
import {
  ChevronDownIcon,
  ClockIcon,
  CodeIcon,
  DoorIcon,
  HomeIcon,
  LayoutIcon,
  PeopleIcon,
  ReportIcon,
  StatsIcon,
} from "@100mslive/react-icons";
import { Box, Flex, Loading, Tooltip } from "@100mslive/roomkit-react";
import { API_CALL_STATE } from "../../constants";
import {
  currentUser,
  getCurrentWorkspaceID,
  getMaxHeight,
  isAdmin,
} from "../../utils";
import WorkspaceCollapsible from "../Common/WorkspaceCollapsible";
import LeftBorderBox from "../Sidebar/LeftBorderBox";
import SidebarOption from "../Sidebar/SidebarOption";
import TemplateList from "../Sidebar/TemplateList";

class SidebarContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeOption: "",
      limitTemplates: true,
      templateDropdownOpen: false,
      prebuiltDialogOpen: false,
      prebuiltTemplateId: "",
    };

    this.handleAdminTabs = this.handleAdminTabs.bind(this);
    this.metisMenu = null;
  }

  componentDidMount() {
    if (window.innerWidth < 968) {
      document.getElementById("main-content-dashboard").style.padding =
        "2.25rem 1.75rem";
    }
    this.setState({
      activeOption: this.props?.location?.pathname.split("/")?.[1] || " ",
    });
  }

  // eslint-disable-next-line complexity
  componentDidUpdate(prevProps, prevState) {
    if (
      !this.initMetisMenu &&
      this.props.fetchAllWorkspacesStatus === API_CALL_STATE.DONE
    ) {
      this.metisMenu = new MetisMenu("#side-menu");
      this.initMetisMenu = true;
    }
    if (
      this.state.activeOption !== prevState.activeOption ||
      (prevState.activeOption === "" &&
        this.props.fetchTemplateStatus === API_CALL_STATE.DONE)
    ) {
      if (
        this.props?.location?.pathname.split("/")?.[1] === "templates" &&
        prevState.activeOption !== "templates" &&
        this.state.templateDropdownOpen === false
      ) {
        this.setState({ templateDropdownOpen: true });
      }
      this.setState({
        activeOption: this.props?.location?.pathname.split("/")?.[1] || " ",
      });
    }
  }

  componentWillUnmount() {
    if (this.metisMenu) {
      this.metisMenu.dispose();
    }
  }

  handleTemplateClick = () => {
    const templates = this.props.templates || {};
    const templatesKeys = Object.keys(templates);

    if (this.props.fetchTemplateStatus === "processing") {
      return;
    }
    if (
      templatesKeys.length > 0 &&
      !this.props.location.pathname.startsWith("/templates")
    ) {
      this.setState({
        templateDropdownOpen: true,
        limitTemplates: true,
      });
      this.props.history.push(`/templates/${templates[templatesKeys[0]].id}`);
    } else if (!templatesKeys.length) {
      this.props.history.push("/templates/");
    } else {
      this.setState({
        templateDropdownOpen: !this.state.templateDropdownOpen,
      });
    }
  };

  iconStyle = {
    height: "20px",
    width: "20px",
    color: "inherit",
  };

  sideBarOptions = {
    dashboard: {
      link: "/dashboard",
      text: "Dashboard",
      icon: <HomeIcon style={this.iconStyle} />,
      btnId: "home",
    },

    rooms: {
      text: "Rooms",
      icon: <DoorIcon style={this.iconStyle} />,
      btnId: "rooms",
    },
    sessions: {
      link: "/sessions",
      text: "Sessions",
      icon: <ClockIcon style={this.iconStyle} />,
      btnId: "sessions",
    },
    insights: {
      link: "/insights",
      text: "Insights",
      icon: <StatsIcon style={this.iconStyle} />,
      btnId: "insights",
    },
    "events-inspector": {
      link: "/events-inspector",
      text: "Events Inspector",
      icon: <ReportIcon style={this.iconStyle} />,
      btnId: "eventsinspector",
    },
    developer: {
      link: "/developer",
      text: "Developer",
      icon: <CodeIcon style={this.iconStyle} />,
      btnId: "developer",
    },
    customers: {
      link: "/admin",
      text: "Customers",
      icon: <PeopleIcon style={this.iconStyle} />,
      btnId: "admin",
    },
  };

  handleAdminTabs = () => {
    const isAdmin = this.props?.workspace?.is_admin;
    let cursorCSS = "";
    if (!isAdmin) {
      cursorCSS =
        "cursor-not-allowed hover:cursor-not-allowed pointer-events-none";
    }

    return (
      <RestrictedSection disabled={isAdmin}>
        <span className={isAdmin ? "" : "opacity-50"}>
          <li className={cursorCSS}>
            <SidebarOption
              collapsed={this.props.collapsed}
              {...this.sideBarOptions["developer"]}
              activeOption={this.state.activeOption}
            />
          </li>
        </span>
      </RestrictedSection>
    );
  };

  // togglePrebuiltDialog = () => {
  //   this.setState({ prebuiltDialogOpen: !this.state.prebuiltDialogOpen });
  // };

  // eslint-disable-next-line complexity
  render() {
    const userCookie = currentUser();
    return (
      <React.Fragment>
        <div
          id="sidebar-menu"
          className="mb-32 overflow-y-auto overscroll-auto"
        >
          <ul className="metismenu list-unstyled" id="side-menu">
            {isAdmin() && !userCookie?.customer?.workspace_id ? null : (
              <li style={{ margin: "0 auto" }}>
                <WorkspaceCollapsible collapsed={this.props.collapsed} />
              </li>
            )}
            {isAdmin() && (
              <SidebarOption
                collapsed={this.props.collapsed}
                {...this.sideBarOptions["customers"]}
                activeOption={this.state.activeOption}
              />
            )}

            <SidebarOption
              collapsed={this.props.collapsed}
              {...this.sideBarOptions["dashboard"]}
              activeOption={this.state.activeOption}
            />
            <li>
              <Flex
                onClick={() => {
                  AppAnalytics.track("btn.clicked", {
                    btnId: `sidebar.templates`,
                    componentId: "sidebar",
                  });
                }}
                css={{ position: "relative" }}
              >
                <LeftBorderBox
                  show={
                    this.state.activeOption === "templates" &&
                    !this.props.collapsed
                  }
                />

                {/* eslint-disable-next-line */}
                <a onClick={this.handleTemplateClick}>
                  {/* CSS matches SidebarOption except for the gap, width */}
                  <Flex
                    className="option-container"
                    css={{
                      "&:hover": {
                        backgroundColor: "$surface_brighter",
                        color: "$on_surface_high",
                      },
                      padding: this.props.collapsed ? "$4" : "$4 $8",
                      gap: "0",
                      borderRadius: this.props.collapsed ? "$1" : "$0",
                      margin: "0 auto",
                      transition: "all 0.2s",
                      ...(this.state.activeOption === "templates"
                        ? {
                            color: "$on_surface_high",
                            fontWeight: "$semiBold",
                            backgroundColor: "$surface_bright",
                            fontSize: "$sm",
                          }
                        : { color: "$on_surface_medium" }),
                    }}
                  >
                    <LayoutIcon style={this.iconStyle} />
                    <span
                      className="text-inherit"
                      style={{
                        display: this.props.collapsed ? "none" : "inline-block",
                        marginRight: this.props.collapsed ? "0" : "4.25rem",
                        marginLeft: "1rem",
                      }}
                    >
                      Templates
                    </span>
                    {!this.props.collapsed &&
                    this.state.activeOption === "templates" &&
                    Object.keys(this.props.templates).length ? (
                      <ChevronDownIcon
                        height={20}
                        width={20}
                        style={{
                          transition: "all 0.35s linear",
                          transform: this.state.templateDropdownOpen
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    ) : (
                      <Box
                        css={{
                          w: "20px",
                          display: this.props.collapsed ? "none" : "block",
                        }}
                      />
                    )}
                  </Flex>
                </a>
              </Flex>
              {!this.props.collapsed ? (
                <Box
                  style={{
                    maxHeight: getMaxHeight(
                      Object.keys(this.props.templates).length,
                      this.state.limitTemplates,
                      this.state.templateDropdownOpen,
                      64
                    ),
                    overflow: "hidden",
                    transition: "max-height 0.3s ease-in-out",
                  }}
                >
                  <TemplateList
                    all_templates={this.props.templates}
                    templates={Object.keys(this.props.templates).slice(0, 4)}
                  />
                  {Object.keys(this.props.templates || {}).length > 4 ? (
                    <TemplateList
                      viewAllTemplates={() =>
                        this.setState({ limitTemplates: false })
                      }
                      limitTemplates={this.state.limitTemplates}
                      all_templates={this.props.templates}
                      templates={Object.keys(this.props.templates).slice(4)}
                    />
                  ) : null}
                </Box>
              ) : null}
              {this.state.activeOption === "templates" &&
              this.props.fetchTemplateStatus === API_CALL_STATE.IN_PROGRESS ? (
                <Flex
                  align="center"
                  justify="center"
                  css={{ w: "100%", mt: "$4" }}
                >
                  <Loading size={24} />
                </Flex>
              ) : null}
            </li>

            <SidebarOption
              collapsed={this.props.collapsed}
              {...this.sideBarOptions["rooms"]}
              activeOption={this.state.activeOption}
              link={
                this.props.location.pathname === "/create-room"
                  ? "/create-room"
                  : "/rooms"
              }
            />

            <SidebarOption
              collapsed={this.props.collapsed}
              {...this.sideBarOptions["sessions"]}
              activeOption={this.state.activeOption}
            />

            <SidebarOption
              collapsed={this.props.collapsed}
              {...this.sideBarOptions["insights"]}
              activeOption={this.state.activeOption}
            />

            <SidebarOption
              collapsed={this.props.collapsed}
              {...this.sideBarOptions["events-inspector"]}
              activeOption={this.state.activeOption}
            />
            {this.handleAdminTabs()}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const RestrictedSection = ({
  children,
  disabled,
  message = "Only the organization owner can view this section",
}) => {
  return (
    <Tooltip
      disabled={disabled}
      title={<div className="w-40">{message}</div>}
      side="top"
      align="center"
    >
      {children}
    </Tooltip>
  );
};

const mapStatetoProps = state => {
  const workspaceID = getCurrentWorkspaceID();
  const { workspaces, status } = state.workspace;
  const { templates, fetchTemplateStatus } = state.roles;

  return {
    ...state,
    templates,
    fetchTemplateStatus,
    all_templates: state.roles.all_templates,
    workspace: workspaces[workspaceID],
    fetchAllWorkspacesStatus: status.fetchAllWorkspaces,
    layout: state.appLayouts.appLayout,
    fetchAppLayoutStatus: state.appLayouts.fetchAppLayoutStatus,
  };
};

export default connect(mapStatetoProps, {
  createAppLayout,
})(withRouter(withNamespaces()(SidebarContent)));
