import { Text } from "@100mslive/roomkit-react";
import question from "../../assets/images/question.png";
import { openDevrevWidget } from "../../helpers/utils";

const NoResults = () => (
  <>
    <img src={question} alt="100ms" width="40px" height="40px" />
    <Text
      css={{
        w: "min(375px, 100%)",
        textAlign: "center",
        mt: "$md",
      }}
      variant="body1"
    >
      No results found
    </Text>
    <Text
      css={{
        w: "min(400px, 100%)",
        textAlign: "center",
        mt: "$xs",
        fontWeight: "$regular",
        c: "$on_surface_medium",
      }}
      variant="body2"
    >
      Please check if you have entered valid inputs. Also note that event data
      older than 30 days is not available.
    </Text>
    <Text
      css={{
        textAlign: "center",
        mt: "$3",
        fontWeight: "$regular",
        c: "$on_surface_medium",
      }}
      variant="body2"
    >
      Please click{" "}
      <span
        className="text-blue-tint cursor-pointer"
        onClick={openDevrevWidget}
      >
        here
      </span>{" "}
      if in need of older data.
    </Text>
  </>
);

export default NoResults;
