import React, { ComponentProps, isValidElement, PropsWithoutRef } from "react";
import { textVariants } from "src/types/common";
import { slugify } from "src/utils";
import { CSS, Flex, Text } from "@100mslive/roomkit-react";

const TextAndSubtext = ({
  text = "",
  subText = "",
  textVariant = "h6" as textVariants,
  subTextVariant = "sm" as textVariants,
  flexGap = "$4",
  textCss = {},
  classNameForText = "",
  containerCSS = {},
  setID = false,
  ...props
}: PropsWithoutRef<
  ComponentProps<typeof Flex> & {
    text: string;
    subText: string | JSX.Element;
    textVariant?: textVariants;
    subTextVariant?: textVariants;
    flexGap?: string;
    textCss?: CSS;
    classNameForText?: string;
    containerCSS?: CSS;
    setID?: boolean;
  }
>) => (
  <Flex direction="column" css={{ ...containerCSS }} {...props}>
    <Text
      variant={textVariant}
      className={classNameForText}
      id={setID ? slugify(text as string) : ""}
      css={{ c: "$on_surface_high", ...textCss }}
    >
      {text as string}
    </Text>
    {subText ? (
      isValidElement(subText) ? (
        subText
      ) : typeof subText === "string" ? (
        <Text
          css={{ c: "$on_surface_low", mt: flexGap, fontWeight: "$regular" }}
          variant={subTextVariant}
        >
          {subText}
        </Text>
      ) : null
    ) : null}
  </Flex>
);
export default TextAndSubtext;
