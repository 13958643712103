import React, {
  ComponentProps,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { isEmpty, set } from "lodash";
import {
  setHasUnsavedLiveStreamingChanges,
  updateHLSDestinations,
  updatePolicyAndAppLayout,
  updatePolicyInfo,
  updatePolicyRole,
} from "src/actions/RolesActions";
import SectionHeader from "src/components/Common/SectionHeader";
import { SideList, SideListItem } from "src/components/Common/SideList";
import toastr from "src/components/Common/toastr";
import TemplateLayout from "src/components/layout/templateLayout";
import {
  getPriorityRolesArray,
  INTERNAL_RECORDER_ROLE,
  isHLSViewer,
  isVisibleRole,
} from "src/helpers";
import useActiveLinks from "src/hooks/useActiveLink";
import { RootState } from "src/store/reducers";
import { hlsDestinationsLayerType } from "src/types/policyTypes";
import { slugify } from "src/utils";
import { Flex } from "@100mslive/roomkit-react";
import HLS from "./HLS";
import RTMP from "./RTMP";

interface Sections {
  [key: string]: {
    title: string;
    children: string[];
  };
}

const sections: Sections = {
  // "Video Tile Layout": { title: "Video Tile Layout", children: [] },
  HLS: {
    title: "Live stream (HLS)",
    children: ["Stream Video Output", "Stream Recording"],
  },
  RTMP: {
    title: "External stream (RTMP out)",
    children: [],
  },
};

const LiveStreaming: FC<
  ComponentProps<typeof Flex> & {
    policyId: string;
    setSelected: Dispatch<SetStateAction<string>>;
    isValidInternalRecorderState: boolean;
  }
  // eslint-disable-next-line complexity
> = ({ policyId, setSelected, isValidInternalRecorderState }) => {
  const dispatch = useDispatch();
  const hasUnsavedLiveStreamingChanges = useSelector(
    (state: RootState) => state.roles.hasUnsavedLiveStreamingChanges
  );

  const invalidFields = useSelector(
    (state: RootState) => state.roles.invalidFields
  );
  const roleLayouts = useSelector(
    (state: RootState) => state.appLayouts.roleLayouts
  );
  const hlsDestinations =
    useSelector(
      (state: RootState) => state.roles.policyInfo.destinations.hlsDestinations
    ) || {};

  const policyInfo = useSelector((state: RootState) => state.roles.policyInfo);

  const roles = useSelector((state: RootState) => state.roles.policyInfo.roles);
  const roleNames = Object.keys(roles) || [];

  const recordingConfig =
    useSelector((state: RootState) => state.roles.policyInfo.recordings?.[0]) ||
    {};

  const enabledAutoStartRecording =
    recordingConfig.compositeRecording?.browserComposite?.autoStart || false;

  const internalRecorderSubscriptionList = Object.values(
    roles?.[INTERNAL_RECORDER_ROLE]?.subscribeParams?.subscribeToRoles || {}
  ) as string[];

  const internalRecorderRolesForSubscription = getPriorityRolesArray(
    roleNames.filter(isVisibleRole).filter(role => !isHLSViewer(role))
  );

  const subscribeToClicked = useCallback(
    (roleName: string) => {
      const path = "subscribeParams.subscribeToRoles";

      if (internalRecorderSubscriptionList.includes(roleName)) {
        const i = internalRecorderSubscriptionList.indexOf(roleName);
        internalRecorderSubscriptionList.splice(i, 1);
      } else {
        internalRecorderSubscriptionList.push(roleName);
      }
      dispatch(setHasUnsavedLiveStreamingChanges(true));
      dispatch(
        updatePolicyRole({
          roleName: INTERNAL_RECORDER_ROLE,
          path,
          value: [...internalRecorderSubscriptionList],
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, internalRecorderRolesForSubscription]
  );

  useEffect(() => {
    if (!policyId) {
      return;
    }
    dispatch(setHasUnsavedLiveStreamingChanges(false));
    // eslint-disable-next-line
  }, [policyId]);

  const activeSection = useActiveLinks("config-subheading");

  const changeHLSDestination = (
    destinationId: string,
    path: string,
    value: string | boolean | number[] | number | hlsDestinationsLayerType[]
  ) => {
    let updatedHLSDestination = { ...hlsDestinations[destinationId] };
    updatedHLSDestination = set(updatedHLSDestination, path, value);
    dispatch(setHasUnsavedLiveStreamingChanges(true));
    dispatch(updateHLSDestinations(updatedHLSDestination));
  };

  return (
    <TemplateLayout
      unsaved={hasUnsavedLiveStreamingChanges}
      onSaveClick={function (): void {
        if (
          Object.keys(invalidFields?.["hlsDestinations"] || {}).length ||
          Object.keys(invalidFields?.["rtmpDestinations"] || {}).length ||
          !isValidInternalRecorderState
        ) {
          toastr.error("Please enter valid values to save changes");
        } else {
          if (!isEmpty(roleLayouts)) {
            dispatch(
              updatePolicyAndAppLayout({
                policy: policyInfo,
                roleLayouts: roleLayouts,
              })
            );
          } else {
            dispatch(updatePolicyInfo(policyId, policyInfo));
          }
          dispatch(setHasUnsavedLiveStreamingChanges(false));
        }
      }}
    >
      <Flex
        direction="column"
        css={{
          minWidth: "210px",
          position: "sticky",
          overflow: "clip auto",
          top: "$20",
          h: "100%",
        }}
      >
        {Object.keys(sections).map((section: string) => {
          const url = slugify(sections[section].title);
          return (
            <HashLink
              to={`?tab=live-streaming#${url}`}
              style={{ width: "100%" }}
              key={sections[section].title}
              onClick={e => e.stopPropagation()}
            >
              <SideList
                showChevron={sections[section].children.length > 0}
                title={sections[section].title}
                subtitle=""
                active={
                  activeSection === url ||
                  sections[section]?.children.some(
                    child => slugify(child) === activeSection
                  )
                }
              >
                {sections[section]?.children.map(child => {
                  const childUrl = slugify(child);
                  return (
                    <HashLink
                      to={`?tab=live-streaming#${childUrl}`}
                      style={{ width: "100%" }}
                      onClick={e => e.stopPropagation()}
                      key={child}
                    >
                      <SideListItem
                        title={child}
                        active={activeSection === childUrl}
                        onClick={() => null}
                      />
                    </HashLink>
                  );
                })}
              </SideList>
            </HashLink>
          );
        })}
      </Flex>

      <Flex
        direction="column"
        css={{
          rowGap: "$12",
          w: "100%",
          left: "240px",
          flexGrow: 1,
          mb: "$10",
        }}
      >
        <SectionHeader
          title="Live Streaming"
          body="Define configuration here and start/stop live streaming with the SDK or API."
          buttonText="Read Guide"
          link={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/features/live-streaming`}
        />
        <HLS
          changeHLSDestination={changeHLSDestination}
          subscribeToClicked={subscribeToClicked}
          enabledAutoStartRecording={enabledAutoStartRecording}
          internalRecorderSubscriptionList={internalRecorderSubscriptionList}
          internalRecorderRolesForSubscription={
            internalRecorderRolesForSubscription
          }
          setSelected={setSelected}
          isValidInternalRecorderState={isValidInternalRecorderState}
        />
        <RTMP
          subscribeToClicked={subscribeToClicked}
          enabledAutoStartRecording={enabledAutoStartRecording}
          internalRecorderSubscriptionList={internalRecorderSubscriptionList}
          internalRecorderRolesForSubscription={
            internalRecorderRolesForSubscription
          }
          setSelected={setSelected}
          isValidInternalRecorderState={isValidInternalRecorderState}
        />
      </Flex>
    </TemplateLayout>
  );
};
export default LiveStreaming;
