import { CrossIcon } from "@100mslive/react-icons";
import { Flex, Text } from "@100mslive/roomkit-react";

const Pill = ({ text, value, onRemove, options = {}, type = "input" }) => {
  return value ? (
    <Flex
      align="center"
      gap="2"
      css={{
        background: "$surface_bright !important",
        borderRadius: "$round",
        p: "$2 $md",
        border: "1px solid",
        borderColor: "$border_bright",
        w: "max-content",
      }}
    >
      <Text variant="sm" css={{ color: "$on_surface_medium" }}>
        {text}:
      </Text>
      <Text
        variant="sm"
        css={{ fontWeight: "$semiBold", color: "$on_surface_high" }}
      >
        {type === "dropdown"
          ? Object.keys(options).find(key => options[key] === value)
          : value}
      </Text>
      <Flex
        css={{
          color: "$on_surface_medium",
          cursor: "pointer",
          "&:hover": { color: "$on_surface_high" },
        }}
        onClick={onRemove}
      >
        <CrossIcon height="18" width="18" />
      </Flex>
    </Flex>
  ) : null;
};

export default Pill;
