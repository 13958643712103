import React, { PropsWithChildren, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API_CALL_STATE, ROLE_TYPES } from "src/constants";
import type { RootState } from "src/store/reducers";
import { getEmailfromLocal } from "src/utils";
import { Button, Flex, Loading, Text } from "@100mslive/roomkit-react";

function TemplateLayout({
  unsaved,
  onSaveClick,
  children,
  isValid = true,
}: PropsWithChildren<{
  unsaved: boolean;
  onSaveClick: () => void;
  isValid?: boolean;
}>) {
  const fetchPolicyInfoStatus = useSelector(
    (state: RootState) => state.roles.fetchPolicyInfoStatus
  );

  const fetchRolesStatus = useSelector(
    (state: RootState) => state.roles.fetchRolesStatus
  );
  const loading =
    fetchPolicyInfoStatus === API_CALL_STATE.IN_PROGRESS ||
    fetchRolesStatus === API_CALL_STATE.IN_PROGRESS;

  const current = useSelector((state: RootState) => state.workspace.current);
  const [isReadOnlyWorkspaceUser, setIsReadOnlyWorkspaceUser] = useState(true);

  useEffect(() => {
    let isReadOnlyUser = true;
    const currentMembers = current.members || [];
    const currentUserEmail = getEmailfromLocal();
    for (const member of currentMembers) {
      if (
        currentUserEmail === member?.email &&
        member?.role === ROLE_TYPES.READ_ONLY
      ) {
        isReadOnlyUser = true;
        break;
      } else {
        isReadOnlyUser = false;
      }
    }
    setIsReadOnlyWorkspaceUser(isReadOnlyUser);
  }, [current]);
  return (
    <Flex direction="column" css={{ position: "relative" }}>
      <Flex
        direction="row"
        css={{
          scrollBehavior: "smooth",
          columnGap: "$20",
        }}
      >
        {loading ? (
          <Flex
            css={{ w: "100%", c: "$primary_bright", h: "100%", p: "$12" }}
            justify="center"
          >
            <Loading size={72} />
          </Flex>
        ) : (
          <>{children}</>
        )}
      </Flex>
      {!loading ? (
        <Flex
          css={{
            width: "100%",
            overflow: "clip auto",
            position: "fixed",
            bottom: "0%",
            right: 0,
            zIndex: 10,
            borderTop: "$space$px solid $border_default",
            bg: "$background_default",
            p: "$6 $12",
          }}
          align="center"
          justify="end"
        >
          {unsaved && !isReadOnlyWorkspaceUser ? (
            <Text css={{ c: "$on_surface_low", mr: "$4" }}>
              Unsaved Changes
            </Text>
          ) : null}
          <Button
            disabled={!unsaved || !isValid || isReadOnlyWorkspaceUser}
            onClick={onSaveClick}
            css={{ r: "$0", p: "$3 $8" }}
          >
            <Text variant="sub2">Save</Text>
          </Button>
        </Flex>
      ) : null}
    </Flex>
  );
}

export default TemplateLayout;
