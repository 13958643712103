import React, { ComponentProps, PropsWithChildren } from "react";
import { MODAL_Z_INDEX } from "src/constants";
import { AppAnalytics } from "src/helpers";
import { Button, CSS, Dialog, Flex, Text } from "@100mslive/roomkit-react";

const DialogRoot: React.FC<
  PropsWithChildren<ComponentProps<typeof Dialog.Root>>
> = ({ children, ...props }) => (
  <Dialog.Root {...props}>{children}</Dialog.Root>
);

const DialogContent: React.FC<
  PropsWithChildren<
    ComponentProps<typeof Dialog.Content> & {
      width?: string;
      hideCloseIcon?: boolean;
      css?: CSS;
    }
  >
> = ({ width = "1000px", children, ...props }) => (
  <>
    <Dialog.Overlay
      css={{
        backgroundColor: "rgba(0, 0, 0, 0.8);",
        inset: 0,
        zIndex: MODAL_Z_INDEX,
      }}
    />
    <Dialog.Content
      {...props}
      css={{
        width: `min(${width},100%)`,
        border: "1px solid $border_bright",
        bg: "$surface_default",
        p: 0,
        zIndex: MODAL_Z_INDEX,
        position: "fixed",
        ...props?.css,
      }}
    >
      <Flex direction="column"> {children}</Flex>
    </Dialog.Content>
  </>
);

const DialogHeader: React.FC<
  PropsWithChildren<
    ComponentProps<typeof Dialog.Title> & {
      title: string;
      subtitle?: string;
      Icon?: React.ReactElement;
      hideCloseIcon?: boolean;
      componentId?: string;
      btnId?: string;
    }
  >
> = ({
  title,
  subtitle,
  Icon,
  hideCloseIcon,
  btnId = "",
  componentId = "",
  ...props
}) => (
  <Flex direction="column">
    <Flex css={{ position: "relative", p: "$10" }}>
      <Dialog.Title {...props} css={{ minWidth: "100%" }}>
        <Flex direction="row" align="center" justify="between">
          <Flex align="center">
            {Icon}
            <Flex direction="column" css={{ ml: "$10" }}>
              <Text variant="h6">{title}</Text>
              {subtitle ? (
                <Text
                  variant="body2"
                  css={{
                    c: "$on_surface_medium",
                    mt: "$2",
                    fontWeight: "$regular",
                  }}
                >
                  {subtitle}
                </Text>
              ) : null}
            </Flex>
          </Flex>
          <Flex align="center">
            {!hideCloseIcon && (
              <Dialog.DefaultClose
                onClick={() => {
                  if (btnId && componentId) {
                    AppAnalytics.track("btn.clicked", {
                      btnId,
                      componentId,
                    });
                  }
                }}
                data-testid="dialoge_cross_icon"
              />
            )}
          </Flex>
        </Flex>
      </Dialog.Title>
    </Flex>
    <Flex
      css={{ borderBottom: "$space$px $border_bright solid", minWidth: "100%" }}
    />
  </Flex>
);

const DialogFooter: React.FC<
  PropsWithChildren<
    ComponentProps<typeof Flex> & {
      InfoMessage?: React.ReactNode;
      basic?: boolean;
      variant?: "standard" | "danger" | "primary" | undefined;
      css?: CSS;
      ctaText?: string;
      cancelText?: string;
      disableAction: boolean;
      onActionClick?: () => unknown;
      onCancelClick?: () => unknown;
    }
  >
> = ({
  onActionClick,
  onCancelClick,
  disableAction,
  InfoMessage,
  children = [],
  basic = true,
  variant = "primary",
  ctaText = "",
  cancelText = "Cancel",
  ...props
}) => (
  <Flex css={{ ...props?.css, minWidth: "100%" }} {...props}>
    <Flex direction="column" css={{ minWidth: "100%" }}>
      <Flex
        css={{
          borderBottom: "$space$px $border_bright solid",
          minWidth: "100%",
        }}
      />
      <Flex css={{ p: "$10" }}>
        {basic ? (
          <Flex justify="between" css={{ minWidth: "100%" }}>
            <Flex>{InfoMessage}</Flex>
            <Flex css={{ gap: "$8" }}>
              <Button outlined variant="standard" onClick={onCancelClick}>
                {cancelText}
              </Button>
              <Button
                disabled={disableAction}
                variant={variant}
                onClick={onActionClick}
              >
                {ctaText}
              </Button>
            </Flex>
          </Flex>
        ) : (
          children
        )}
      </Flex>
    </Flex>
  </Flex>
);

export const ComposableDialog: {
  Root: typeof DialogRoot;
  Content: typeof DialogContent;
  Footer: typeof DialogFooter;
  Header: typeof DialogHeader;
  Portal: typeof Dialog.Portal;
} = {
  Root: DialogRoot,
  Content: DialogContent,
  Footer: DialogFooter,
  Header: DialogHeader,
  Portal: Dialog.Portal,
};
