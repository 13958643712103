import React, { ComponentProps, PropsWithoutRef } from "react";
import { CheckCircleIcon } from "@100mslive/react-icons";
import { Box, CSS, Flex, Text } from "@100mslive/roomkit-react";

const TabTile: React.FC<
  PropsWithoutRef<
    ComponentProps<typeof Flex> & { active: boolean; text: string; css?: CSS }
  >
> = ({ active = false, text = "", css, ...props }) => (
  <Flex
    css={{
      bg: active ? "$surface_bright" : "$surface_default",
      p: "$8",
      r: "$0",
      cursor: "pointer",
      border: "$space$1 solid",
      borderColor: active ? "$primary_default" : "$border_default",
      "&:hover": {
        bg: active ? "$surface_default" : "$surface_dim",
        borderColor: active ? "$primary_bright" : "$border_bright",
      },
      ...css,
    }}
    align="center"
    justify="between"
    {...props}
  >
    <Text
      variant="body2"
      css={{
        c: "$on_surface_high",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {text}
    </Text>
    {active ? (
      <Box css={{ c: "$primary_default" }}>
        <CheckCircleIcon width={20} height={20} />
      </Box>
    ) : null}
  </Flex>
);

export default TabTile;
