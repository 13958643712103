import React, { FC, ReactNode, useState } from "react";
import { Chip } from "src/components";
import TemplateConfigCard from "src/components/Cards/TemplateConfigCard";
import ConfigMultiSelect from "src/components/Common/ConfigMultiSelect";
import ValuePill from "src/components/Common/ValuePill";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { TranscriptionType } from "src/types/policyTypes";
import { slugify } from "src/utils";
import { Box, Flex, Input, Text } from "@100mslive/roomkit-react";

interface AdvancedTranscriptionSettingsProps {
  transcription: TranscriptionType;
  setTranscription: (key: string, value: ReactNode) => void;
  internalRecorderSubscriptionList: string[];
  internalRecorderRolesForSubscription: string[];
}

const AdvancedTranscriptionSettings: FC<AdvancedTranscriptionSettingsProps> = ({
  transcription,
  setTranscription,
  internalRecorderRolesForSubscription,
  internalRecorderSubscriptionList,
}: AdvancedTranscriptionSettingsProps) => {
  const [customVocabInput, setCustomVocabInput] = useState("");

  const customVocabulary = transcription?.customVocabulary || [];

  const removeCustomVocabWord = (value: string) => {
    const updatedVocabulary = [...customVocabulary];
    const index = updatedVocabulary.indexOf(value);
    if (index > -1) {
      updatedVocabulary.splice(index, 1);
    }
    setTranscription("customVocabulary", updatedVocabulary);
  };

  const cardTitle = "Advanced Transcription Configuration";

  const LeftComponent = () => (
    <Flex
      css={{
        transition: "max-height ease 0.3s",
        overflow: "hidden",
      }}
    >
      <Text
        variant="body2"
        css={{ c: "$on_surface_low", fontWeight: "$regular" }}
      >
        These settings are applicable for Closed Captions, Live Transcription
        (HLS) and Post Call Transcription.
      </Text>
    </Flex>
  );

  const customVocab = (
    <Box
      css={{
        px: "$1",
        py: "$2",
        maxHeight: "18rem",
        overflowY: "auto",
        bg: "$surface_bright",
        borderRadius: "$md",
        w: "100%",
        border: "$space$px solid $border_bright",
      }}
    >
      {customVocabulary.length > 0 && (
        <div className="flex flex-row flex-wrap mb-2 gap-2">
          {[...customVocabulary].map((value, index) => (
            <Chip
              className="advanced-transcription-settings__chip"
              onClick={() => removeCustomVocabWord(value)}
              text={value}
              key={index}
              css={{
                display: "flex",
                flexDirection: "row",
                maxWidth: "100%",
                c: "$on_surface_high",
                border: "$space$px solid $border_bright",
                w: "min-content",
                bg: "$surface_brighter",
              }}
            />
          ))}
        </div>
      )}
      <form
        onSubmit={e => {
          const updatedVocabulary = [...customVocabulary, customVocabInput];
          setTranscription("customVocabulary", updatedVocabulary);
          setCustomVocabInput("");
          e.preventDefault();
        }}
      >
        <Input
          type="textarea"
          placeholder="Non-dictionary words like 100ms, Jane, John, etc."
          value={customVocabInput}
          onChange={e => {
            const { value } = e.target;
            setCustomVocabInput(value);
            e.preventDefault();
          }}
          css={{
            w: "100%",
            "&:focus": { border: "none", boxShadow: "none" },
            border: "none",
            boxShadow: "none",
            fontSize: "$body2",
          }}
          required
        />
      </form>
    </Box>
  );

  const rightComponent = (
    <Flex direction="column" css={{ minWidth: "100%" }}>
      <>
        <Flex
          direction="column"
          css={{
            py: "$10",
            minWidth: "100%",
          }}
        >
          <SettingsTitle
            key="Custom Vocabulary"
            title="Add non-dictionary words like names, abbreviations, slang, and technical jargon which may not be recognised by the AI model for better transcription accuracy."
            text="Custom Vocabulary"
          />
          {customVocab}
          <Text
            className="mt-2"
            variant="caption"
            css={{ color: "$on_surface_medium" }}
          >
            Press Return to add another word
          </Text>
        </Flex>
        <Flex
          direction="column"
          css={{
            py: "$10",
            minWidth: "100%",
            borderBottom: "$space$px solid $border_default",
          }}
        >
          <SettingsTitle
            key="Language"
            title="Select the language that needs to be transcribed. This will be the spoken language and not for the purpose of translation. Transcription only supports English language at this point of time. Support for more languages will come soon."
            text="Language"
          />
          <ConfigMultiSelect
            inputText="Language selection is currently disabled"
            optionsArray={internalRecorderRolesForSubscription}
            onCheckedChange={() => {}}
            isCheckedCondition={role =>
              ((internalRecorderSubscriptionList as string[]) || [])?.includes(
                role
              )
            }
            disabled={true}
          />
          <Flex css={{ w: "100%", flexWrap: "wrap" }}>
            {["English"].map((roleName: string) => (
              <ValuePill key={roleName} content={roleName} onClick={() => {}} />
            ))}
          </Flex>
        </Flex>
      </>
    </Flex>
  );

  return (
    <>
      <TemplateConfigCard
        text={cardTitle}
        id={slugify(cardTitle)}
        classNameForText="config-subheading"
        subtitle=""
        leftComponent={<LeftComponent />}
        rightComponent={rightComponent}
      />
    </>
  );
};

export default AdvancedTranscriptionSettings;
