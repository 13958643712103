import { useState } from "react";
import { isValidObjectID } from "src/utils";
import { ChevronDownIcon } from "@100mslive/react-icons";
import {
  Box,
  Dropdown,
  Flex,
  Input,
  Label,
  Text,
} from "@100mslive/roomkit-react";
import FilterError from "../FilterError";

const inputCSS = {
  w: "100%",
  fontSize: "$xs",
  p: "$sm $md",
  borderRadius: "$0",
  bg: "$surface_bright",
  border: "1px solid $surface_brighter",
};

const FilterInput = ({
  text,
  value,
  options = {},
  onChange,
  disabled,
  onEnterKeyPress,
  type,
}) => {
  const [open, setOpen] = useState(false);
  switch (type) {
    case "dropdown":
      return (
        <Flex align="center" justify="between" gap="1" css={{ p: "$xs $md" }}>
          <Label htmlFor={text}>
            <Text
              variant="caption"
              css={{
                minWidth: "$20",
                color: "$on_surface_medium",
                fontWeight: "$semiBold",
              }}
            >
              {text}
            </Text>
          </Label>
          <Dropdown.Root
            open={open}
            onOpenChange={() => {
              setOpen(prev => !prev);
            }}
          >
            <Dropdown.Trigger css={{ w: "100%", c: "$on_surface_high" }}>
              <Flex align="center" css={{ position: "relative", w: "100%" }}>
                <Input
                  css={inputCSS}
                  placeholder={`Filter by ${text}`}
                  value={
                    Object.keys(options).find(key => options[key] === value) ||
                    ""
                  }
                />
                <ChevronDownIcon
                  style={{ position: "absolute", right: "12px" }}
                />
              </Flex>
            </Dropdown.Trigger>
            <Dropdown.Content
              side="bottom"
              css={{ w: "422px", position: "relative", left: "-$2" }}
            >
              {Object.keys(options).map(option => (
                <Dropdown.Item
                  onSelect={() => {
                    onChange(options[option]);
                  }}
                >
                  <Text variant="xs">{option}</Text>
                </Dropdown.Item>
              ))}
            </Dropdown.Content>
          </Dropdown.Root>
        </Flex>
      );

    default: {
      const validInput = isValidObjectID(value);
      const isIDField = text.toLowerCase().includes("id");
      return (
        <Flex align="center" justify="center" gap="1" css={{ p: "$xs $md" }}>
          <Label htmlFor={text}>
            <Text
              variant="caption"
              css={{
                minWidth: "$20",
                color: "$on_surface_medium",
                fontWeight: "$semiBold",
              }}
            >
              {text}
            </Text>
          </Label>
          <Box css={{ w: "100%" }}>
            <Input
              css={inputCSS}
              disabled={disabled}
              id={text}
              error={isIDField && !validInput ? true : false}
              placeholder={`Filter by ${text}`}
              value={value}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  onEnterKeyPress();
                }
              }}
              onChange={e => onChange(e.target.value)}
            />
            {isIDField ? (
              <FilterError
                errorString={
                  validInput ? "" : `Please check the ${text} entered`
                }
              />
            ) : (
              ""
            )}
          </Box>
        </Flex>
      );
    }
  }
};
export default FilterInput;
