import React, { ComponentProps, PropsWithChildren } from "react";
import { ChevronRightIcon } from "@100mslive/react-icons";
import { Flex, Text } from "@100mslive/roomkit-react";
import TextAndSubtext from "./TextAndSubtext";

export function SideList({
  title,
  subtitle = "",
  active,
  onClick = () => {
    return;
  },
  children,
  showChevron = false,
}: PropsWithChildren<{
  title: string;
  subtitle?: string;
  active: boolean;
  showChevron?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}>) {
  return (
    <Flex
      direction="column"
      align="center"
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        onClick(e);
      }}
      css={{
        cursor: "pointer",
      }}
    >
      <Flex
        direction="row"
        align="center"
        justify="between"
        css={{ w: "100%", c: "$on_surface_low", maxWidth: "207px" }}
      >
        <Flex
          align="center"
          css={{
            borderLeft: showChevron
              ? "none"
              : !active
              ? "2px solid $transparent"
              : "2px solid $primary_bright",
            padding: "0.5rem",
            pr: "0",
            pl: showChevron ? "0" : "$8",
            w: "100%",
            gap: "$4",
            color: active ? "$on_surface_high" : "$on_surface_low",
          }}
        >
          {showChevron ? (
            <ChevronRightIcon
              style={{
                transition: "transform 0.2s ease",
                transform: active ? "rotate(90deg)" : "rotate(0deg)",
              }}
              height={16}
              width={16}
            />
          ) : null}
          <TextAndSubtext
            text={title}
            textVariant="sm"
            subTextVariant="caption"
            subText={subtitle}
            textCss={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "189px",
              color: active ? "$on_surface_high" : "$on_surface_low",
              fontWeight: active ? "$semiBold" : "$regular",
            }}
            containerCSS={{ w: "100%" }}
          />
        </Flex>
      </Flex>
      {children}
    </Flex>
  );
}

export function SideListItem({
  title,
  active,
  onClick,
  ...props
}: PropsWithChildren<
  ComponentProps<typeof Flex> & {
    title: string;
    active: boolean;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
  }
>) {
  return (
    <Flex
      direction="row"
      align="center"
      justify="start"
      onClick={onClick}
      css={{
        cursor: "pointer",
        px: "0.5rem",
        ...props.css,
      }}
      {...props}
    >
      <Text
        variant="caption"
        css={{
          fontWeight: active ? "$semiBold" : "$regular",
          color: active ? "$on_surface_high" : "$on_surface_low",
          borderLeft: "2px solid",
          borderColor: active ? "$primary_bright" : "$on_surface_low",
          paddingLeft: "$md",
          py: "$xs",
        }}
      >
        - {title}
      </Text>
    </Flex>
  );
}
