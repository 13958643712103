import React from "react";
import { AppAnalytics } from "src/helpers";
import { AlertTriangleIcon, CrossIcon } from "@100mslive/react-icons";
import { Box, Flex, Text } from "@100mslive/roomkit-react";

// eslint-disable-next-line complexity
function WarningCard({
  open,
  setOpen,
  title = "",
  body,
  fullWidth = false,
  btnText = "Learn more ->",
  componentId,
  link,
  hideCross = false,
}) {
  const eventValue = title.replace(/ /g, ".").toLowerCase().replace("?", "");
  if ((!body && !link) || !open) {
    return null;
  }
  return (
    <Flex
      align="center"
      css={{
        visibility: open ? "initial" : "hidden",
        border: "$space$px solid $alert_warning",
        bg: "$surface_default",
        p: "$8",
        r: "$1",
        w: "100%",
      }}
    >
      <Flex
        align={fullWidth ? "center" : "start"}
        justify="between"
        direction={fullWidth ? "row" : "column"}
        css={{ w: "100%" }}
      >
        <Box>
          {title !== "" && (
            <Flex css={{ color: "$on_surface_medium" }} align="center">
              <Box css={{ c: "$alert_warning" }}>
                <AlertTriangleIcon width={20} height={20} />
              </Box>
              <Text
                variant="sm"
                css={{ ml: "$4", display: "inline", fontWeight: "$semiBold" }}
              >
                {title}
              </Text>
            </Flex>
          )}
          {body !== "" && (
            <Text
              variant="sm"
              css={{
                color: "$on_surface_medium",
                mb: fullWidth ? "0" : "$8",
                mt: title ? "$4" : "0",
                maxWidth: fullWidth || hideCross ? "unset" : "280px",
              }}
            >
              {body}
            </Text>
          )}
        </Box>

        <a
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            AppAnalytics.track("btn.clicked", {
              componentId: componentId,
              btnId: "read.guide",
              value: eventValue,
            })
          }
          href={link}
        >
          <Text
            variant="sm"
            css={{ c: "$primary_bright", fontWeight: "$medium" }}
          >
            {btnText}
          </Text>
        </a>
      </Flex>

      {!hideCross ? (
        <Box
          css={{
            c: "$on_surface_low",

            "&:hover": { c: "$on_surface_medium", cursor: "pointer" },
          }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <CrossIcon width={20} height={20} />
        </Box>
      ) : null}
    </Flex>
  );
}

export default WarningCard;
