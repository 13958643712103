import React, { FC, ReactElement } from "react";
import { Link } from "react-router-dom";
import { AppAnalytics } from "src/helpers";
import { Flex, Loading, Text } from "@100mslive/roomkit-react";

interface HeaderItemProps {
  icon: ReactElement;
  text: string;
  btnId: string;
  link?: string;
  showLoader?: boolean;
  onClick: () => void;
}

const HeaderItem: FC<HeaderItemProps> = ({
  icon,
  text,
  btnId,
  showLoader = false,
  link = "",
  onClick = () => {
    return;
  },
}) => {
  return link ? (
    <Link
      onClick={() =>
        AppAnalytics.track("btn.clicked", {
          btnId: `${btnId}.header`,
          componentId: "header",
        })
      }
      to={{ pathname: link }}
      target="_blank"
    >
      {showLoader ? (
        <Loading size={24} />
      ) : (
        <Flex
          align="center"
          css={{
            color: "$on_surface_medium",
            borderRight: "1px solid $border_default",
            paddingRight: "$md",
            "&:hover": { color: "$primary_bright" },
          }}
        >
          {icon}
          <Text
            variant="sm"
            css={{ color: "inherit", fontWeight: "$semiBold" }}
          >
            {text}
          </Text>
        </Flex>
      )}
    </Link>
  ) : (
    <Flex
      onClick={() => {
        onClick();
        AppAnalytics.track("btn.clicked", {
          btnId,
          componentId: "header",
        });
      }}
      align="center"
      css={{
        color: "$on_surface_medium",
        borderRight: "1px solid $border_default",
        paddingRight: "$md",
        cursor: "pointer",
        "&:hover": { color: "$primary_bright" },
      }}
    >
      {showLoader ? (
        <Loading size={24} />
      ) : (
        <>
          {icon}
          <Text
            variant="sm"
            css={{ color: "inherit", fontWeight: "$semiBold" }}
          >
            {text}
          </Text>
        </>
      )}
    </Flex>
  );
};

export default HeaderItem;
