import React, { ComponentProps, FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import {
  patchRoleInStore,
  patchRoleNameInStore,
  setHasUnsavedRoles,
} from "src/actions/RolesActions";
import TemplateConfigCard from "src/components/Cards/TemplateConfigCard";
import TabGroup from "src/components/Common/TabGroup";
import { validationMessagesKeys } from "src/constants";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { roleTemplatePolicy } from "src/types/policyTypes";
import { slugify } from "src/utils";
import { Messages } from "src/validations";
import { CheckIcon, CrossIcon } from "@100mslive/react-icons";
import { Box, Flex, Input, Text } from "@100mslive/roomkit-react";
const tabs = [
  { name: "5 (Low)", id: 5, icon: <></>, title: "5", value: 5 },

  { name: "4", id: 4, icon: <></>, title: "4", value: 4 },
  {
    name: "3",
    id: 3,
    icon: <></>,
    title: "3",
    value: 3,
  },
  { name: "2", id: 2, icon: <></>, title: "2", value: 2 },
  {
    name: "1 (High)",
    id: 1,
    icon: <></>,
    title: "1",
    value: 1,
  },
];

const GeneralRoleSettings: FC<
  ComponentProps<typeof Flex> & {
    roleConfig: roleTemplatePolicy;
    roles: roleTemplatePolicy[];
    activeRole: string;
    setActiveRole: (name: string) => void;
  }
> = ({ roleConfig, roles, activeRole, setActiveRole }) => {
  const dispatch = useDispatch();
  const setRole = (role: roleTemplatePolicy) => {
    dispatch(patchRoleInStore(role));
  };

  const priorityTab = tabs.find(tab => roleConfig?.priority === tab.value);

  const [activeTab, setActiveTab] = useState(priorityTab?.title);
  const [roleNameErr, setRoleNameErr] = useState<string>("");
  const [name, setName] = useState<string>(activeRole);
  const handleNameSave = (input: string) => {
    if (activeRole === input) {
      return;
    }
    const nameInput = input.trim();
    const roleObject = { ...roleConfig, name: nameInput };
    dispatch(patchRoleNameInStore(roleObject, activeRole));
    setActiveRole(nameInput);
  };

  const handleRoleNameChange = (input: string) => {
    const err = Messages(
      validationMessagesKeys.validRoleName,
      input,
      true,
      roles.map(role => role.name)
    );
    setRoleNameErr(err);
    setName(input);
  };

  useEffect(() => {
    setName(activeRole);
  }, [activeRole]);

  useEffect(() => {
    if (activeRole) {
      const priorityTab = tabs.find(tab => roleConfig?.priority === tab.value);
      setActiveTab(priorityTab?.title);
    }
  }, [activeRole, roleConfig?.priority]);

  return (
    <TemplateConfigCard
      text="General"
      id={slugify("General")}
      classNameForText="config-subheading"
      rightComponent={
        <Flex direction="column" css={{ minWidth: "100%" }}>
          <Flex
            direction="column"
            css={{
              pb: "$10",
              minWidth: "100%",
              borderBottom: "$space$px solid $border_default",
            }}
          >
            <SettingsTitle
              key="roleName"
              title="Every role has a name that should be unique inside a template. This name will be used while generating app tokens and referencing inside a template."
              text="Role Name"
            />
            <Flex css={{ mt: "$4", position: "relative" }}>
              <Input
                type="text"
                css={{ r: "$0", width: "100%", bg: "$surface_bright" }}
                onChange={e => handleRoleNameChange(e.target.value)}
                value={name}
                maxLength={100}
              />
              <button
                className={classNames("ml-2", {
                  "cursor-not-allowed": activeRole === name,
                })}
                onClick={() => {
                  setName(activeRole);
                }}
              >
                <Box className="text-alert-error">
                  <CrossIcon />
                </Box>
              </button>
              <button
                className={classNames("ml-2", {
                  "cursor-not-allowed": roleNameErr || activeRole === name,
                })}
                onClick={() => {
                  if (!roleNameErr) {
                    handleNameSave(name);
                  }
                }}
                disabled={Boolean(roleNameErr || activeRole === name)}
              >
                <Box className="text-alert-success">
                  <CheckIcon />
                </Box>
              </button>
              {roleNameErr && activeRole !== name ? (
                <Text
                  variant="caption"
                  css={{
                    fontWeight: "$regular",
                    position: "absolute",
                    top: "110%",
                    c: "$alert_error_default",
                  }}
                >
                  {roleNameErr}
                </Text>
              ) : null}
              {!roleNameErr && activeRole !== name ? (
                <Text
                  variant="caption"
                  css={{
                    fontWeight: "$regular",
                    position: "absolute",
                    top: "110%",
                    c: "$alert_success",
                  }}
                >
                  &#10003; Role name is available
                </Text>
              ) : null}
            </Flex>
          </Flex>
          <Flex
            direction="column"
            css={{
              mt: "$10",
              minWidth: "100%",
            }}
          >
            <SettingsTitle
              key="roleName"
              title="Priority will determine the order in which the roles will be degraded. A lower number represents a higher priority."
              text="Role Priority"
            />
            <TabGroup
              activeTab={activeTab}
              setActiveTab={(value: string) => {
                setActiveTab(value);
                setRole({ ...roleConfig, priority: parseInt(value) });
                dispatch(setHasUnsavedRoles(true));
              }}
              useParams={false}
              btnId={activeTab}
              componentId={`templates.config.roles.${activeTab}`}
              tabs={tabs}
              css={{ w: "100%", mt: "$4" }}
            />
          </Flex>
        </Flex>
      }
    />
  );
};

export default GeneralRoleSettings;
